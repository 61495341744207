import React from 'react';
import { useNavigate } from 'react-router-dom';

const SessionExpired = () => {
  const navigate = useNavigate();
  const handleSessionExpire = () => {
    document.cookie = 'BMI-token=; path=/; max-age=0; secure; SameSite=Strict';
    localStorage.setItem('isLogged', false);
    navigate('/login', { state: { newUser: false } });
  };
  return (
    <div className="session-expired">
      <p>Session Expired! </p>
      <button onClick={() => handleSessionExpire()}>Login</button>
    </div>
  );
};

export default SessionExpired;
