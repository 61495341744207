import axios from 'axios';

import config from '../utils/ApiUrl';
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const updateUserProfile = async (userId, updatedUserData) => {
  try {
    // Make API call to update user profile
    const response = await axios.put(
      `${API_URL}/profile/${userId}`,
      updatedUserData,

      {
        headers: {
          'Content-Type': 'application/json',
          bmi_api_key: bmi_api_key,
        },
      }
    );
    return response.data; // Return the updated user data
  } catch (error) {
    throw error;
  }
};

export default updateUserProfile;
