import config from '../utils/ApiUrl';
const API_URL = config.API_URL;

const alertSettings = async (formData) => {
  const response = await fetch(`${API_URL}/alert-settings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });

  return response.json();
};

const getAlertSettings = async (userId) => {
  const response = await fetch(`${API_URL}/get-alert-settings/${userId}`);
  return response.json();
};

export { alertSettings, getAlertSettings };
