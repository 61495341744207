import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import userService from '../services/userService';

const AuthenticationCallbackHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleAuthentication = async () => {
      try {
        // Extract email from URL params
        const searchParams = new URLSearchParams(location.search);
        const sessiontoken = searchParams.get('sessiontoken');

        // Optionally, you can perform validation or further processing here

        if (sessiontoken) {
          document.cookie = `BMI-token=${sessiontoken}; path=/; max-age=86400; secure; SameSite=Strict`;
          localStorage.setItem('isLogged', 'true');
          //navigate("/main");
        }
        try {
          const { Browser } = await import('@capacitor/browser');
          await Browser.close();
          window.location.href = `bmiapp://auth-redirect?sessiontoken=${sessiontoken}`;
        } catch (err) {
          navigate('/main');
        }
      } catch (error) {
        //console.error('Authentication failed:', error);
        // Handle authentication failure if needed
      }
    };

    handleAuthentication();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <img id="loader" src="loading.gif" alt="loading" />
    </div>
  );
};

export default AuthenticationCallbackHandler;
