import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  createPaymentIntent,
  createSubscription,
} from '../services/paymentService';
import userService from '../services/userService';
import './Payments.css';
import config from '../utils/ApiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionExpired from './SessionExpired';

const stripePromise = loadStripe(config.PUBLISHABLE_KEY);

const PaymentForm = ({ user }) => {
  const [promoCode, setPromoCode] = useState('');
  const [customer, setCustomer] = useState('');
  const [paymentError, setPaymentError] = useState(null);
  const [user_Id, setUser_Id] = useState();
  const [clientSecret, setClientSecret] = useState(null);
  const [planAmount, setPlanAmount] = useState(29900);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(2);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const refreshLocal = async () => {
    window.location.href = '/main/provider-search';
  };
  const fetchUserProfile = () => {
    setUser_Id(user._id);
    setCustomer(user.email);
  };

  const handlePayment = async () => {
    try {
      const amount = planAmount;

      const paymentData = await createPaymentIntent(amount, user_Id, promoCode);
      setPlanAmount(amount);
      setClientSecret(paymentData.clientSecret);
      setPaymentDetails(paymentData.paymentDetails);
      if (!paymentData.paymentDetails.saving) {
        notifyDanger('Invalid or expired promocode!');
      } else {
        notifySuccess(
          `Congrats! ${paymentData.paymentDetails.saving / 100} CAD is saved`
        );
      }
    } catch (error) {
      setPaymentError(error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: customer,
          },
        },
      }
    );

    if (error) {
      setPaymentError(error.message);
    } else if (paymentIntent.status === 'succeeded') {
      if (planAmount) {
        setPaymentError(null);
        try {
          const response = await createSubscription(planAmount, user_Id);

          if (await response.token) {
            notifySuccess('Payment Successful');
            refreshLocal();
            setClientSecret(null);
          } else {
            notifyDanger(response.error || 'Something went wrong!');
          }
        } catch {
          notifyDanger('Something went wrong!');
        }
      }
    }
  };

  const handlePlanClick = (amount, index) => {
    setPlanAmount(amount);
    setSelectedPlan(index); // Set the selected plan index
  };

  return (
    <div className="payment-container">
      <ToastContainer />
      {sessionExpired && <SessionExpired />}
      <h1 className="payment-heading">Payments</h1>
      {!clientSecret && (
        <>
          <div className="plan-selection">
            {planAmount && (
              <div className="promo-holder">
                <input
                  className="promocode-input"
                  type="text"
                  placeholder="Enter promo code (optional)"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button
                  disabled={!planAmount || planAmount === 0}
                  className="confirm-button"
                  onClick={() => handlePayment()}
                >
                  Make Payment
                </button>
              </div>
            )}
            <table className="subscription-table">
              <thead>
                <tr>
                  <th className="heading-select-plan">
                    Select plan to proceed
                  </th>
                  <th
                    className={
                      selectedPlan !== 1
                        ? 'heading-select-plan'
                        : 'heading-select-plan-active'
                    }
                    onClick={() => handlePlanClick(2900, 1)}
                  >
                    {selectedPlan !== 1 && <p>Select Plan</p>}
                    {selectedPlan === 1 && <p>Selected</p>}
                  </th>
                  <th
                    className={
                      selectedPlan !== 2
                        ? 'heading-select-plan'
                        : 'heading-select-plan-active'
                    }
                    onClick={() => handlePlanClick(5900, 2)}
                  >
                    {selectedPlan !== 2 && <p>Select Plan</p>}
                    {selectedPlan === 2 && <p>Selected</p>}
                  </th>
                  <th
                    className={
                      selectedPlan !== 3
                        ? 'heading-select-plan'
                        : 'heading-select-plan-active'
                    }
                    onClick={() => handlePlanClick(9900, 3)}
                  >
                    {selectedPlan !== 3 && <p>Select Plan</p>}
                    {selectedPlan === 3 && <p>Selected</p>}
                  </th>
                </tr>
                <tr>
                  <th>Features</th>
                  <th
                    className={selectedPlan === 1 ? 'highlighted' : ''}
                    onClick={() => handlePlanClick(2900, 1)}
                  >
                    Monthly Subscription <br /> $29 / month
                  </th>
                  <th
                    className={selectedPlan === 2 ? 'highlighted' : ''}
                    onClick={() => handlePlanClick(5900, 2)}
                  >
                    6 Months Subscription <br /> $59 / 6 months
                  </th>
                  <th
                    className={selectedPlan === 3 ? 'highlighted' : ''}
                    onClick={() => handlePlanClick(9900, 3)}
                  >
                    Annual Subscription <br /> $99 / year
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Unlimited Chat with Buyers</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>

                {/* <tr>
                  <td>
                  Free Extension for No Wins 
                  </td>
                  <td className="tick"> —</td>
                  <td className="tick"> Place 5 minimum bids, get 3 free months if none are accepted</td>
                  <td className="tick">Place 10 minimum bids, get 6 free months if fewer than 5 are accepted</td>
                </tr> */}

                <tr>
                  <td>
                    1-Month Free Trial (Like our Facebook page for a promo code
                    and get a free month.)
                  </td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>Instant Bidding</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>Easy Cancellation (Cancel anytime)</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>
                    Seasonal Flexibility (Bid on high-demand posts during peak
                    times.)
                  </td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>
                    Consistent Exposure (Visible for 6 months/Visible for the
                    entire year)
                  </td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>Cost Savings (Lower rate/Lowest monthly rate)</td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>Priority Support (24/7 priority support)</td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>
                    Offline Support (Help connecting with insurance buyers.)
                  </td>
                  <td className="cross">&#10007;</td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>
                    Money-Back Guarantee (min of 10 bids required/Full refund if
                    fewer than 5 bids accepted)
                  </td>
                  <td className="cross">&#10007;</td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>
                    Premium Alerts (Alerts for new buyers in your zip code)
                  </td>
                  <td className="cross">&#10007;</td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>Maximum Visibility (Bids get priority)</td>
                  <td className="cross">&#10007;</td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                </tr>
                <tr>
                  <td>Early Access to New Features</td>
                  <td className="cross">&#10007;</td>
                  <td className="cross">&#10007;</td>
                  <td className="tick">&#10003;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {clientSecret && (
        <>
          <ul className="payment-details-list">
            <li className="payment-details">
              <span>Selected plan duration:</span>{' '}
              <span className="payment-details-value">
                {paymentDetails.duration}
              </span>
            </li>
            <li className="payment-details">
              <span>Promocode used :</span>{' '}
              <span className="payment-details-value">
                {paymentDetails.promoCode || '-'}{' '}
              </span>
            </li>
            <li className="payment-details">
              <span>Total Amount Saved:</span>{' '}
              <span className="payment-details-value">
                {paymentDetails.saving / 100} $CAD
              </span>
            </li>
            <li className="payment-details">
              <span>Total Amount to be paid (inclusive of all taxes):</span>{' '}
              <span className="payment-details-value">
                {paymentDetails.amount / 100} $CAD
              </span>
            </li>
          </ul>
          <form inert className="payment-form" onSubmit={handleSubmit}>
            <div className="cardelement-holder">
              <CardElement
                options={{
                  style: {
                    base: {
                      border: '1px solid rgba(5,66,106,1)',
                      fontSize: '1.4rem',
                      color: 'black',
                      '::placeholder': {
                        color: 'black',
                      },
                    },
                    invalid: {
                      color: '#fa755a',
                    },
                  },
                  appearance: {
                    theme: 'stripe',
                  },
                }}
              />
            </div>
            <div className="buttons-holder-payments2">
              <button
                type="submit"
                disabled={!stripe || !elements}
                className="confirm-button"
              >
                Confirm Payment
              </button>
              <button
                className="confirm-button"
                onClick={() => {
                  setClientSecret(null);
                  notifyDanger('Payment cancelled');
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </>
      )}
      {paymentError && clientSecret && (
        <p className="error-message">{paymentError}</p>
      )}
    </div>
  );
};

const PaymentPage = ({ user }) => (
  <Elements stripe={stripePromise}>
    <PaymentForm user={user} />
  </Elements>
);

export default PaymentPage;
