import { Helmet } from 'react-helmet-async';

const getMetaTags = (section) => {
  switch (section) {
    case 'dashboard':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>Manage Your Account Dashboard | Beat My Insurance</title>
          <meta
            name="description"
            content="Manage your activities on your Beat My Insurance dashboard. Buy, sell, chat, check notifications, and access help & support effortlessly."
          />
          <meta
            property="og:title"
            content="Manage Your Account Dashboard | Beat My Insurance"
          />
          <meta
            property="og:description"
            content="Manage your activities on your Beat My Insurance dashboard. Buy, sell, chat, check notifications, and access help & support effortlessly."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Manage Your Account Dashboard | Beat My Insurance"
          />{' '}
          <meta
            name="twitter:description"
            content="Manage your activities on your Beat My Insurance dashboard. Buy, sell, chat, check notifications, and access help & support effortlessly."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              '@id': '#Organization',
              url: 'https://www.beatmyinsurance.com',
              legalName: 'Beat My Insurance',
              name: 'Beat My Insurance',
              description:
                'Get the best auto, home, life, and super visa insurance with Beat My Insurance! Easily compare options to find cheap car insurance, auto insurance, home insurance in Canada, and bundled home and auto insurance deals.',
              image: 'https://www.beatmyinsurance.com/logo.png',
              logo: 'https://www.beatmyinsurance.com/logo.png',

              email: 'info@beatmyinsurance.com',
              address: {
                '@type': 'PostalAddress',
                addressRegion: 'Ontario',
                addressCountry: 'Canada',
              },
              sameAs: [
                'https://www.facebook.com/beatmyinsurance/',
                'https://www.youtube.com/@BeatMyInsurance',
                'https://www.instagram.com/beatmyinsurance/',
                'https://www.linkedin.com/company/beat-my-insurance/',
                'https://x.com/Beatmyinsurance',
              ],
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Beat My Insurance',
                  item: 'https://www.beatmyinsurance.com',
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: 'Buy Insurance',
                  item: 'https://www.beatmyinsurance.com/buy-insurance',
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  name: 'Sell Insurance',
                  item: 'https://www.beatmyinsurance.com/sell-insurance',
                },
                {
                  '@type': 'ListItem',
                  position: 4,
                  name: 'Cheap Insurance Car Finder',
                  item: 'https://www.beatmyinsurance.com/cheap-insurance-car-finder',
                },
                {
                  '@type': 'ListItem',
                  position: 5,
                  name: 'Mortgage Penalty Calculator',
                  item: 'https://www.beatmyinsurance.com/mortgage-penalty-calculator',
                },
                {
                  '@type': 'ListItem',
                  position: 6,
                  name: 'About Us',
                  item: 'https://www.beatmyinsurance.com/about',
                },
              ],
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'WebPage',
              '@id': '#WebPage',
              url: 'https://www.beatmyinsurance.com/cheap-insurance-car-finder',
              name: 'Cheap Insurance Car Finder',
              areaServed: [
                'https://en.wikipedia.org/wiki/Canada',
                'https://en.wikipedia.org/wiki/Ottawa',
                'https://en.wikipedia.org/wiki/London,_Ontario',
                'https://en.wikipedia.org/wiki/Hamilton,_Ontario',
                'https://en.wikipedia.org/wiki/Vaughan',
                'https://en.wikipedia.org/wiki/Toronto',
                'https://en.wikipedia.org/wiki/Mississauga',
                'https://en.wikipedia.org/wiki/Brampton',
                'https://en.wikipedia.org/wiki/Windsor,_Ontario',
                'https://en.wikipedia.org/wiki/Kitchener,_Ontario',
                'https://en.wikipedia.org/wiki/Markham,_Ontario',
              ],
            })}
          </script>
        </Helmet>
      );

    case 'provider-search':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>Find Insurance Buyers & Bid on Quotes - Provider Search</title>
          <meta
            name="description"
            content="Browse buyer listings and submit bids with the search provider on Beat My Insurance. Connect with customers searching for cheap insurance quotes."
          />
          <meta
            property="og:title"
            content="Find Insurance Buyers & Bid on Quotes - Provider Search"
          />
          <meta
            property="og:description"
            content="Browse buyer listings and submit bids with the search provider on Beat My Insurance. Connect with customers searching for cheap insurance quotes."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Find Insurance Buyers & Bid on Quotes - Provider Search"
          />{' '}
          <meta
            name="twitter:description"
            content="Browse buyer listings and submit bids with the search provider on Beat My Insurance. Connect with customers searching for cheap insurance quotes."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    case 'buyer-post':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>Buy Insurance Online, Compare Plans & Get Cheap Quotes</title>
          <meta
            name="description"
            content="Find best auto, home, life, and Supervisa insurance quotes online with Beat My Insurance. Compare bids and get connected with insurance providers."
          />
          <meta
            property="og:title"
            content="Buy Insurance Online, Compare Plans & Get Cheap Quotes"
          />
          <meta
            property="og:description"
            content="Find best auto, home, life, and Supervisa insurance quotes online with Beat My Insurance. Compare bids and get connected with insurance providers."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Buy Insurance Online, Compare Plans & Get Cheap Quotes"
          />{' '}
          <meta
            name="twitter:description"
            content="Find best auto, home, life, and Supervisa insurance quotes online with Beat My Insurance. Compare bids and get connected with insurance providers."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    case 'notifications':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title> Receive Notifications on Cheap Auto Insurance Quotes.</title>
          <meta
            name="description"
            content="View notifications on Beat My Insurance. Stay updated on new quotes, bids, and important alerts for your insurance needs."
          />
          <meta
            property="og:title"
            content=" Receive Notifications on Cheap Auto Insurance Quotes."
          />
          <meta
            property="og:description"
            content="View notifications on Beat My Insurance. Stay updated on new quotes, bids, and important alerts for your insurance needs."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content=" Receive Notifications on Cheap Auto Insurance Quotes."
          />{' '}
          <meta
            name="twitter:description"
            content="View notifications on Beat My Insurance. Stay updated on new quotes, bids, and important alerts for your insurance needs."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    case 'chats':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>
            Chat with Insurance Buyers & Sellers on Beat My Insurance
          </title>
          <meta
            name="description"
            content="Use the chat feature on Beat My Insurance to connect buyers and sellers. Discuss insurance quotes, offers, and finalize deals in real-time."
          />
          <meta
            property="og:title"
            content="Chat with Insurance Buyers & Sellers on Beat My Insurance"
          />
          <meta
            property="og:description"
            content="Use the chat feature on Beat My Insurance to connect buyers and sellers. Discuss insurance quotes, offers, and finalize deals in real-time."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Chat with Insurance Buyers & Sellers on Beat My Insurance"
          />{' '}
          <meta
            name="twitter:description"
            content="Use the chat feature on Beat My Insurance to connect buyers and sellers. Discuss insurance quotes, offers, and finalize deals in real-time."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    case 'profile':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>Profile | Beat My Insurance - View & Edit Your Account</title>
          <meta
            name="description"
            content="Manage your profile on Beat My Insurance. Update your name, email, profile picture, and bio for an up-to-date account."
          />
          <meta
            property="og:title"
            content="Profile | Beat My Insurance - View & Edit Your Account"
          />
          <meta
            property="og:description"
            content="Manage your profile on Beat My Insurance. Update your name, email, profile picture, and bio for an up-to-date account."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Profile | Beat My Insurance - View & Edit Your Account"
          />{' '}
          <meta
            name="twitter:description"
            content="Manage your profile on Beat My Insurance. Update your name, email, profile picture, and bio for an up-to-date account."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    case 'settings':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>Manage Privacy & Account Settings | Beat My Insurance</title>
          <meta
            name="description"
            content="Update your account settings on Beat My Insurance. Adjust your password, manage privacy, or easily remove your account as needed."
          />
          <meta
            property="og:title"
            content="Manage Privacy & Account Settings | Beat My Insurance"
          />
          <meta
            property="og:description"
            content="Update your account settings on Beat My Insurance. Adjust your password, manage privacy, or easily remove your account as needed."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Manage Privacy & Account Settings | Beat My Insurance"
          />{' '}
          <meta
            name="twitter:description"
            content="Update your account settings on Beat My Insurance. Adjust your password, manage privacy, or easily remove your account as needed."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    case 'payments':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>Choose Your Plan & Payment Options | Beat My Insurance</title>
          <meta
            name="description"
            content="Select from flexible Beat My Insurance payment plans. Choose monthly, 6-month, or annual subscriptions to access premium insurance services."
          />
          <meta
            property="og:title"
            content="Choose Your Plan & Payment Options | Beat My Insurance"
          />
          <meta
            property="og:description"
            content="Select from flexible Beat My Insurance payment plans. Choose monthly, 6-month, or annual subscriptions to access premium insurance services."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Choose Your Plan & Payment Options | Beat My Insurance"
          />{' '}
          <meta
            name="twitter:description"
            content="Select from flexible Beat My Insurance payment plans. Choose monthly, 6-month, or annual subscriptions to access premium insurance services."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    case 'support':
      return (
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>
            Get Insurance Help and Support Anytime | Beat My Insurance
          </title>
          <meta
            name="description"
            content="Need help? Visit the Help & Support page on Beat My Insurance. We’re here to answer all your insurance questions anytime."
          />
          <meta
            property="og:title"
            content="Get Insurance Help and Support Anytime | Beat My Insurance"
          />
          <meta
            property="og:description"
            content="Need help? Visit the Help & Support page on Beat My Insurance. We’re here to answer all your insurance questions anytime."
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Get Insurance Help and Support Anytime | Beat My Insurance"
          />{' '}
          <meta
            name="twitter:description"
            content="Need help? Visit the Help & Support page on Beat My Insurance. We’re here to answer all your insurance questions anytime."
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
      );

    default:
      return <Helmet />;
  }
};

export default getMetaTags;
