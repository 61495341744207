import React from 'react';
import { useNavigate } from 'react-router-dom';
import './About.css';
import { Helmet } from 'react-helmet-async';
import {
  Search,
  Users,
  TrendingDown,
  Home,
  Car,
  Plane,
  DollarSign,
  Award,
  Clock,
  Phone,
  Eye,
  MessageCircle,
  Lock,
  CheckCircle,
  XCircle,
  ArrowRight,
  Youtube,
  Linkedin,
  ChevronDown,
  Menu,
  X,
  TwitterIcon,
} from 'lucide-react';
import { Mail, MapPin, Facebook, Twitter, Instagram } from 'lucide-react';

const AboutUs = () => {
  const navigate = useNavigate();

  const handleVisitWebsite = () => {
    navigate('/');
  };

  return (
    <div className="about-us-container">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>Top Car Insurance Quotes & Best Mortgage Rates in Canada</title>
        <meta
          name="description"
          content="Beat My Insurance helps Canadians find top auto insurance quotes and mortgage rates. Compare broker offers and save with a hassle-free process."
        />
        <meta
          property="og:title"
          content="Top Car Insurance Quotes & Best Mortgage Rates in Canada"
        />
        <meta
          property="og:description"
          content="Beat My Insurance helps Canadians find top auto insurance quotes and mortgage rates. Compare broker offers and save with a hassle-free process."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta
          name="twitter:title"
          content="Top Car Insurance Quotes & Best Mortgage Rates in Canada"
        />{' '}
        <meta
          name="twitter:description"
          content="Beat My Insurance helps Canadians find top auto insurance quotes and mortgage rates. Compare broker offers and save with a hassle-free process."
        />{' '}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{' '}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Beat My Insurance',
                item: 'https://www.beatmyinsurance.com',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'About Us',
                item: 'https://www.beatmyinsurance.com/about',
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="about-us-top">
        <div className="about-us-content fade-in">
          <h1 className="about-us-title">About Us</h1>
          <p className="about-us-text">
            At{' '}
            <span className="about-us-highlight" onClick={handleVisitWebsite}>
              Beat My Insurance
            </span>
            we're a digital marketplace that connects insurance shoppers with
            providers. Our platform simplifies the process by letting you enter
            your details once to receive multiple offers from different
            providers.
          </p>
          <p className="about-us-text">
            Operating under 13756700 Canada Inc., we provide a platform that
            brings together buyers and licensed professionals in one convenient
            marketplace. Your privacy matters – our system keeps your contact
            information confidential until you choose to connect with a
            provider.
          </p>
          <p className="about-us-text">
            Our marketplace enables you to compare different offers in one
            place. By seeing multiple options side by side, you can make
            informed decisions about your insurance needs. We've made the
            process straightforward: enter your information once, compare
            offers, and connect with providers when you're ready. If you don't
            find a better rate through our platform, you'll know you've explored
            your options thoroughly.
          </p>
          <p className="about-us-text">
            Our dashboard helps you keep track of your policies and renewals in
            one place, making it easier to manage your insurance information.
            You have full control over your account – easily update your status
            to active or inactive, manage your personal details, and delete your
            account whenever you choose.
          </p>
          <p className="about-us-text">
            Join Beat My Insurance marketplace to discover and compare insurance
            options in a simple, transparent way.
          </p>
          <button className="about-us-button" onClick={handleVisitWebsite}>
            Visit Our Website
          </button>
        </div>
        <div className="about-us-image-slider-container">
          <div className="about-us-image-slider">
            <div className="image-container">
              <img
                src="about1.png"
                alt="Cheap Insurance Quotes"
                className="about-us-img slide"
              />
              <img
                src="about2.png"
                alt="Supervisa and cheap car Insurance"
                className="about-us-img slide"
              />
              <img
                src="about3.png"
                alt="Mortgage Rates "
                className="about-us-img slide"
              />
            </div>
          </div>
        </div>
      </div>

      {/*footer*/}
      <div className="bg-slate-900  text-white py-12">
        <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold mb-4">BeatMyInsurance</h3>
            <p className="text-slate-300">
              Find the cheapest car insurance quotes. Save up to 50% on your
              auto insurance today!
            </p>
            <button
              onClick={() => navigate('/buy-insurance')}
              className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded flex items-center"
            >
              Get Free Quote <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2 text-slate-300">
              <li>
                <a
                  href="/cheap-insurance-car-finder"
                  className="hover:text-blue-400"
                >
                  Cheap Car Insurance
                </a>
              </li>
              <li>
                <a
                  href="/mortgage-penalty-calculator"
                  className="hover:text-blue-400"
                >
                  Mortgage Penalty Calculator
                </a>
              </li>
              <li>
                <a href="/about" className="hover:text-blue-400">
                  About Us
                </a>
              </li>
              <li>
                <a href="/blogs" className="hover:text-blue-400">
                  Blogs
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <div className="space-y-3 text-slate-300">
              {/*<p className="flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                1-800-BEAT-INS
              </p>*/}
              <p
                className="flex items-center"
                onClick={() =>
                  (window.location.href = 'mailto:info@beatmyinsurance.com')
                }
              >
                <Mail className="h-4 w-4 mr-2" />
                info@beatmyinsurance.com
              </p>
              <p className="flex items-center">
                <MapPin className="h-4 w-4 mr-2" />
                Ontario, Canada
              </p>
            </div>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Stay Updated</h4>
            <p className="text-slate-300 mb-4">
              Get the latest insurance deals and tips!
            </p>

            <div className="flex space-x-4 mt-4">
              <Facebook
                onClick={() => {
                  window.open(
                    'https://www.facebook.com/beatmyinsurance/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <img
                src="/twitter.png"
                alt="twitter"
                onClick={() => {
                  window.open(
                    'https://x.com/Beatmyinsurance',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <Instagram
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/beatmyinsurance/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <Youtube
                onClick={() => {
                  window.open(
                    'http://www.youtube.com/@BeatMyInsurance',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />

              <Linkedin
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/company/103720775/admin/feed/posts/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="max-w-7xl mx-auto px-4 mt-8 pt-8 border-t border-slate-700">
          <div className="flex flex-col md:flex-row justify-between items-center text-slate-400 text-sm">
            <p>&copy; 2025 BeatMyInsurance. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              {/*<a href="/privacy" className="hover:text-blue-400">
                Privacy Policy
              </a>*/}
              <a href="/terms" className="hover:text-blue-400">
                Terms of use | Privacy Policy
              </a>
              {/*}
              <a href="/sitemap" className="hover:text-blue-400">
                Sitemap
              </a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
