import React, { useState, useEffect } from 'react';
import getAdminFieldsService from '../services/dynamicFieldsService';
import positionsService from '../services/indexingService';
import getStatesService from '../services/getStatesService';
import {
  Search,
  Users,
  TrendingDown,
  Home,
  Car,
  Plane,
  DollarSign,
  Award,
  Clock,
  Phone,
  Eye,
  MessageCircle,
  Lock,
  CheckCircle,
  XCircle,
  ArrowRight,
  ArrowLeft,
  Check,
  AlertCircle,
} from 'lucide-react';

// Insurance Form Component
const InsuranceForm = ({ productType, onCancel }) => {
  const [step, setStep] = useState(1);
  /*const [formData, setFormData] = useState({
    vin: '',
    make: '',
    model: '',
    year: '',
    primaryUse: '',
    annualMileage: '',
    parkingType: '',
    postalCode: '',
    experience: '',
    accidents: '',
    preferredContact: 'message',
  });*/

  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState();
  const [states, setStates] = useState();
  const regFields = [
    'city',
    'zip',
    'state',
    'currentPremium',
    'expectedPremium',
    'startDate',
    'currentProvider',
    'description',
  ];

  const vehicleDetails = [
    'Vehicle Year',
    'Make (Honda/Toyota/Ford etc.)',
    'Model (Accord/Camry/Bronco etc.)',
    'Buy or Lease Date',
    'Purchased Condition ',
    'VINs of the car',
    'Is your vehicle ',
    'Parking location',
  ];
  const usageInfo = [
    'Kms Driven per Year ',
    'Commute Days per Week',
    'Daily Commute One-Way (kms)',
    'Will Have Winter Tires',
    'Recovery or Tag Installed',
    'Any Commercial Use - Uber or DoorDash',
  ];
  const driverInfo = [
    'Gender',
    'Marital Status',
    'Driver License Number',
    'Licensed Since',
    'License Type (G, Level 5, G2, Other)',
    'Tickets in Past 3 Years',
    'Claims in last 3 years',
    'At fault accidents in last 6 years',
    'Licensed in US',
    'Driving school ',
    'What is the date of birth for the eldest homeowner?',
    'Additional drivers residing at the same address',
  ];
  const [formData, setFormData] = useState({
    type: 'Auto Insurance',
    city: '',
    zip: '',
    state: '',
    currentPremium: '',
    expectedPremium: '',
    startDate: '',
    currentProvider: '',
    description: '',
    visibility: true,
    bids: {},
    additionalData: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const validateStep = () => {
    /*const newErrors = {};
    
    if (step === 1) {
      if (!formData.vin) newErrors.vin = 'VIN is required';
      if (!formData.make) newErrors.make = 'Make is required';
      if (!formData.model) newErrors.model = 'Model is required';
      if (!formData.year) newErrors.year = 'Year is required';
    } else if (step === 2) {
      if (!formData.primaryUse) newErrors.primaryUse = 'Primary use is required';
      if (!formData.parkingType) newErrors.parkingType = 'Parking type is required';
    } else if (step === 3) {
      if (!formData.postalCode) newErrors.postalCode = 'Postal code is required';
      if (!formData.experience) newErrors.experience = 'Driving experience is required';
    }

    setErrors(newErrors);*/
    return true; // Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep()) {
      // Handle form submission here
    }
  };

  useEffect(() => {
    fetchFields();
    fetchStates();
  }, []);
  const fetchStates = async () => {
    try {
      const states = await getStatesService.getStates();
      setStates(states);
    } catch (error) {}
  };

  const fetchFields = async () => {
    try {
      const rawFields = await getAdminFieldsService.getAdminFields();
      /*const positionsResponse = await positionsService.getPositions();
      const positions = positionsResponse.positions;
      const orderedFields = [];
      positions.forEach((id) => {
        if (fields[id] !== undefined) {
          orderedFields.push(fields[id]);
        }
      });*/

      const autoInsuranceFields = [];
      rawFields.forEach((field) => {
        if (field.category.includes('Auto Insurance')) {
          autoInsuranceFields.push(field);
        }
      });
      setFields(autoInsuranceFields);
    } catch (error) {}
  };
  const handleAdditionalDataChange = (e) => {
    const { name, value } = e.target;

    if (value !== '') {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
      delete formData.additionalData[name];
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold mb-6">Vehicle Information</h3>
            {vehicleDetails &&
              fields &&
              vehicleDetails.map((detail, index) => (
                <React.Fragment key={index}>
                  {/* Check if detail matches regFields */}
                  {regFields.includes(detail) &&
                    detail === 'state' &&
                    (<div>
                      <label htmlFor="state">State/Province*</label>
                      <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>)(
                      detail === 'startDate' && (
                        <div>
                          <label>Start Date</label>
                          <input
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )(
                      detail === 'description' && (
                        <div>
                          <label>Start Date</label>
                          <textarea
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )(
                      !['description', 'startDate', 'state'].includes(
                        detail
                      ) && (
                        <div>
                          <label>{detail}</label>
                          <input
                            type="text"
                            name={detail}
                            value={formData[detail]}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )}

                  {/* Render additional fields */}
                  {fields.map(
                    (field) =>
                      field.name === detail && (
                        <div key={field.name}>
                          <label>{field.name}</label>
                          {field.format === 'text' && (
                            <input
                              type="text"
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            />
                          )}

                          {field.format === 'dropdown' && (
                            <select
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            >
                              <option value="">Select</option>
                              {field.options.split(',').map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}

                          {field.format === 'date' && (
                            <input
                              type="date"
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            />
                          )}
                        </div>
                      )
                  )}
                </React.Fragment>
              ))}
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold mb-6">Usage Information</h3>
            {usageInfo &&
              fields &&
              usageInfo.map((detail, index) => (
                <React.Fragment key={index}>
                  {/* Check if detail matches regFields */}
                  {regFields.includes(detail) &&
                    detail === 'state' &&
                    (<div>
                      <label htmlFor="state">State/Province*</label>
                      <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>)(
                      detail === 'startDate' && (
                        <div>
                          <label>Start Date</label>
                          <input
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )(
                      detail === 'description' && (
                        <div>
                          <label>Start Date</label>
                          <textarea
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )(
                      !['description', 'startDate', 'state'].includes(
                        detail
                      ) && (
                        <div>
                          <label>{detail}</label>
                          <input
                            type="text"
                            name={detail}
                            value={formData[detail]}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )}

                  {/* Render additional fields */}
                  {fields.map(
                    (field) =>
                      field.name === detail && (
                        <div key={field.name}>
                          <label>{field.name}</label>
                          {field.format === 'text' && (
                            <input
                              type="text"
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            />
                          )}

                          {field.format === 'dropdown' && (
                            <select
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            >
                              <option value="">Select</option>
                              {field.options.split(',').map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}

                          {field.format === 'date' && (
                            <input
                              type="date"
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            />
                          )}
                        </div>
                      )
                  )}
                </React.Fragment>
              ))}
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold mb-6">Usage Information</h3>
            {driverInfo &&
              fields &&
              driverInfo.map((detail, index) => (
                <React.Fragment key={index}>
                  {/* Check if detail matches regFields */}
                  {regFields.includes(detail) &&
                    detail === 'state' &&
                    (<div>
                      <label htmlFor="state">State/Province*</label>
                      <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>)(
                      detail === 'startDate' && (
                        <div>
                          <label>Start Date</label>
                          <input
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )(
                      detail === 'description' && (
                        <div>
                          <label>Start Date</label>
                          <textarea
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )(
                      !['description', 'startDate', 'state'].includes(
                        detail
                      ) && (
                        <div>
                          <label>{detail}</label>
                          <input
                            type="text"
                            name={detail}
                            value={formData[detail]}
                            onChange={handleChange}
                          />
                        </div>
                      )
                    )}

                  {/* Render additional fields */}
                  {fields.map(
                    (field) =>
                      field.name === detail && (
                        <div key={field.name}>
                          <label>{field.name}</label>
                          {field.format === 'text' && (
                            <input
                              type="text"
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            />
                          )}

                          {field.format === 'dropdown' && (
                            <select
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            >
                              <option value="">Select</option>
                              {field.options.split(',').map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}

                          {field.format === 'date' && (
                            <input
                              type="date"
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                              className="w-full p-2 border border-gray-300 rounded-md"
                            />
                          )}
                        </div>
                      )
                  )}
                </React.Fragment>
              ))}
          </div>
        );

      case 4:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold mb-6">Usage Information</h3>

            {/* Render fields */}
            {fields &&
              fields.map((field, index) => (
                <React.Fragment key={index}>
                  {!vehicleDetails.includes(field.name) &&
                    !usageInfo.includes(field.name) &&
                    !driverInfo.includes(field.name) &&
                    !regFields.includes(field.name) && (
                      <div key={field.name}>
                        <label>{field.name}</label>
                        {field.format === 'text' && (
                          <input
                            type="text"
                            name={field.name}
                            value={formData.additionalData[field.name] || ''}
                            onChange={(e) => handleAdditionalDataChange(e)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                          />
                        )}

                        {field.format === 'dropdown' && (
                          <select
                            name={field.name}
                            value={formData.additionalData[field.name] || ''}
                            onChange={(e) => handleAdditionalDataChange(e)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                          >
                            <option value="">Select</option>
                            {field.options.split(',').map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        )}

                        {field.format === 'date' && (
                          <input
                            type="date"
                            name={field.name}
                            value={formData.additionalData[field.name] || ''}
                            onChange={(e) => handleAdditionalDataChange(e)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                          />
                        )}
                      </div>
                    )}
                </React.Fragment>
              ))}

            {/* Render regFields */}
            {regFields.map((detail, index) => {
              if (
                !vehicleDetails.includes(detail) &&
                !usageInfo.includes(detail) &&
                !driverInfo.includes(detail)
              ) {
                if (detail === 'state') {
                  return (
                    <div key={`regField-state-${index}`}>
                      <label htmlFor="state">State/Province*</label>
                      <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="">Select State</option>
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                }

                if (detail === 'startDate') {
                  return (
                    <div key={`regField-startDate-${index}`}>
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                  );
                }

                if (detail === 'description') {
                  return (
                    <div key={`regField-description-${index}`}>
                      <label>Description</label>
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                  );
                }

                if (!['description', 'startDate', 'state'].includes(detail)) {
                  return (
                    <div key={`regField-other-${index}`}>
                      <label>{detail}</label>
                      <input
                        type="text"
                        name={detail}
                        value={formData[detail]}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>
                  );
                }
              }
              return null;
            })}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="max-w-[90%] mx-auto bg-white rounded-lg shadow-xl p-6">
      <div className="mb-8">
        <div className="flex justify-between gap-[10%] mb-2">
          {['Vehicle Details', 'Usage Info', 'Driver Info', 'Other Info'].map(
            (label, index) => (
              <div
                key={label}
                className={`text-sm font-medium ${
                  step > index + 1
                    ? 'text-green-600'
                    : step === index + 1
                      ? 'text-blue-600'
                      : 'text-gray-400'
                }`}
              >
                {label}
              </div>
            )
          )}
        </div>
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-full bg-blue-600 rounded-full transition-all duration-300"
            style={{ width: `${((step - 1) / 3) * 100}%` }}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6 h-auto">
        {renderStep()}

        <div className="flex justify-between pt-6 max-w-[100%]">
          {step > 1 && (
            <button
              type="button"
              onClick={handleBack}
              className="flex items-center gap-2 px-6 py-2 text-gray-600 hover:text-gray-800"
            >
              <ArrowLeft className="w-4 h-4" />
              Back
            </button>
          )}

          {step < 4 ? (
            <button
              type="button"
              onClick={handleNext}
              className="ml-auto flex items-center gap-2 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
            >
              Next
              <ArrowRight className="w-4 h-4" />
            </button>
          ) : (
            <button
              type="submit"
              className="ml-auto flex items-center gap-2 bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700"
            >
              Submit
              <Check className="w-4 h-4" />
            </button>
          )}
        </div>
      </form>

      <div className="mt-6 bg-blue-50 border border-blue-200 rounded-lg p-4 text-sm text-blue-800">
        <p className="flex items-center gap-2">
          <Lock className="w-4 h-4" />
          Your information stays private until you accept a quote.
        </p>
      </div>
    </div>
  );
};

// Main Homepage Component
const AutoInsuranceForm = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <div className="min-h-screen">
      {true && (
        <div id="insurance-form" className="py-16 bg-gray-50">
          <div className="container mx-auto px-1">
            <InsuranceForm productType={selectedProduct} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoInsuranceForm;
