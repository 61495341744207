import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import HomePage from './components/Home';
import AboutUs from './components/About';
import Login from './components/Login';
import Main from './components/Main';
import Dashboard from './components/Dashboard';
import GetPosts from './components/GetPosts';
import NewListing from './components/NewListing';
import Notifications from './components/Notifications';
import Chats from './components/Chats';
import Settings from './components/Settings';
import Profile from './components/Profile';
import Payments from './components/Payments';
import Help from './components/Help';
import Faq from './components/Faq';
import EditPost from './components/EditPost';

import BuyInsurance from './components/BuyInsurance';
import SellInsurance from './components/SellInsurance';

import AuthenticationCallbackHandler from './components/Authentication';
import VerifyEmail from './components/EmailVerify';
import ChangePasswordVerification from './components/ChangePasswordVerification';
import ResetPasswordVerification from './components/ResetPassword';
import Failure from './components/Failure';
import NotFoundPage from './components/PageNotFound';
import MobileVerification from './components/MobileVerification';

import TermsAndConditions from './components/Terms';

import Sellers from './posters/Sellers';
import Buyers from './posters/Buyers';

import Calculator from './tools/Calculator';
import MortgageCalculator from './tools/MortgagePenalties';
import AutoInsurance from './blogs/AutoInsurance';
import RateApprovalDashboard from './tools/RateApproval';
import RateApproval2025 from './tools/SingleInputRateApproval';
import Sitemap from './Sitemap';

import HomepageNew from './components/Homepage';
import './components/index.css';

import AutoInsuranceForm from './components/AutoInsuranceForm';
import BestCardSelector from './tools/BestCardSelector';
import NewMortgagePenaltyCalculator from './tools/NewMortgage';
import RetirementCalculator from './tools/RetirementCalculator';

import ServicePages from './components/ServicePages';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomepageNew />} />
          <Route path="/home" element={<HomepageNew />} />
          <Route path="/buy-insurance" element={<BuyInsurance />} />
          <Route path="/buy-insurance/:type" element={<BuyInsurance />} />
          <Route path="/sell-insurance" element={<SellInsurance />} />
          <Route path="/sell-insurance/:id" element={<SellInsurance />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/main" element={<Main />} />
          <Route path="/main/:section" element={<Main />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/posts" element={<GetPosts />} />
          <Route path="/newpost" element={<NewListing />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/help" element={<Help />} />
          <Route path="/editpost/:postId" element={<EditPost />} />
          <Route
            path="/auth-redirect"
            element={<AuthenticationCallbackHandler />}
          />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route
            path="/verify-change-password"
            element={<ChangePasswordVerification />}
          />
          <Route
            path="/verify-reset-password"
            element={<ResetPasswordVerification />}
          />
          <Route path="/faq" element={<Faq />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/sellers" element={<Sellers />} />
          <Route path="/buyers" element={<Buyers />} />
          {/*<Route path='/blogs' element={<BlogPage/>}/>
          <Route path='/blogs/:blogUrl' element={<BlogPost/>}/>
          <Route path="/blogs" element={<Navigate to="https://api.beatmyinsurance.com/blogs" replace />} />
          <Route path="/blogs/*" element={<Navigate to="https://api.beatmyinsurance.com/blogs" replace />} />*/}
          <Route path="/cheap-insurance-car-finder" element={<Calculator />} />
          <Route
            path="/mortgage-penalty-calculator"
            element={<MortgageCalculator />}
          />
          {/*<Route path="/sitemap.xml" element={<Sitemap />} /> */}
          <Route
            path="/cheap-auto-insurance/:city"
            element={<ServicePages />}
          />
          <Route path="/*" element={<NotFoundPage />} />
          <Route
            path="/mobile-verification/:userId"
            element={<MobileVerification />}
          />

          <Route path="/home" element={<HomepageNew />} />
          <Route path="/service/:city" element={<ServicePages />} />

          <Route path="/auto-insurance-form" element={<AutoInsuranceForm />} />
          <Route path="/best-card-selector" element={<BestCardSelector />} />
          <Route
            path="/new-mortgage"
            element={<NewMortgagePenaltyCalculator />}
          />
          <Route
            path="/retirement-calculator"
            element={<RetirementCalculator />}
          />
          <Route
            path="/car-insurance-rates-2025"
            element={<RateApprovalDashboard />}
          />
          <Route
            path="/car-insurance-increase-by-provider-2025"
            element={<RateApproval2025 />}
          />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
