import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './MobileVerification.css';
import {
  sendCodeToMobile,
  verifyMobileCode,
  updateUserMobile,
} from '../services/mobileService';

const MobileVerification = () => {
  const [countryCode, setCountryCode] = useState('+1'); // default to US country code
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(0); // Timer for resend button
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => setTimer(timer - 1), 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const requestVerificationCode = async () => {
    try {
      const data = await sendCodeToMobile({
        phoneNumber: `${countryCode}${phoneNumber}`,
      });

      if (data.success) {
        setIsCodeSent(true);
        setMessage('Verification code sent to your mobile.');
        setTimer(60); // Start the 1-minute timer
      } else {
        if (data.error && data.error === 'Number already exists!') {
          setMessage(data.error);
          return;
        }
        setMessage(data.message || 'Failed to send verification code.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  const verifyCode = async () => {
    try {
      const data = await verifyMobileCode({
        phoneNumber: `${countryCode}${phoneNumber}`,
        code: verificationCode,
        userId,
      });

      if (data.success) {
        setMessage('Verification successful');
        navigate('/main/profile');
      } else {
        setMessage(data.error || 'Invalid/expired code. Failed to verify.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="mobile-verification-container">
      <h2>Mobile Verification</h2>
      <div className="input-group-mv">
        <label htmlFor="countryCode">Country Code</label>
        <select
          id="countryCode"
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        >
          <option value="+1">+1 (USA/Canada)</option>
        </select>
      </div>
      <div className="input-group-mv">
        <label htmlFor="phoneNumber">Phone Number</label>
        <input
          type="tel"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) =>
            '1234567890'.includes(e.target.value[e.target.value.length - 1])
              ? setPhoneNumber(e.target.value)
              : null
          }
          placeholder="Enter your phone number"
        />
        <button
          onClick={requestVerificationCode}
          disabled={!phoneNumber || timer > 0}
        >
          {timer > 0 ? `Resend Code (${timer}s)` : 'Send Code'}
        </button>
      </div>

      {isCodeSent && (
        <div className="input-group-mv">
          <label htmlFor="verificationCode">Verification Code</label>
          <input
            type="text"
            id="verificationCode"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Enter the code"
          />
          <button onClick={verifyCode} disabled={!verificationCode}>
            Verify
          </button>
        </div>
      )}

      {message && <p className="message-mv">{message}</p>}
    </div>
  );
};

export default MobileVerification;
