import config from '../utils/ApiUrl';
const API_URL = config.API_URL;

const restrictedFieldsService = {
  getFields: async () => {
    try {
      const response = await fetch(`${API_URL}/restricted-fields`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch fields');
    }
  },
};

export default restrictedFieldsService;
