import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import getMyPostsService from '../services/getMyPostsService';
import { updatePostBids, acceptBid } from '../services/updatePostBidService';
import { editVisibility } from '../services/editPost';
import getAdminFieldsService from '../services/dynamicFieldsService';
import './EditPost.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionExpired from './SessionExpired';
import QRCodePopup from './QRCode';
import { Info } from 'lucide-react';

const EditPost = (props) => {
  const { postId, userData } = props;
  const [post, setPost] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [sessionExpired, setSessionExpired] = useState(false);
  const [mode, setMode] = useState(props.mode ? 'bids' : 'details');
  const [showQR, setShowQR] = useState(false);

  const navigate = useNavigate();
  const [fields, setFields] = useState([]);

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const fields = await getAdminFieldsService.getAdminFields();
      setFields(fields);
    } catch (error) {
      notifyDanger('Failed to fetch fields');
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        const userEmail = props.userData.email || '';
        const userDetails = props.userData;
        setUserDetails(userDetails);
        const userPosts = await getMyPostsService.getMyPosts(userEmail);
        const selectedPost = userPosts.find((post) => post._id === postId);
        setPost(selectedPost);
      } catch (error) {
        notifyDanger('Failed to fetch user posts');
      }
    };

    fetchUserPosts();
  }, [postId]);

  const handleBidAccept = async (user, bid) => {
    const data = {
      userEmail: userDetails.email,
      userName: userDetails.name,
      provider: user.replaceAll('_dot_', '.'),
      amount: bid[bid.length - 1],
      postId: postId,
      reply: true,
      session: getCookie('BMI-token'),
    };

    try {
      const response = await acceptBid(data);
      if (response) {
        notifySuccess('Accepted');
        window.location.reload();
      }
    } catch (error) {
      notifyDanger('sorry! something went wrong');
    }
  };

  const handleBidRejected = async (user, bid) => {
    const data = {
      userEmail: userDetails.email,
      userName: userDetails.name,
      provider: user.replaceAll('_dot_', '.'),
      amount: bid[bid.length - 1],
      postId: postId,
      reply: false,
      session: getCookie('BMI-token'),
    };

    try {
      const response = await acceptBid(data);
      if (response) {
        notifySuccess('Rejected');
        window.location.reload();
      }
    } catch (error) {
      notifyDanger('sorry! something went wrong');
    }
  };

  const handleToggle = async () => {
    setPost((prevState) => ({
      ...prevState,
      visibility: !post.visibility,
    }));

    try {
      const data = {
        postId: post._id,
        visibility: !post.visibility,
        session: getCookie('BMI-token'),
      };
      const response = await editVisibility(data);
      if (response.message) {
        notifySuccess(response.message);
      } else {
        notifyDanger(response.error || ' Something went wrong');
      }
    } catch (error) {
      notifyDanger(error);
    }
  };

  const handleEdit = () => {
    window.location.reload();
  };

  const handleShare = () => {
    const shareData = {
      title: 'Check out this post',
      text: 'I found this insurance deal on BeatMyInsurance!',
      url: `/sell-insurance/${postId}`,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {})
        .catch((error) => notifyDanger('Error sharing:'));
    } else {
      notifyDanger('Web Share API not supported in this browser');
    }
  };

  return (
    <div
      className="edit-post-container"
      style={{
        width: '100%',
        height: '400px',
        overflowY: 'scroll',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #e0e0e0',
      }}
    >
      <ToastContainer className={'custom-toastify'} />
      {sessionExpired && <SessionExpired />}
      {post ? (
        <div className="post-details">
          <div className="top-bar-ep">
            <button
              className={
                mode === 'details' ? 'mode-btn-ep-active' : 'mode-btn-ep'
              }
              onClick={() => setMode('details')}
            >
              Details
            </button>
            <button
              className={
                mode !== 'details' ? 'mode-btn-ep-active' : 'mode-btn-ep'
              }
              onClick={() => setMode('bids')}
            >
              Offers
            </button>

            <button className="mode-btn-ep" onClick={() => setShowQR(!showQR)}>
              QR Code
              <div className="relative flex items-center">
                <div className="group relative h-[100%] flex align-center justify-center">
                  <Info className="info-icon-ep text-[rgb(47, 47, 53)] hover:text-black w-5  cursor-pointer z-[50]" />
                  <div className="absolute left-1/2 -translate-x-1/4 bottom-2/3 mb-2 hidden group-hover:flex bg-black text-white text-xs rounded py-1 px-2 whitespace-nowrap z-[90]">
                    Scan to see details
                  </div>
                </div>
              </div>
            </button>
            <button
              id="share-btn-ep"
              className="mode-btn-ep"
              onClick={handleShare}
            >
              <img src="/share.png" alt="share" /> Share
            </button>
            {showQR && (
              <QRCodePopup
                link={`${process.env.REACT_APP_SELL_INSURANCE_URL}${post._id}`}
                open={showQR}
                onClose={() => setShowQR(false)}
              />
            )}
            <div className="toggle-container-ep">
              <input
                type="checkbox"
                id="visibility-toggle"
                checked={post.visibility}
                onChange={handleToggle}
                className="toggle-checkbox"
              />
              <label htmlFor="visibility-toggle" className="toggle-label">
                <span className="toggle-button"></span>
              </label>
              <p>{post.visibility ? 'Public' : 'Private'}</p>
            </div>
          </div>
          {mode === 'details' && (
            <div className="details-container-ep">
              <p>
                <strong>Listing ID:</strong>{' '}
                <span>{post.listingId ? post.listingId : post._id}</span>
              </p>
              <p>
                <strong>Type:</strong> <span>{post.type}</span>
              </p>
              <p>
                <strong>City:</strong> <span>{post.city}</span>
              </p>
              <p>
                <strong>Zip:</strong> <span>{post.zip}</span>
              </p>
              <p>
                <strong>State:</strong> <span>{post.state}</span>
              </p>
              <p>
                <strong>Current Premium:</strong>{' '}
                <span>{post.currentPremium}</span>
              </p>
              <p>
                <strong>Expected Premium:</strong>{' '}
                <span>{post.expectedPremium}</span>
              </p>
              <p>
                <strong>Start Date:</strong> <span>{post.startDate}</span>
              </p>
              <p>
                <strong>Current Provider:</strong>{' '}
                <span>{post.currentProvider}</span>
              </p>
              {(() => {
                const renderedNames = new Set(); // To keep track of rendered names
                return Object.entries(fields).map(([key, value]) => {
                  if (
                    value.name &&
                    post.additionalData &&
                    post.additionalData[value.name] &&
                    !renderedNames.has(value.name) // Check for duplicates
                  ) {
                    renderedNames.add(value.name); // Add to the set of rendered names
                    return (
                      <p key={key}>
                        <strong>{value.name}:</strong>{' '}
                        <span>{post.additionalData[value.name]}</span>
                      </p>
                    );
                  }
                  return null;
                });
              })()}
              <p>
                <strong>Description:</strong> <span>{post.description}</span>
              </p>
              <button className="edit-btn" onClick={handleEdit}>
                Go Back
              </button>{' '}
            </div>
          )}

          {mode == 'bids' && (
            <div className="bids-popup">
              <h2>Submitted offers</h2>
              <table className="bids-table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Offer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(post.bids).map(([user, bid]) => (
                    <tr key={user}>
                      <td>{bid[0]}</td>
                      <td>
                        {bid.length > 1 ? bid[bid.length - 1][0] : 'no-bids'}
                      </td>
                      <td>
                        {!bid.length > 1 && (
                          <>
                            <p>-</p>
                          </>
                        )}
                        {bid.length > 1 && bid[bid.length - 1][1] && (
                          <>
                            <p>{bid[bid.length - 1][1]}</p>
                          </>
                        )}
                        {bid.length > 1 && !bid[bid.length - 1][1] && (
                          <>
                            <button
                              className="btn accept-btn"
                              onClick={() => handleBidAccept(user, bid)}
                            >
                              Accept
                            </button>
                            <button
                              className="btn reject-btn"
                              onClick={() => handleBidRejected(user, bid)}
                            >
                              Reject
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ) : (
        <img id="loader" src="/loading.gif" alt="loading" />
      )}
    </div>
  );
};

export default EditPost;
