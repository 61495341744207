import config from '../utils/ApiUrl';
const API_URL = config.API_URL;
const fetchServicePageDetails = async (city) => {
  const response = await fetch(
    `${API_URL}/fetch-service-page-details/${city}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  return data;
};

export { fetchServicePageDetails };
