import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import buyerPostService from '../services/buyerPostService';
import getTypesService from '../services/getTypesService';
import getStatesService from '../services/getStatesService';
import './BuyInsurance.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getAdminFieldsService from '../services/dynamicFieldsService';
import positionsService from '../services/indexingService';
import SessionExpired from './SessionExpired';
import { useNavigate } from 'react-router-dom';

const BuyInsurance = () => {
  const { type } = useParams();
  const [formData, setFormData] = useState({
    type: '',
    city: '',
    zip: '',
    state: '',
    currentPremium: '',
    expectedPremium: '',
    startDate: '',
    currentProvider: '',
    description: '',
    visibility: true,
    bids: {},
    additionalData: {},
  });
  const [types, setTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [fields, setFields] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const typeSetter = (actualType) => {
      setFormData((prevState) => ({
        ...prevState,
        type: actualType,
      }));
    };

    if (type) {
      switch (type.toLowerCase()) {
        case 'auto-insurance':
          typeSetter('Auto Insurance');
          break;
        case 'home-insurance':
          typeSetter('Home Insurance');
          break;
        case 'home-and-auto-bundle':
          typeSetter('Home and Auto (bundle)');
          break;
        case 'renters-insurance':
          typeSetter('Renters Insurance');
          break;
        case 'super-visa-insurance':
          typeSetter('Super Visa Insurance');
          break;
        case 'mortgage-rate-quote':
          typeSetter('Mortgage Rate Quote');
          break;
        case 'visitors-insurance':
          typeSetter('Visitors Insurance');
          break;
        case 'health-dental-insurance':
          typeSetter('Health & Dental Insurance');
          break;
        case 'life-insurance':
          typeSetter('Life Insurance');
          break;
        default:
          notifyDanger('Unknown type:', type);
      }
    } else {
      notifyDanger('Type is undefined or null:', type);
    }

    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const fields = await getAdminFieldsService.getAdminFields();
      const positionsResponse = await positionsService.getPositions();
      const positions = positionsResponse.positions;
      const orderedFields = [];
      positions.forEach((id) => {
        if (fields[id] !== undefined) {
          orderedFields.push(fields[id]);
        }
      });
      setFields(orderedFields);
    } catch (error) {
      notifyDanger('Failed to fetch fields:', error.message);
    }
  };

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  useEffect(() => {
    fetchTypes();
    fetchStates();
  }, []);

  const fetchTypes = async () => {
    try {
      const types = await getTypesService.getTypes();
      setTypes(types);
    } catch (error) {
      notifyDanger('Failed to fetch types:', error.message);
    }
  };
  const fetchStates = async () => {
    try {
      const states = await getStatesService.getStates();
      setStates(states);
    } catch (error) {
      notifyDanger('Failed to fetch states:', error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdditionalDataChange = (e) => {
    const { name, value } = e.target;
    //console.log(name, formData.additionalData[name], value);
    if (value !== '') {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
      delete formData.additionalData[name];
    }
  };

  const handleToggle = () => {
    setFormData((prevState) => ({
      ...prevState,
      visibility: !prevState.visibility,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem('BMI-newlisting', JSON.stringify(formData));
    navigate('/login', { state: { newUser: true } });
    /*
    try {
      const response = await buyerPostService.postDetails(formData);
      if (response.error) {
        notifyDanger(response.error);
      } else {
        notifySuccess("Successful");
        window.location.href = '/main/dashboard';
      }
    } catch (error) {
      console.error("Failed to Post:", error.message);
      notifyDanger("Failed to post");
    }
    */
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormData({
      type: '',
      city: '',
      zip: '',
      state: '',
      currentPremium: '',
      expectedPremium: '',
      startDate: '',
      currentProvider: '',
      description: '',
      visibility: true,
      bids: {},
      additionalData: {},
    });
  };

  return (
    <div className="pagecontent-buyerpost">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>Insurance Details | Insurance Quote Application</title>
        <meta
          name="description"
          content="Submit your insurance requirements on Beat My Insurance for competitive bids from top providers. Save your time and safeguard your personal information."
        />
        <meta
          property="og:title"
          content="Insurance Details | Insurance Quote Application"
        />
        <meta
          property="og:description"
          content="Submit your insurance requirements on Beat My Insurance for competitive bids from top providers. Save your time and safeguard your personal information."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta
          name="twitter:title"
          content="Insurance Details | Insurance Quote Application"
        />{' '}
        <meta
          name="twitter:description"
          content="Submit your insurance requirements on Beat My Insurance for competitive bids from top providers. Save your time and safeguard your personal information."
        />{' '}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{' '}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Beat My Insurance',
                item: 'https://www.beatmyinsurance.com',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Buy Insurance',
                item: 'https://www.beatmyinsurance.com/buy-insurance',
              },
            ],
          })}
        </script>
      </Helmet>
      <img
        className="home-icon-bi"
        src="/home.png"
        onClick={() => navigate('/')}
        alt="home"
      />
      {sessionExpired && <SessionExpired />}
      <h1 className="buyer-post-heading-bi">
        Insurance Application – Information
      </h1>
      <div className="toggle-container-bi">
        <input
          type="checkbox"
          id="visibility-toggle"
          checked={formData.visibility}
          onChange={handleToggle}
          className="toggle-checkbox"
        />
        <label htmlFor="visibility-toggle" className="toggle-label">
          <span className="toggle-button"></span>
        </label>
        <p>{formData.visibility ? 'Active' : 'Inactive'}</p>
      </div>
      <div className="newlisting-body">
        <div
          className="buyer-post-container"
          style={{
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #e0e0e0',
          }}
        >
          <form onSubmit={handleSubmit} className="buyer-post-form">
            <div className="elements">
              <div className="form-group-newlisting">
                <label htmlFor="type">Type*</label>
                <div className="select-holder-buyerpost">
                  <select
                    name="type"
                    value={formData.type}
                    onChange={(e) => {
                      handleChange(e);
                      setFormData((prevState) => ({
                        ...prevState,
                        additionalData: {},
                      }));
                    }}
                  >
                    <option value="">Select Type</option>
                    {types.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group-newlisting">
                <label htmlFor="state">State/Province*</label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group-newlisting">
                <label htmlFor="city">City*</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-newlisting">
                <label htmlFor="zip">ZIP* (XXX XXX)</label>
                <input
                  type="text"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </div>

              {formData.type !== 'Mortgage Rate Quote' && (
                <div className="form-group-newlisting">
                  <label htmlFor="currentPremium">
                    Current Premium* (per month)
                  </label>
                  <input
                    type="text"
                    name="currentPremium"
                    value={formData.currentPremium}
                    onChange={handleChange}
                  />
                </div>
              )}
              {formData.type !== 'Mortgage Rate Quote' && (
                <div className="form-group-newlisting">
                  <label htmlFor="expectedPremium">Expected Premium</label>
                  <input
                    type="text"
                    name="expectedPremium"
                    value={formData.expectedPremium}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="form-group-newlisting">
                <label htmlFor="startDate">Start Date*</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                />
              </div>
              {formData.type !== 'Mortgage Rate Quote' && (
                <div className="form-group-newlisting">
                  <label htmlFor="currentProvider">Current Provider*</label>
                  <input
                    type="text"
                    name="currentProvider"
                    value={formData.currentProvider}
                    onChange={handleChange}
                  />
                </div>
              )}

              {fields &&
                fields.map((field) => {
                  // Check if the field's category includes the form type
                  if (
                    field.category
                      ? field.category.includes(formData.type)
                      : false
                  ) {
                    // If the field does not have a 'depend' property
                    if (field.depend === undefined) {
                      return (
                        <div key={field._id} className="form-group-newlisting">
                          <label htmlFor={field.name}>
                            {field.name === 'Driver License Number'
                              ? 'Driver License Number *'
                              : field.name}
                          </label>

                          {field.format === 'text' && (
                            <input
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                            />
                          )}

                          {field.format === 'dropdown' && (
                            <select
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                            >
                              <option value="">Select</option>
                              {field.options.split(',').map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}

                          {field.format === 'date' && (
                            <input
                              type="date"
                              name={field.name}
                              value={formData.additionalData[field.name] || ''}
                              onChange={(e) => handleAdditionalDataChange(e)}
                            />
                          )}
                        </div>
                      );
                    }

                    // If the field has a 'depend' property
                    if (field.depend !== undefined) {
                      const isDependentSatisfied =
                        (field.category
                          ? field.category.includes(formData.type)
                          : false) && field.depend[1] === 'X---Any_Value---X'
                          ? formData.additionalData[field.depend[0]] !== '' &&
                            formData.additionalData[field.depend[0]] !==
                              undefined
                          : formData.additionalData[field.depend[0]] ===
                              field.depend[1] ||
                            formData[field.depend[0]] === field.depend[1];

                      if (isDependentSatisfied) {
                        return (
                          <div
                            key={field._id}
                            className="form-group-newlisting"
                          >
                            <label htmlFor={field.name}>{field.name}</label>
                            {field.format === 'text' && (
                              <input
                                name={field.name}
                                value={
                                  formData.additionalData[field.name] || ''
                                }
                                onChange={(e) => handleAdditionalDataChange(e)}
                              />
                            )}

                            {field.format === 'dropdown' && (
                              <select
                                name={field.name}
                                value={
                                  formData.additionalData[field.name] || ''
                                }
                                onChange={(e) => handleAdditionalDataChange(e)}
                              >
                                <option value="">Select</option>
                                {field.options.split(',').map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )}

                            {field.format === 'date' && (
                              <input
                                type="date"
                                name={field.name}
                                value={
                                  formData.additionalData[field.name] || ''
                                }
                                onChange={(e) => handleAdditionalDataChange(e)}
                              />
                            )}
                          </div>
                        );
                      }
                    }
                  }
                  return null;
                })}

              <div className="form-group-newlisting">
                <label htmlFor="description">Note for broker</label>
                <textarea
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="buttonholder-newlisting">
              <button
                className="cancel-newlisting"
                onClick={(e) => {
                  handleCancel(e);
                }}
              >
                {' '}
                Cancel
              </button>
              <button type="submit" className="submit-newlisting">
                Submit
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default BuyInsurance;
