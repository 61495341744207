import config from '../utils/ApiUrl';
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const sendSupportMessage = async (formData) => {
  const response = await fetch(`${API_URL}/support-message`, {
    method: 'POST',
    headers: {
      bmi_api_key: bmi_api_key,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });

  return response.json();
};

export { sendSupportMessage };
