import config from '../utils/ApiUrl';
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const faqService = {
  async getFaqs() {
    try {
      const response = await fetch(`${API_URL}/faqs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          bmi_api_key: bmi_api_key,
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      const faqs = await response.json();
      return faqs;
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default faqService;
