import React, { useState, useEffect } from 'react';
import './Settings.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changePasswordMail } from '../services/passwordService';
import userService from '../services/userService';
import SessionExpired from './SessionExpired';

const Settings = ({ user }) => {
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [deleteAccountPassword, setDeleteAccountPassword] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const fetchUserProfile = () => {
    setUserId(user._id);
    setUserEmail(user.email);
  };
  const handleLogout = () => {
    document.cookie = 'BMI-token=; path=/; max-age=0; secure; SameSite=Strict';
    localStorage.setItem('isLogged', false);
    window.location.href = 'http://www.beatmyinsurance.com';
  };

  const handleDeleteAccount = async () => {
    try {
      const result = await userService.deleteAccount(
        userId,
        deleteAccountPassword
      );
      if (result.message) {
        notifySuccess(result.message);
        handleLogout();
      } else {
        notifyDanger(result.error || 'Something went wrong');
      }
    } catch (error) {
      notifyDanger('something went wrong', error);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword === confirmPassword) {
      const formData = {
        userId: userId,
        userEmail: userEmail,
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      try {
        const response = await changePasswordMail(formData);

        if (response.error) {
          notifyDanger(response.error);
        } else {
          if (response.message) {
            notifySuccess('Check your mail for verification');
          } else {
            notifyDanger('Please enter valid details');
          }
        }
      } catch (error) {
        notifyDanger('something went wrong');
      }

      setPasswordPopup(false);
    } else {
      notifyDanger('Passwords doesnot match!');
    }
  };

  const handleCancelChangePassword = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setPasswordPopup(!passwordPopup);
  };

  const handlecancelDeletePassword = () => {
    setDeleteAccountPassword('');
    setDeleteAccount(!deleteAccount);
  };

  return (
    <div className="settings-container">
      <ToastContainer />
      {sessionExpired && <SessionExpired />}
      <h1 className="settings-heading">Settings</h1>
      <div className="settings-box">
        <div className="setting-item">
          <div className="setting-item-head">
            <img src="/lock.png" alt="lock" />
            <p>User Password</p>
          </div>
          <button onClick={handleCancelChangePassword}>Change Password</button>
        </div>
        {passwordPopup && (
          <div className="password-popup">
            <p className="change-password-head">Change Password</p>
            <label className="oldpassword-label">Old Password:</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <label className="newpassword-label">New Password:</label>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label className="confirmpassword-label">
              Confirm New Password:
            </label>
            <input
              type="password"
              placeholder="Re-enter new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="password-pop-buttons">
              <button
                className="cancel-button-password"
                onClick={handleCancelChangePassword}
              >
                Cancel
              </button>
              <button
                className="submit-button-password"
                onClick={() => handleChangePassword()}
              >
                Submit
              </button>
            </div>
          </div>
        )}

        <div className="setting-item">
          <div className="setting-item-head">
            <img src="/bin.png" alt="bin" />
            <p>Remove My Account</p>
          </div>
          <button
            className="delete-button"
            onClick={handlecancelDeletePassword}
          >
            Delete My Account
          </button>
        </div>
        {deleteAccount && (
          <div className="delete-popup">
            <p className="delete-account-head">Remove Account</p>
            <p className="delete-account-info">
              Are you sure you want to remove the account? If you remove your
              account all of your data will be gone permanently.
              Provide password to remove your account permanently
            </p>
            <div className="delete-confirm">
              <label>Password</label>
              <br />
              <input
                type="password"
                placeholder="Enter password"
                onChange={(e) => setDeleteAccountPassword(e.target.value)}
              />
              <div className="delete-account-buttons">
                <button
                  className="delete-account-cancel"
                  onClick={handlecancelDeletePassword}
                >
                  Cancel
                </button>
                <button
                  className="delete-account-submit"
                  onClick={() => handleDeleteAccount()}
                >
                  Delete my account
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
