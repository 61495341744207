import React, { useState, useEffect } from 'react';
import NotificationService from '../services/notificationService';
import './Notifications.css';
import EditPost from './EditPost';
import SessionExpired from './SessionExpired';

const Notifications = ({ user }) => {
  const [postId, setPostId] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [providerPop, setProviderPop] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const userData = user;
      const userId = userData._id;
      const data = await NotificationService.getNotificationsForUser(userId);

      const sortedNotifications = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setNotifications(sortedNotifications);
    } catch (error) {
      alert('Failed to fetch notifications:', error.message);
    }
  };

  const handleNotificationClick = async (
    notificationread,
    notificationId,
    post_id,
    provider,
    selected_Notification
  ) => {
    if (!provider) {
      if (postId) {
        setPostId(false);
      } else {
        setPostId(post_id);
      }
    } else {
      setProviderPop(!providerPop);
      setSelectedNotification(selected_Notification);
    }

    try {
      if (!notificationread) {
        const data =
          await NotificationService.markNotificationAsRead(notificationId);
        if (data) {
          selectedNotification.read = true;
        }
      }
    } catch (error) {
      alert('Failed to mark notification as read:', error.message);
    }
  };
  const getTimeSinceUpload = (createDate) => {
    const now = new Date();
    const uploadDate = new Date(createDate);
    const diffMs = now - uploadDate;
    const diffMins = Math.floor(diffMs / 60000);
    const diffHrs = Math.floor(diffMs / 3600000);
    const diffDays = Math.floor(diffMs / 86400000);

    if (diffMins < 60) {
      if (diffMins === 1) {
        return `${diffMins} minute ago`;
      }
      return `${diffMins} minutes ago`;
    } else if (diffHrs < 24) {
      if (diffHrs === 1) {
        return `${diffHrs} hour ago`;
      }
      return `${diffHrs} hours ago`;
    } else {
      if (diffDays === 1) {
        return `${diffDays} day ago`;
      }
      return `${diffDays} days ago`;
    }
  };

  return (
    <div className="notifications-page">
      {sessionExpired && <SessionExpired />}
      <h1 className="notification-heading">Notifications</h1>
      <div className="notifications-holder">
        {notifications.length === 0 && (
          <div className="empty-holder-notifications">
            <img className="empty-notifications" src="/empty.png" alt="empty" />
            <button
              className="start-posting"
              onClick={() => {
                window.location.href = '/main/buyer-post';
              }}
            >
              Start Posting
            </button>
          </div>
        )}
        {notifications.map((notification) => (
          <div
            className="notification-card"
            key={notification._id}
            style={{
              fontWeight: notification.read ? 'normal' : 'bolder',
              background: notification.read
                ? 'white'
                : 'rgba(229, 249, 245, 1)',
            }}
            onClick={() =>
              handleNotificationClick(
                notification.read,
                notification._id,
                notification.postId,
                notification.provider,
                notification
              )
            }
          >
            <img src="/dot.png" alt="dot" />
            <p>{notification.message}</p>
            <span>({getTimeSinceUpload(notification.createdAt)})</span>
            <button>View</button>
          </div>
        ))}
      </div>
      {postId && (
        <div className="editpost-holder-notification">
          <EditPost postId={postId} userData={user} mode={'bids'} />
        </div>
      )}
      {providerPop && (
        <div className="bid-result">
          <div
            className="bid-result-close"
            onClick={() => setProviderPop(false)}
          >
            X
          </div>
          {selectedNotification.reply && (
            <div class="bid-accepted-pop">
              <p className="congrats">Congratulations!</p>
              <p className="bid-accept-info">{selectedNotification.message}</p>
              <p className="bid-accept-details">
                for further communications contact:
              </p>
              <p className="mail-link">
                <a href={`mailto:${selectedNotification.buyerEmail}`}>
                  {selectedNotification.buyerEmail}
                </a>
              </p>
              <button
                className="bid-accept-pop-close"
                onClick={() => window.location.reload()}
              >
                Close
              </button>
            </div>
          )}

          {!selectedNotification.reply && (
            <div class="bid-rejected-pop">
              <p>Sorry! The Bid has been rejected.</p>
              <p>Better Luck next time</p>
              <button
                className="bid-reject-pop-close"
                onClick={() => window.location.reload()}
              >
                Close
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
