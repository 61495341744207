import React, { useState } from 'react';
import './Help.css';
import FaqComponent from './Faq';
import { sendSupportMessage } from '../services/support';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionExpired from './SessionExpired';

const Help = () => {
  const [showFaq, setShowFaq] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    topic: '',
    message: '',
  });

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      topic: '',
      message: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendSupportMessage(formData);
      if (response.error) {
        notifyDanger(response.error);
      }
      if (response.message) {
        notifySuccess(response.message);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          topic: '',
          message: '',
        });
      }
    } catch (error) {
      notifyDanger('An error occurred while sending the message.');
    }
  };

  return (
    <div className="pagecontent-support">
      <ToastContainer />
      {sessionExpired && <SessionExpired />}
      {!showFaq && (
        <div className="support-page-holder">
          <h1 className="help-heading">Help & Support</h1>
          <div className="support-body">
            <div className="right-holder-s">
              <form className="form-s" onSubmit={handleSubmit}>
                <div className="name-holder-s">
                  <div className="name-details-s">
                    <p>First name*</p>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="name-details-s">
                    <p>Last name*</p>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Enter Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="email-topic-holder-s">
                  <div className="email-holder-s">
                    <p>Email*</p>
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter Email Address"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="topic-holder-s">
                    <p>Message Topic*</p>
                    <input
                      type="text"
                      name="topic"
                      placeholder="Enter Message Topic"
                      value={formData.topic}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="message-holder-s">
                  <p>Your message*</p>
                  <textarea
                    name="message"
                    placeholder="Write message here"
                    rows={10}
                    cols={30}
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <div className="buttons-holder-s">
                  <button
                    className="cancel-s"
                    onClick={(e) => {
                      handleCancel(e);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="submit-s" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="left-holder-s">
              <p className="get-in-touch">Get in Touch</p>
              <p className="side-head-left-s">
                Want to get in touch? Contact us using the form on the side or
                click below to read FAQ section.
              </p>
              <button onClick={() => setShowFaq(true)}> Jump to FAQ</button>
            </div>
          </div>
        </div>
      )}
      {showFaq && (
        <div className="faq-page-holder">
          <FaqComponent />
        </div>
      )}
    </div>
  );
};

export default Help;
