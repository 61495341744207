import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import customViewService from '../services/customViewService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notifySuccess = (alertMessage) => {
  toast.success(alertMessage);
};

const notifyDanger = (alertMessage) => {
  toast.error(alertMessage);
};

const fetchFields = async () => {
  try {
    const response = await customViewService.getFields();
    return response;
  } catch (err) {
    notifyDanger('Failed to fetch fields');
    return [];
  }
};

const fetchExistingViewSettings = async (id) => {
  try {
    const response = await customViewService.fetchExistingView(id);
    return response;
  } catch (err) {
    return [];
  }
};

const CustomView = ({ open = true, onClose, viewId }) => {
  const [typeNames, setTypeNames] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const fields = await fetchFields();
      setTypeNames(fields);

      if (viewId) {
        const existingSettings = await fetchExistingViewSettings(viewId);
        if (existingSettings && existingSettings.length > 0) {
          setSelectedFields(existingSettings);
        }
      }
    };

    loadData();
  }, [viewId]);

  const availableFields = typeNames.filter(
    (name) => !selectedFields.includes(name)
  );

  const updatePriority = (index, newType) => {
    const updatedFields = [...selectedFields];
    updatedFields[index] = newType;
    setSelectedFields(updatedFields);
  };

  const addPrioritySlot = () => {
    if (availableFields.length >= 0) {
      setSelectedFields([...selectedFields, '']);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const nonEmptyFields = selectedFields.filter((field) => field !== '');
      const response = await customViewService.uploadNewView(
        viewId,
        nonEmptyFields
      );

      onClose();
    } catch (error) {
      alert('Failed to save view settings. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      const popupContent = document.querySelector('.custom-popup-content');
      if (popupContent && !popupContent.contains(e.target)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, onClose]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
      <ToastContainer />
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 custom-popup-content">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">Set Custom View</h2>
          <button onClick={onClose} className="cursor-pointer">
            <X size={20} />
          </button>
        </div>

        <ul className="border p-2 rounded min-h-16 mt-4 max-h-[40vh] overflow-auto">
          {selectedFields.length > 0 ? (
            selectedFields.map((field, index) => (
              <li
                key={index}
                className="flex items-center justify-between p-2 bg-gray-100 rounded mb-1"
              >
                <span className="font-semibold">#{index + 1}</span>
                <select
                  value={field}
                  onChange={(e) => updatePriority(index, e.target.value)}
                  className="border rounded px-2 py-1 max-w-[70%]"
                >
                  <option value="">Select Type</option>
                  {availableFields.concat(field ? [field] : []).map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </li>
            ))
          ) : (
            <p className="text-gray-500 text-center">No priorities added</p>
          )}
        </ul>

        {availableFields.length >= 0 && (
          <button
            onClick={addPrioritySlot}
            className="mt-2 px-4 py-2 bg-green-500 text-white rounded w-full"
          >
            + Add Priority
          </button>
        )}

        <div className="flex justify-between mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded"
          >
            Go Back
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomView;
