import config from '../utils/ApiUrl';
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const updatePostBids = async (postId, userEmail, bidAmount, session) => {
  try {
    const response = await fetch(`${API_URL}/${postId}/bids`, {
      method: 'PUT',
      headers: {
        bmi_api_key: bmi_api_key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userEmail, bidAmount, session }),
    });

    if (!response.ok) {
      throw new Error('Failed to update post bids');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Failed to update post bids: ' + error.message);
  }
};

const acceptBid = async (data) => {
  try {
    const response = await fetch(`${API_URL}/bid-accept`, {
      method: 'POST',
      headers: {
        bmi_api_key: bmi_api_key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Failed to respond on bid');
    }

    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export { updatePostBids, acceptBid };
