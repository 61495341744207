import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowUpDown, Info, Filter, X, Search } from 'lucide-react';
import { fetchRateApprovalData } from '../services/calculatorService';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  Legend,
} from 'recharts';
import {
  Users,
  ChevronDown,
  Menu,
  Mail,
  MapPin,
  Facebook,
  Instagram,
  Youtube,
  Linkedin,
  ArrowRight,
} from 'lucide-react';
import { Helmet } from 'react-helmet';

const RateApprovalDashboard = () => {
  // State management remains the same
  const [activeView, setActiveView] = useState('details');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [sortField, setSortField] = useState('Insurer');
  const [sortDirection, setSortDirection] = useState('asc');
  const [showFilters, setShowFilters] = useState(false);
  const [insurerGroupFilter, setInsurerGroupFilter] = useState('');

  const [dateRange, setDateRange] = useState({
    start: '2024-01-01',
    end: '2025-12-31',
  });
  const [rateRange, setRateRange] = useState({
    min: -10,
    max: 40,
  });
  const [insuranceData, setInsuranceData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Insurance',
      submenu: [
        { label: 'Car Insurance', link: '/buy-insurance' },
        { label: 'Super Visa Insurance', link: '/buy-insurance' },
        { label: 'Home Insurance', link: '/buy-insurance' },
        { label: 'Life Insurance', link: '/buy-insurance' },
      ],
      size: 4,
    },
    {
      label: 'Tools & Calculators',
      submenu: [
        {
          label: 'Cheap Insurance Car Calculator',
          link: '/cheap-insurance-car-finder',
        },
        {
          label: 'Mortgage Penalty Calculator',
          link: '/mortgage-penalty-calculator',
        },
        {
          label: 'Car Insurance Rates-2025',
          link: '/car-insurance-rates-2025',
        },
        {
          label: 'Car Insurance Increase By Provider-2025',
          link: '/car-insurance-increase-by-provider-2025',
        },
      ],
      size: 2,
    },
    {
      label: 'Resources',
      submenu: [
        { label: 'Blogs', link: '/blogs' },
        { label: 'FAQ', link: '/faq' },
        { label: 'About Us', link: '/about' },
      ],
      size: 3,
    },
  ];

  useEffect(() => {
    fetchInsuranceData();
  }, []);

  const fetchInsuranceData = async () => {
    try {
      const response = await fetchRateApprovalData();
      if (response) {
        setInsuranceData(response.data);
      }
    } catch (err) {
      setInsuranceData([]);
    }
  };

  // Data processing logic remains the same
  const processedData = useMemo(() => {
    return insuranceData
      ? insuranceData.filter(
          (company) =>
            company['Market Share Year'] === 2023 &&
            new Date(company['Renewal Effective Date']) > new Date('2024-01-01')
        )
      : [];
  }, [insuranceData]);

  const filteredCompanies = useMemo(() => {
    let filtered = processedData.filter(
      (company) =>
        (company.Insurer.toLowerCase().includes(searchTerm.toLowerCase()) ||
          company['Insurer Group']
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        new Date(company['Renewal Effective Date']) >=
          new Date(dateRange.start) &&
        new Date(company['Renewal Effective Date']) <=
          new Date(dateRange.end) &&
        company['Approved Rate Change (%)'] >= rateRange.min &&
        company['Approved Rate Change (%)'] <= rateRange.max
    );

    return filtered.sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      return sortDirection === 'asc'
        ? aValue > bValue
          ? 1
          : -1
        : aValue < bValue
          ? 1
          : -1;
    });
  }, [
    processedData,
    searchTerm,
    sortField,
    sortDirection,
    dateRange,
    rateRange,
  ]);

  const filteredByInsurerGroup = useMemo(() => {
    // Step 1: Filter companies
    let result = filteredCompanies;

    if (insurerGroupFilter) {
      result = filteredCompanies.filter((company) =>
        company['Insurer Group']
          .toLowerCase()
          .includes(insurerGroupFilter.toLowerCase())
      );
    }

    // Step 2: Sort filtered companies
    if (sortField) {
      result = [...result].sort((a, b) => {
        const valueA = a[sortField] || '';
        const valueB = b[sortField] || '';

        // Ascending or descending sorting
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
        } else {
          return sortDirection === 'asc'
            ? valueA.toString().localeCompare(valueB.toString())
            : valueB.toString().localeCompare(valueA.toString());
        }
      });
    }

    return result;
  }, [filteredCompanies, insurerGroupFilter, sortField, sortDirection]);

  const marketStats = useMemo(() => {
    const totalMarketShare = filteredByInsurerGroup.reduce(
      (sum, company) => sum + company['Market Share (%)'],
      0
    );
    const averageRateChange =
      filteredByInsurerGroup.reduce(
        (sum, company) => sum + company['Approved Rate Change (%)'],
        0
      ) / filteredByInsurerGroup.length;
    const topInsurers = [...filteredByInsurerGroup]
      .sort((a, b) => b['Market Share (%)'] - a['Market Share (%)'])
      .slice(0, 5);

    return {
      totalMarketShare: totalMarketShare.toFixed(2),
      averageRateChange: averageRateChange.toFixed(2),
      topInsurers,
    };
  }, [filteredByInsurerGroup]);

  const rateChangeChartData = useMemo(() => {
    return filteredByInsurerGroup
      .sort(
        (a, b) => b['Approved Rate Change (%)'] - a['Approved Rate Change (%)']
      )
      .slice(0, 10);
  }, [filteredByInsurerGroup]);

  const handleSort = (field) => {
    setSortDirection((prevDirection) =>
      prevDirection === 'asc' ? 'desc' : 'asc'
    );
    setSortField(field);
  };

  const renderContent = () => {
    if (insuranceData) {
      switch (activeView) {
        case 'overview':
          return (
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-white p-4 rounded shadow">
                  <h3 className="font-bold mb-2">Total Market Share</h3>
                  <div className="text-2xl font-bold">
                    {marketStats.totalMarketShare}%
                  </div>
                </div>
                <div className="bg-white p-4 rounded shadow">
                  <h3 className="font-bold mb-2">Average Rate Change</h3>
                  <div className="text-2xl font-bold">
                    {marketStats.averageRateChange}%
                  </div>
                </div>
                <div className="bg-white p-4 rounded shadow">
                  <h3 className="font-bold mb-2">Total Companies</h3>
                  <div className="text-2xl font-bold">
                    {filteredCompanies.length}
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 rounded shadow">
                <h3 className="font-bold mb-4">Market Share Distribution</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={marketStats.topInsurers}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="Insurer"
                      angle={-45}
                      textAnchor="end"
                      height={80}
                      tick={({ x, y, payload }) => {
                        const words = [];
                        words.push('\n\n');
                        payload.value.split(' ').forEach((word) => {
                          words.push(word);
                        });
                        return (
                          <text
                            x={x}
                            y={y}
                            textAnchor="middle"
                            fill="#666"
                            fontSize={12}
                          >
                            {words.map((word, index) => (
                              <tspan x={x} y={y + index * 14} key={index}>
                                {word}
                              </tspan>
                            ))}
                          </text>
                        );
                      }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="Market Share (%)"
                      fill="#8884d8"
                      barSize={40}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          );
        case 'rate-changes':
          return (
            <div className="bg-white p-6 rounded shadow">
              <h3 className="font-bold mb-4">Rate Changes Analysis</h3>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={rateChangeChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="Insurer"
                    angle={-40}
                    textAnchor="end"
                    height={100}
                    tick={({ x, y, payload }) => {
                      const words = [];
                      words.push('\n\n');
                      payload.value.split(' ').forEach((word) => {
                        words.push(word);
                      });
                      return (
                        <text
                          x={x}
                          y={y}
                          textAnchor="middle"
                          fill="#666"
                          fontSize={12}
                        >
                          {words.map((word, index) => (
                            <tspan x={x} y={y + index * 14} key={index}>
                              {word}
                            </tspan>
                          ))}
                        </text>
                      );
                    }}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="Approved Rate Change (%)" fill="#8884d8">
                    {rateChangeChartData.map((entry, index) => (
                      <Cell
                        key={index}
                        fill={
                          entry['Approved Rate Change (%)'] > 0
                            ? '#82ca9d'
                            : '#ff7675'
                        }
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          );
        case 'details':
          return (
            <div className="bg-white p-4 rounded shadow">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th
                        className="text-left p-2 cursor-pointer"
                        onClick={() => handleSort('Insurer')}
                      >
                        Insurer{' '}
                        {sortField === 'Insurer' && (
                          <ArrowUpDown className="inline h-4 w-4" />
                        )}
                      </th>
                      <th
                        className="text-left p-2 cursor-pointer"
                        onClick={() => handleSort('Insurer Group')}
                      >
                        Group{' '}
                        {sortField === 'Insurer Group' && (
                          <ArrowUpDown className="inline h-4 w-4" />
                        )}
                      </th>
                      <th
                        className="text-left p-2 cursor-pointer"
                        onClick={() => handleSort('Approved Rate Change (%)')}
                      >
                        Rate Change{' '}
                        {sortField === 'Approved Rate Change (%)' && (
                          <ArrowUpDown className="inline h-4 w-4" />
                        )}
                      </th>

                      <th
                        className="text-left p-2 cursor-pointer"
                        onClick={() => handleSort('Market Share (%)')}
                      >
                        Market Share{' '}
                        {sortField === 'Market Share (%)' && (
                          <ArrowUpDown className="inline h-4 w-4" />
                        )}
                      </th>
                      <th
                        className="text-left p-2 cursor-pointer"
                        onClick={() => handleSort('Renewal Effective Date')}
                      >
                        Renewal Effective Date
                        {sortField === 'Renewal Effective Date' && (
                          <ArrowUpDown className="inline h-4 w-4" />
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCompanies.map(
                      (company) =>
                        filteredByInsurerGroup.includes(company) && (
                          <tr
                            key={company.Insurer}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => setSelectedCompany(company)}
                          >
                            <td className="p-2">{company.Insurer}</td>
                            <td className="p-2">{company['Insurer Group']}</td>
                            <td className="p-2">
                              {company['Approved Rate Change (%)'].toFixed(2)}%
                            </td>
                            <td className="p-2">
                              {company['Market Share (%)'].toFixed(2)}%
                            </td>
                            <td className="p-2">
                              {company['Renewal Effective Date']}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          );
        default:
          return null;
      }
    } else {
      return <p>loading...</p>;
    }
  };

  return insuranceData ? (
    <div className="space-y-4">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>Canada Car Insurance Provider Rate Changes 2025</title>
        <meta
          name="description"
          content="Compare insurance rate changes, market share, and renewal dates across providers | Auto Insurance Rates 2025"
        />
        <meta
          property="og:title"
          content="Canada Car Insurance Provider Rate Changes 2025"
        />
        <meta
          property="og:description"
          content="Compare insurance rate changes, market share, and renewal dates across providers | Auto Insurance Rates 2025"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta
          name="twitter:title"
          content="Canada Car Insurance Provider Rate Changes 2025"
        />{' '}
        <meta
          name="twitter:description"
          content="Compare insurance rate changes, market share, and renewal dates across providers | Auto Insurance Rates 2025"
        />{' '}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{' '}
        <meta name="twitter:site" content="@Beatmyinsurance" />
      </Helmet>
      <nav
        className="bg-blue-900 border-b"
        onClick={() => setOpenSubmenu(null)}
      >
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            <div className="flex-shrink-0">
              {/*<span className="text-2xl font-bold text-white">BeatMyInsurance</span>*/}
              <img
                onClick={() => navigate('/home')}
                src="/whitelogo.png"
                alt="Beat My Insurance"
                className="max-h-8 cursor-pointer"
              />
            </div>

            <div className="hidden md:flex items-center space-x-6">
              <div className="flex space-x-6">
                {menuItems.map((item) => (
                  <div
                    key={item.label}
                    className="relative"
                    onClick={() => setOpenSubmenu(item.label)}
                    onMouseEnter={() => setOpenSubmenu(item.label)}
                  >
                    <button
                      className={`flex items-center px-2 py-1 text-sm text-white `}
                    >
                      {item.label}
                      <ChevronDown className="ml-1 w-4 h-4" />
                    </button>

                    {openSubmenu === item.label && (
                      <div className="absolute z-50 left-0 mt-1 w-48 bg-white rounded-lg shadow-xl py-1">
                        {item.submenu.map((subItem) => (
                          <a
                            key={subItem.label}
                            href={subItem.link}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-50"
                          >
                            {subItem.label}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="flex items-center space-x-3">
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: false } })
                  }
                  className="inline-flex items-center text-sm text-white hover:text-blue-600"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Login
                </button>
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: true } })
                  }
                  className="inline-flex items-center text-sm bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Sign Up
                </button>
              </div>
            </div>

            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-white hover:text-white"
              >
                {isOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden border-t">
            <div className="px-2 py-2 space-y-1">
              {menuItems.map((item) => (
                <div key={item.label}>
                  <button
                    className="w-full text-left px-3 py-2 text-sm text-white "
                    onClick={() =>
                      setOpenSubmenu(
                        openSubmenu === item.label ? null : item.label
                      )
                    }
                  >
                    <div className="flex justify-between items-center">
                      {item.label}
                      <ChevronDown
                        className={`w-4 h-4 transform ${
                          openSubmenu === item.label ? 'rotate-180' : ''
                        }`}
                      />
                    </div>
                  </button>
                  {openSubmenu === item.label && (
                    <div className="pl-4">
                      {item.submenu.map((subItem) => (
                        <a
                          key={subItem.label}
                          href={subItem.link}
                          className="block px-3 py-2 text-sm text-white"
                        >
                          {subItem.label}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-center space-x-3 mb-1">
              <button
                onClick={() =>
                  navigate('/login', { state: { newUser: false } })
                }
                className="inline-flex items-center pl-6 text-sm text-white hover:text-blue-600"
              >
                <Users className="w-4 h-4 mr-1" />
                Login
              </button>
              <button
                onClick={() => navigate('/login', { state: { newUser: true } })}
                className="inline-flex items-center  text-sm  text-white px-3 py-1 rounded hover:bg-blue-700"
              >
                <Users className="w-4 h-4 mr-1" />
                Sign Up
              </button>
            </div>
          </div>
        )}
      </nav>
      <div className="p-4" onClick={() => setOpenSubmenu(null)}>
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">
            Insurance Rate Analysis Dashboard
          </h1>

          <div className="flex gap-2">
            <div className="relative flex justify-center">
              <Search className="absolute left-2 top-2 h-4 w-4 text-gray-500" />
              <input
                type="text"
                placeholder="Search insurers..."
                className="flex justify-center pl-8  border rounded"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button
              className="px-4 py-2 border rounded hover:bg-gray-100"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              {showFilters ? (
                <X className="h-4 w-4" />
              ) : (
                <Filter className="h-4 w-4" />
              )}
            </button>
          </div>
        </div>
        <h2 className="text-2xs mb-4">
          2025 Insurance Rate Change by Provider
        </h2>

        {showFilters && (
          <div className="bg-white p-4 rounded shadow">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="insurer-group" className="font-medium">
                    Filter by Insurer Group
                  </label>
                  <input
                    type="text"
                    id="insurer-group"
                    className="p-2 border rounded"
                    placeholder="Type Insurer Group"
                    value={insurerGroupFilter}
                    onChange={(e) => setInsurerGroupFilter(e.target.value)}
                  />
                </div>

                <h3 className="font-medium mb-2">Date Range</h3>
                <div className="flex gap-2">
                  <input
                    type="date"
                    className="p-2 border rounded"
                    value={dateRange.start}
                    onChange={(e) =>
                      setDateRange({ ...dateRange, start: e.target.value })
                    }
                  />
                  <input
                    type="date"
                    className="p-2 border rounded"
                    value={dateRange.end}
                    onChange={(e) =>
                      setDateRange({ ...dateRange, end: e.target.value })
                    }
                  />
                </div>
              </div>
              <div>
                <h3 className="font-medium mb-2">Rate Change Range (%)</h3>
                <div className="flex gap-2">
                  <input
                    type="number"
                    className="p-2 border rounded"
                    value={rateRange.min}
                    onChange={(e) =>
                      setRateRange({
                        ...rateRange,
                        min: Number(e.target.value),
                      })
                    }
                    placeholder="Min"
                  />
                  <input
                    type="number"
                    className="p-2 border rounded"
                    value={rateRange.max}
                    onChange={(e) =>
                      setRateRange({
                        ...rateRange,
                        max: Number(e.target.value),
                      })
                    }
                    placeholder="Max"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex gap-2 mb-4">
          <button
            className={`px-4 py-2 rounded ${
              activeView === 'overview'
                ? 'bg-blue-500 text-white'
                : 'border hover:bg-gray-100'
            }`}
            onClick={() => setActiveView('overview')}
          >
            Overview
          </button>
          <button
            className={`px-4 py-2 rounded ${
              activeView === 'rate-changes'
                ? 'bg-blue-500 text-white'
                : 'border hover:bg-gray-100'
            }`}
            onClick={() => setActiveView('rate-changes')}
          >
            Rate Changes
          </button>
          <button
            className={`px-4 py-2 rounded ${
              activeView === 'details'
                ? 'bg-blue-500 text-white'
                : 'border hover:bg-gray-100'
            }`}
            onClick={() => setActiveView('details')}
          >
            Details
          </button>
        </div>

        {insuranceData ? renderContent() : null}

        {selectedCompany && (
          <div className="bg-white p-4 rounded shadow mt-4">
            <h3 className="font-bold mb-4">
              Company Details: {selectedCompany.Insurer}
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-medium">Insurer Group</p>
                <p>{selectedCompany['Insurer Group']}</p>
              </div>
              <div>
                <p className="font-medium">Market Share</p>
                <p>{selectedCompany['Market Share (%)'].toFixed(2)}%</p>
              </div>
              <div>
                <p className="font-medium">Rate Change</p>
                <p>{selectedCompany['Approved Rate Change (%)'].toFixed(2)}%</p>
              </div>
              <div>
                <p className="font-medium">Approval Date</p>
                <p>{selectedCompany['Approval Date']}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <h4 className="ml-6">
        Data source :{' '}
        <a
          className="underline"
          href="https://autorateapprovals.fsrao.ca/?_gl=1*1v0zjxc*_gcl_au*NjM1NjMxNDI3LjE3MzM0Mjg4MTM.*_ga*MzE4Mzk2NTA2LjE3MzM0Mjg4MTI.*_ga_SBF9PKM7WQ*MTczMzg5Mjc4Mi4zLjEuMTczMzg5Mjc4OC41NC4wLjA."
        >
          Auto Rate Approvals
        </a>
      </h4>
      <div
        onClick={() => setOpenSubmenu(null)}
        className="bg-slate-900  text-white py-12"
      >
        <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold mb-4">BeatMyInsurance</h3>
            <p className="text-slate-300">
              Find the cheapest car insurance quotes. Save up to 50% on your
              auto insurance today!
            </p>
            <button
              onClick={() => navigate('/buy-insurance')}
              className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded flex items-center"
            >
              Get Free Quote <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2 text-slate-300">
              <li>
                <a
                  href="/cheap-insurance-car-finder"
                  className="hover:text-blue-400"
                >
                  Cheap Car Insurance
                </a>
              </li>
              <li>
                <a
                  href="/mortgage-penalty-calculator"
                  className="hover:text-blue-400"
                >
                  Mortgage Penalty Calculator
                </a>
              </li>
              <li>
                <a href="/about" className="hover:text-blue-400">
                  About Us
                </a>
              </li>
              <li>
                <a href="/blogs" className="hover:text-blue-400">
                  Blogs
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <div className="space-y-3 text-slate-300">
              {/*<p className="flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                1-800-BEAT-INS
              </p>*/}
              <p
                className="flex items-center"
                onClick={() =>
                  (window.location.href = 'mailto:info@beatmyinsurance.com')
                }
              >
                <Mail className="h-4 w-4 mr-2" />
                info@beatmyinsurance.com
              </p>
              <p className="flex items-center">
                <MapPin className="h-4 w-4 mr-2" />
                Ontario, Canada
              </p>
            </div>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Stay Updated</h4>
            <p className="text-slate-300 mb-4">
              Get the latest insurance deals and tips!
            </p>

            <div className="flex space-x-4 mt-4">
              <Facebook
                onClick={() => {
                  window.open(
                    'https://www.facebook.com/beatmyinsurance/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <img
                src="/twitter.png"
                alt="twitter"
                onClick={() => {
                  window.open(
                    'https://x.com/Beatmyinsurance',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <Instagram
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/beatmyinsurance/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <Youtube
                onClick={() => {
                  window.open(
                    'http://www.youtube.com/@BeatMyInsurance',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />

              <Linkedin
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/company/103720775/admin/feed/posts/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="max-w-7xl mx-auto px-4 mt-8 pt-8 border-t border-slate-700">
          <div className="flex flex-col md:flex-row justify-between items-center text-slate-400 text-sm">
            <p>&copy; 2024 BeatMyInsurance. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              {/*<a href="/privacy" className="hover:text-blue-400">
                Privacy Policy
              </a>*/}
              <a href="/terms" className="hover:text-blue-400">
                Terms of use | Privacy Policy
              </a>
              {/*}
              <a href="/sitemap" className="hover:text-blue-400">
                Sitemap
              </a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <p> loading...</p>
  );
};

export default RateApprovalDashboard;
