import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Import react-select
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchCalculatorOptions,
  fetchResult,
} from '../services/calculatorService';
import TermsPop from './TermsPop';
import CalculationResult from './CalculationResult';
import './Calculator.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Calculator = () => {
  const [loading, setLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState();
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [bodyStyleOptions, setBodyStyleOptions] = useState([]);
  const [modelYearOptions, setModelYearOptions] = useState([]);
  const [data, setData] = useState({
    Make: '',
    ModelYear: '',
    Model: '',
    BodyStyle: '',
  });
  const navigate = useNavigate();

  const fetchOptions = async (mode, inputData) => {
    try {
      setLoading(true);
      const response = await fetchCalculatorOptions(inputData);
      if (response.data) {
        if (mode === 'make') {
          setMakeOptions(
            response.data.make.map((make) => ({ value: make, label: make }))
          );
        } else if (mode === 'modelYear') {
          setModelYearOptions(
            response.data.modelYear
              .map((year) => ({ value: year, label: year }))
              .reverse()
          );
        } else if (mode === 'model') {
          setModelOptions(
            response.data.model.map((model) => ({ value: model, label: model }))
          );
        } else {
          setBodyStyleOptions(
            response.data.bodyStyle.map((bodyStyle) => ({
              value: bodyStyle,
              label: bodyStyle,
            }))
          );
        }
      } else {
        notifyDanger(response.error || 'Something went wrong!');
      }
      setLoading(false);
    } catch {
      notifyDanger('Something went wrong!');
    }
  };

  useEffect(() => {
    fetchOptions('make', {
      Make: '',
      ModelYear: '',
      Model: '',
      BodyStyle: '',
    });
  }, []);

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const handleCancel = () => {
    setShowTerms(false);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    setShowTerms(true);
  };

  const handleSubmit = async () => {
    setShowTerms(false);
    try {
      setLoading(true);
      const queryData = { ...data };
      if (
        queryData.Make.length === 0 ||
        queryData.Model.length === 0 ||
        queryData.BodyStyle.length === 0 ||
        queryData.ModelYear.length === 0
      ) {
        setLoading(false);
        notifyDanger('Please select all required fields');
        return;
      }

      const response = await fetchResult(queryData);

      if (response.data) {
        setResult(response.data);
        setShowResult(true);
      } else {
        notifyDanger(response.error || 'Something went wrong!');
      }
      setLoading(false);
    } catch (error) {
      notifyDanger('Something went wrong!', error);
    }
  };

  const handleSelectChange = (selectedOption, field, mode) => {
    var newData = {};
    if (field === 'Make') {
      newData = {
        Make: selectedOption?.value || '',
        ModelYear: '',
        Model: '',
        BodyStyle: '',
      };
    } else if (field === 'ModelYear') {
      newData = {
        Make: data.Make,
        ModelYear: selectedOption?.value || '',
        Model: '',
        BodyStyle: '',
      };
    } else if (field === 'Model') {
      newData = {
        Make: data.Make || '',
        ModelYear: data.ModelYear || '',
        Model: selectedOption?.value || '',
        BodyStyle: '',
      };
    } else {
      newData = { ...data, [field]: selectedOption?.value || '' };
    }
    setData(newData);
    if (selectedOption !== null) {
      fetchOptions(mode, newData);
    }
  };

  return (
    <div className="calculator-container">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>Find Cheap Car Insurance in Canada | Quick Auto Insurance</title>
        <meta
          name="description"
          content="Use our Cheap Insurance Car Finder to compare affordable auto insurance in Canada. Search by car make, model, year, and body style for the best rates!"
        />
        <meta
          property="og:title"
          content="Find Cheap Car Insurance in Canada | Quick Auto Insurance"
        />
        <meta
          property="og:description"
          content="Use our Cheap Insurance Car Finder to compare affordable auto insurance in Canada. Search by car make, model, year, and body style for the best rates!"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta
          name="twitter:title"
          content="Find Cheap Car Insurance in Canada | Quick Auto Insurance"
        />{' '}
        <meta
          name="twitter:description"
          content="Use our Cheap Insurance Car Finder to compare affordable auto insurance in Canada. Search by car make, model, year, and body style for the best rates!"
        />{' '}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{' '}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Beat My Insurance',
                item: 'https://www.beatmyinsurance.com',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Cheap Car Insurance Finder',
                item: 'https://www.beatmyinsurance.com/cheap-insurance-car-finder',
              },
            ],
          })}
        </script>
      </Helmet>
      <ToastContainer />
      <div className="calculator-header-bar">
        <img
          className="logo-calculator"
          src="/logo2.png"
          alt="Beat My Insurance"
          onClick={() => navigate('/')}
        />
        <img
          className="home-icon-calculator"
          src="/home.png"
          onClick={() => navigate('/')}
          alt="home"
        />
      </div>

      {loading && (
        <img className="loading-img" src="/loading.gif" alt="loading" />
      )}
      {showTerms && <TermsPop onAgree={handleSubmit} onCancel={handleCancel} />}
      {showResult && (
        <CalculationResult
          data={result}
          handleResultClose={() => setShowResult(false)}
        />
      )}
      {!loading && !showTerms && (
        <div className="form-container">
          <h1 className="calculator-heading">Cheap Insurance Car Finder</h1>
          <form onSubmit={handleSaveClick}>
            <label className="calculator-labels">Make*:</label>
            <Select
              options={makeOptions}
              value={makeOptions.find((opt) => opt.value === data.Make)}
              onChange={(selected) => {
                handleSelectChange(selected, 'Make', 'modelYear');
              }}
              placeholder="Select Make"
              isClearable={() => handleSelectChange('', 'Make', 'make')}
            />

            <label className="calculator-labels">Model Year*:</label>
            <Select
              isDisabled={data.Make.length < 1}
              options={modelYearOptions}
              value={modelYearOptions.find(
                (opt) => opt.value === data.ModelYear
              )}
              onChange={(selected) => {
                handleSelectChange(selected, 'ModelYear', 'model');
              }}
              placeholder="Select Model Year"
              isClearable={() =>
                handleSelectChange('', 'ModelYear', 'modelYear')
              }
            />

            <label className="calculator-labels">Model*:</label>
            <Select
              isDisabled={data.ModelYear.length < 1}
              options={modelOptions}
              value={modelOptions.find((opt) => opt.value === data.Model)}
              onChange={(selected) => {
                handleSelectChange(selected, 'Model', 'bodyStyle');
              }}
              placeholder="Select Model"
              isClearable={() => handleSelectChange('', 'Model', 'model')}
            />

            <label className="calculator-labels">Body Style*:</label>
            <Select
              isDisabled={data.Model.length < 1}
              options={bodyStyleOptions}
              value={bodyStyleOptions.find(
                (opt) => opt.value === data.BodyStyle
              )}
              onChange={(selected) => handleSelectChange(selected, 'BodyStyle')}
              placeholder="Select Body Style"
              isClearable={() =>
                handleSelectChange('', 'BodyStyle', 'bodyStyle')
              }
            />

            <button className="submit-btn-calculator" type="submit">
              Find
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Calculator;
