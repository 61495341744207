import config from '../utils/ApiUrl';
const API_URL = config.API_URL;

const fetchCalculatorOptions = async (queryData) => {
  const response = await fetch(
    `${API_URL}/fetch-calculator-options/` +
      `${encodeURIComponent(queryData.Make || 'null')}/` +
      `${encodeURIComponent(queryData.ModelYear || 'null')}/` +
      `${encodeURIComponent(queryData.Model || 'null')}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response.json();
};

const fetchResult = async (data) => {
  const response = await fetch(`${API_URL}/fetch-calculator-result`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

const fetchRateApprovalData = async () => {
  const response = await fetch(`${API_URL}/fetch-rate-approval-data`);
  return response.json();
};

export { fetchCalculatorOptions, fetchResult, fetchRateApprovalData };
