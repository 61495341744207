import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sellers.css';

const Sellers = () => {
  return (
    <div className="sellers-b-page">
      <img className="sellers-b-logo" src="/logo.png" alt="logo" />
      <p className="sellers-b-main-head">
        Why Pay For Leads When You <span>Can Get Them For Free?</span>
      </p>
      <p className="sellers-b-side-head">
        Let’s join hands and hit your goals together!
      </p>
      <button
        className="current-listings"
        onClick={() =>
          (window.location.href =
            'https://www.beatmyinsurance.com/sell-insurance')
        }
      >
        Current Listings
      </button>
      <img
        className="sellers-b-content"
        onClick={() =>
          (window.location.href =
            'https://www.beatmyinsurance.com/sell-insurance')
        }
        src="/sellersContent.png"
        alt="search buyers"
      />
      <img
        className="sellers-b-content-mobile"
        onClick={() =>
          (window.location.href =
            'https://www.beatmyinsurance.com/sell-insurance')
        }
        src="/sellersContentMobile.png"
        alt="search buyers"
      />
      <img
        className="sellers-b-comment1"
        src="/sellersComment1.png"
        alt="game changer"
      />
      <img
        className="sellers-b-comment2"
        src="/sellersComment2.png"
        alt="500 plus buyers"
      />
    </div>
  );
};
export default Sellers;
