import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Users, ChevronDown, Menu, X } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fetchServicePageDetails } from '../services/servicePageService';
import parse from 'html-react-parser';
import './ServicePages.css';
import { Helmet } from 'react-helmet';

const ServicePages = () => {
  const city = useParams().city;
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [pageDetails, setPageDetails] = useState();
  const [showAnswer, setShowAnswer] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Insurance',
      submenu: [
        { label: 'Car Insurance', link: '/buy-insurance/auto-insurance' },
        {
          label: 'Super Visa Insurance',
          link: '/buy-insurance/super-visa-insurance',
        },
        { label: 'Home Insurance', link: '/buy-insurance/home-insurance' },
        { label: 'Life Insurance', link: '/buy-insurance/life-insurance' },
      ],
      size: 4,
    },
    {
      label: 'Tools & Calculators',
      submenu: [
        {
          label: 'Cheap Insurance Car Calculator',
          link: '/cheap-insurance-car-finder',
        },
        {
          label: 'Mortgage Penalty Calculator',
          link: '/mortgage-penalty-calculator',
        },
        {
          label: 'Car Insurance Rates-2025',
          link: '/car-insurance-rates-2025',
        },
        {
          label: 'Car Insurance Increase By Provider-2025',
          link: '/car-insurance-increase-by-provider-2025',
        },
      ],
      size: 2,
    },
    {
      label: 'Resources',
      submenu: [
        { label: 'Blogs', link: '/blogs' },
        { label: 'FAQ', link: '/faq' },
        { label: 'About Us', link: '/about' },
      ],
      size: 3,
    },
  ];

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const response = await fetchServicePageDetails(city);
      if (response.servicePage) {
        setPageDetails(response.servicePage);
      } else {
        alert(response.error || 'No data found');
      }
    } catch (err) {
      alert('Something went wrong please try again later!');
    }
  };

  const SEOComponent = () => {
    if (!pageDetails?.seo) return null;

    return (
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>{pageDetails.seo.title || ''}</title>
        <meta name="description" content={pageDetails.seo.description || ''} />
        <meta property="og:title" content={pageDetails.seo.title || ''} />
        <meta
          property="og:description"
          content={pageDetails.seo.description || ''}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta name="twitter:title" content={pageDetails.seo.title || ''} />
        <meta
          name="twitter:description"
          content={pageDetails.seo.description || ''}
        />
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta name="twitter:site" content="@Beatmyinsurance" />
        {/* Inject JSON-LD Schema for SEO */}
        <script type="application/ld+json">
          {JSON.stringify(pageDetails.seo.seoSchema)}
        </script>
      </Helmet>
    );
  };

  return pageDetails ? (
    <div
      className="service-page"
      onClick={() => setOpenSubmenu(openSubmenu ? false : false)}
    >
      <SEOComponent />
      <nav className="bg-blue-900 border-b">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            <div className="flex-shrink-0">
              {/*<span className="text-2xl font-bold text-white">BeatMyInsurance</span>*/}
              <img
                src="/whitelogo.png"
                alt="Beat My Insurance"
                className="max-h-8"
              />
            </div>

            <div className="hidden md:flex items-center space-x-6">
              <div className="flex space-x-6">
                {menuItems.map((item) => (
                  <div
                    key={item.label}
                    className="relative"
                    onClick={() => setOpenSubmenu(item.label)}
                    onMouseEnter={() => setOpenSubmenu(item.label)}
                  >
                    <button
                      className={`flex items-center px-2 py-1 text-sm text-white `}
                    >
                      {item.label}
                      <ChevronDown className="ml-1 w-4 h-4" />
                    </button>

                    {openSubmenu === item.label && (
                      <div className="absolute z-50 left-0 mt-1 w-48 bg-white rounded-lg shadow-xl py-1">
                        {item.submenu.map((subItem) => (
                          <a
                            key={subItem.label}
                            href={subItem.link}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-50"
                          >
                            {subItem.label}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="flex items-center space-x-3">
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: false } })
                  }
                  className="inline-flex items-center text-sm text-white hover:text-blue-600"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Login
                </button>
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: true } })
                  }
                  className="inline-flex items-center text-sm bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Sign Up
                </button>
              </div>
            </div>

            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-white hover:text-white"
              >
                {isOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden border-t">
            <div className="px-2 py-2 space-y-1">
              {menuItems.map((item) => (
                <div key={item.label}>
                  <button
                    className="w-full text-left px-3 py-2 text-sm text-white "
                    onClick={() =>
                      setOpenSubmenu(
                        openSubmenu === item.label ? null : item.label
                      )
                    }
                  >
                    <div className="flex justify-between items-center">
                      {item.label}
                      <ChevronDown
                        className={`w-4 h-4 transform ${
                          openSubmenu === item.label ? 'rotate-180' : ''
                        }`}
                      />
                    </div>
                  </button>
                  {openSubmenu === item.label && (
                    <div className="pl-4">
                      {item.submenu.map((subItem) => (
                        <a
                          key={subItem.label}
                          href={subItem.link}
                          className="block px-3 py-2 text-sm text-white"
                        >
                          {subItem.label}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-center space-x-3 mb-1">
              <button
                onClick={() =>
                  navigate('/login', { state: { newUser: false } })
                }
                className="inline-flex items-center pl-6 text-sm text-white hover:text-blue-600"
              >
                <Users className="w-4 h-4 mr-1" />
                Login
              </button>
              <button
                onClick={() => navigate('/login', { state: { newUser: true } })}
                className="inline-flex items-center  text-sm  text-white px-3 py-1 rounded hover:bg-blue-700"
              >
                <Users className="w-4 h-4 mr-1" />
                Sign Up
              </button>
            </div>
          </div>
        )}
      </nav>
      <div className="service-page1">
        <h1 className="service-page1-head">
          Chasing cheap insurance in {pageDetails.city || '---'}
        </h1>
        <div className="service-page1-container">
          <img src="/comments-service.png" alt="people comments" />
          <div className="service-page1-container-mid">
            <img src="/new-way.png" alt="new way" />
          </div>
          <div className="service-page1-container-end">
            <div>
              <div className="service-page-benefit-points">
                <img src="/green-tick.png" alt="one form" />
                <p>One form</p>
              </div>
              <div className="service-page-benefit-points">
                <img src="/green-tick.png" alt="easy sharing" />
                <p>Easy Sharing</p>
              </div>
              <div className="service-page-benefit-points">
                <img src="/green-tick.png" alt="multiple offers" />
                <p>Multiple Offers</p>
              </div>
            </div>
            <div className="service-page-list-for-free">
              <button onClick={() => navigate('/buy-insurance')}>
                List for FREE now!
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works-container">
        <h2>How it Works</h2>
        <div className="how-it-works-container-content">
          <div className="how-it-works-container-content-left">
            <img src="/car-service.png" alt="how it works" />
            <p className="service-page-for-buyers">For Buyers</p>
            <p className="service-page-for-buyers-content">
              1. Add details <br />
              2. Share link or accept quote <br />
              3. Close the deal!
            </p>
            <p className="service-page-signup-text">Sign up for free now!</p>
            <div className="service-page-for-buyers-button">
              <button onClick={() => navigate('/buy-insurance')}>
                Start Listing
              </button>
            </div>
          </div>
          <div className="how-it-works-container-content-right">
            <img src="/sell-service.png" alt="how it works" />
            <p className="service-page-for-buyers">For Sellers</p>
            <p className="service-page-for-buyers-content">
              1. Sign up <br />
              2. Post quotes
            </p>
            <p className="service-page-signup-text">Sign up for free now!</p>
            <div className="service-page-for-buyers-button">
              <button onClick={() => navigate('/sell-insurance')}>
                View Leads
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="tired-of-dealing-container">
        <img
          src="/mobile-offers-page-with-bg.png"
          alt="beatmyinsurance offers page in mobile"
        />
        <div className="tired-of-dealing-right">
          <h3>
            Tired of dealing with a million forms? <br />
            We got you.{' '}
          </h3>
          <p>
            <span>All your insurance needs - in one app.</span> <br />
            Easy, fast, and no headaches. <br />
            Get covered, not stressed. 👊 <br />
          </p>
          <h3>Download the App for FREE Now!</h3>
          <div className="mobile-app-download-buttons">
            <img
              onClick={() =>
                (window.location.href = process.env.REACT_APP_IOS_APP_URL)
              }
              src="/ios-download.png"
              alt="download app on ios for free"
            />
            <img
              onClick={() =>
                (window.location.href = process.env.REACT_APP_ANDROID_APP_URL)
              }
              src="/android-download.png"
              alt="download app on android for free"
            />
          </div>
        </div>
      </div>
      <div className="service-page-features-container">
        <div className="service-page-features-left">
          <div className="service-page-features-top">
            <img src="/chats-blue.png" alt="in app messaging" />
            <h3>In App Messaging</h3>
          </div>
          <p>Stay anonymous and let brokers come to you!</p>
          <img src="/chat-screenshot.png" alt="chats" />
        </div>
        <img className="service-page-feature-bars" src="/bar.png" alt="and" />
        <div className="service-page-features-middle">
          <div className="service-page-features-top">
            <img src="/dashboard-service.png" alt="Unified Dashboard" />
            <h3>Unified Dashboard</h3>
          </div>
          <p>Track all your insurance posts in one place</p>
          <img src="/dashboard-screenshot.png" alt="dashboard" />
        </div>
        <img className="service-page-feature-bars" src="/bar.png" alt="and" />
        <div className="service-page-features-right">
          <div className="service-page-features-top">
            <img src="/bell-service.png" alt="Real-time Notifications" />
            <h3>Real-time Notifications</h3>
          </div>
          <p>Get alerts when brokers make offers on your post!</p>
          <img src="/notifications-screenshot.png" alt="notifications" />
        </div>
      </div>
      <div className="find-cheap-car-insurance-container">
        <div className="find-cheap-car-insurance-left">
          <h2>{pageDetails.heading || '---'}</h2>
          <p>{pageDetails.description || '---'}</p>
        </div>
        <div className="find-cheap-car-insurance-right">
          <div>
            <p>
              With this tool, know if your car is suitable for a good premium!
            </p>
            <button onClick={() => navigate('/cheap-insurance-car-finder')}>
              Cheap Insurance Car Calculator{' '}
            </button>
          </div>
          <div>
            <p>Compare insurance rate changes from these providers.</p>
            <button onClick={() => navigate('/car-insurance-rates-2025')}>
              Car Insurance Rates in 2025{' '}
            </button>
          </div>
        </div>
      </div>
      <div className="service-page-people-talks">
        <h2>
          What People are Talking About Insurance in {pageDetails.city || '---'}
        </h2>
        <div>
          {pageDetails.faq.map((faq, index) => (
            <div className="service-page-qa-item" key={index}>
              <div
                className="service-page-question"
                onClick={() => {
                  setShowAnswer(!showAnswer ? index : null);
                }}
              >
                {faq.question}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`service-page-chevron-icon ${
                    showAnswer === index ? 'rotate' : ''
                  }`}
                />
              </div>
              {showAnswer === index && (
                <div className="service-page-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <img className="empty-dashboard" src="/empty.png" alt="empty" />
  );
};
export default ServicePages;
