import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import faqService from '../services/faqService';
import './Faq.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const response = await faqService.getFaqs();
      setFaqs(response);
    } catch (error) {
      alert('Failed to fetch FAQs:', error.message);
    }
  };

  return (
    <div className="pageholder-faq">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>FAQ – Beat My Insurance</title>
        <meta
          name="description"
          content="Get answers on Beat My Insurance and find the cheapest car insurance and mortgage rates through competitive bidding."
        />
        <meta property="og:title" content="FAQ – Beat My Insurance" />
        <meta
          property="og:description"
          content="Get answers on Beat My Insurance and find the cheapest car insurance and mortgage rates through competitive bidding."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta name="twitter:title" content="FAQ – Beat My Insurance" />{' '}
        <meta
          name="twitter:description"
          content="Get answers on Beat My Insurance and find the cheapest car insurance and mortgage rates through competitive bidding."
        />{' '}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{' '}
        <meta name="twitter:site" content="@Beatmyinsurance" />
      </Helmet>
      <div className="contentholder">
        <div className="headingholder">
          <h1>FAQ</h1>
          <div className="absolute top-4 right-8">
            <img
              onClick={() => navigate('/')}
              className="home-btn-faq"
              src="/home.png"
              alt="home"
            />
          </div>
        </div>
        <div className="innercontent">
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div
                className="question"
                onClick={() => {
                  setShowAnswer(!showAnswer ? faq._id : null);
                }}
              >
                {faq.question}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`chevron-icon ${showAnswer === faq._id ? 'rotate' : ''}`}
                />
              </div>
              {showAnswer === faq._id && (
                <div className="answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
