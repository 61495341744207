import config from '../utils/ApiUrl';
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const createPaymentIntent = async (amount, userId, promoCode) => {
  try {
    const response = await fetch(`${API_URL}/create-payment-intent`, {
      method: 'POST',
      headers: {
        bmi_api_key: bmi_api_key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        userId,
        promoCode,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }
  } catch (error) {
    throw new Error('Error processing payment: ' + error.message);
  }
};

const createSubscription = async (amount, userId) => {
  try {
    const response = await fetch(`${API_URL}/create-subscription`, {
      method: 'POST',
      headers: {
        bmi_api_key: bmi_api_key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        userId,
      }),
    });

    return await response.json();
  } catch (error) {
    throw new Error('Error processing payment: ' + error.message);
  }
};

export { createPaymentIntent, createSubscription };
