import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import updateUserProfile from '../services/userProfileService';
import sellerService from '../services/sellersService';
import userService from '../services/userService';
import uploadImage from '../services/imageUpload';
import getStatesService from '../services/getStatesService';
import getTypesService from '../services/getTypesService';
import { alertSettings, getAlertSettings } from '../services/alertsService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionExpired from './SessionExpired';
import { Check } from 'lucide-react';

const Profile = ({ user }) => {
  const [userData, setUserData] = useState(null);
  const [image, setImage] = useState('/profile.png');
  const [imageEdit, setImageEdit] = useState(false);
  const [editDetails, seEditDetails] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [verifySellerPop, setVerifySellerPop] = useState(false);
  const [licenseNumber, setLicenseNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [licensedSince, setLicensedSince] = useState();
  const [states, setStates] = useState([]);
  const [types, setTypes] = useState([]);
  const [showPremiumSettings, setShowPremiumSettings] = useState(false);
  const [premiumSettings, setPremiumSettings] = useState({
    getNotified: { mobile: false, email: false },
    states: [],
    types: [],
  });
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const navigate = useNavigate();

  const fetchAlertSettings = async (id) => {
    try {
      const response = await getAlertSettings(id);
      if (response.data) {
        setPremiumSettings(response.data);
      }
    } catch (err) {
      return;
    }
  };

  const fetchStates = async () => {
    try {
      const states = await getStatesService.getStates();
      setStates(states);
    } catch (error) {
      notifyDanger('Failed to fetch states:', error.message);
    }
  };
  const fetchTypes = async () => {
    try {
      const types = await getTypesService.getTypes();
      setTypes(types);
    } catch (error) {
      notifyDanger('Failed to fetch types:', error.message);
    }
  };

  const handleStateToggle = (state) => {
    setPremiumSettings((prevSettings) => {
      const isSelected = prevSettings.states.includes(state);
      const updatedStates = isSelected
        ? prevSettings.states.filter((s) => s !== state)
        : [...prevSettings.states, state];

      return { ...prevSettings, states: updatedStates };
    });
  };

  const handleTypeToggle = (type) => {
    setPremiumSettings((prevSettings) => {
      const isSelected = prevSettings.types.includes(type);
      const updatedtypes = isSelected
        ? prevSettings.types.filter((s) => s !== type)
        : [...prevSettings.types, type];

      return { ...prevSettings, types: updatedtypes };
    });
  };
  const handleNotificationChange = (type) => {
    setPremiumSettings((prevSettings) => ({
      ...prevSettings,
      getNotified: {
        ...prevSettings.getNotified,
        [type]: !prevSettings.getNotified[type],
      },
    }));
  };

  const handlePremiumSettingsSubmit = async () => {
    try {
      const response = await alertSettings({
        id: user._id,
        premiumSettings: premiumSettings,
      });
      if (response.message) {
        setShowPremiumSettings(false);
        notifySuccess('Updated successfully');
      } else {
        notifyDanger('Something went wrong!');
      }
    } catch (err) {
      notifyDanger('Something went wrong!');
    }
  };

  const fetchUserProfile = () => {
    setUserData(user);
    setImage(user.image !== '' ? user.image : '/profile.png');
    fetchAlertSettings(user._id);
  };
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  const refreshLocal = async () => {
    const newuser = await userService.getUser(getCookie('BMI-token'));
    if (newuser.user) {
      setUserData(newuser.user);
      setImageEdit(false);
      seEditDetails(false);
      setImageFile();
      if (newuser.user.image !== '') {
        setImage(newuser.user.image);
      } else {
        setImage('/profile.png');
      }
    } else {
      setSessionExpired(true);
    }
    window.location.reload();
  };

  useEffect(() => {
    fetchUserProfile();
    fetchStates();
    fetchTypes();
  }, []);

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const handleSaveImage = async () => {
    try {
      const response = await uploadImage(
        userData._id,
        userData.image,
        imageFile
      );
      if (response.message) {
        notifySuccess('successfully uploaded');
        refreshLocal();
      } else {
        notifyDanger('Failed to upload');
      }
    } catch (error) {
      notifyDanger('Error uploading image');
    }
  };

  const handleDetailsSave = async (e) => {
    e.preventDefault();
    try {
      const result = await updateUserProfile(userData._id, userData);

      if (result.error) {
        notifyDanger(result.error);
        return;
      }
      notifySuccess('Profile updated successfully');
      refreshLocal();
    } catch (error) {
      notifyDanger(error);
    }
  };

  const handleCancel = () => {
    fetchUserProfile();
    setImageEdit(false);
    seEditDetails(false);
    fetchUserProfile();
  };

  const handleFirstNameChange = (e) => {
    setUserData({ ...userData, firstName: e.target.value });
  };
  const handleLastNameChange = (e) => {
    setUserData({ ...userData, lastName: e.target.value });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Customize options as needed
    return new Date(dateString).toLocaleDateString('en-CA', options); // 'en-CA' locale for Canada format
  };

  const handleVerifyClick = () => {
    setVerifySellerPop(!verifySellerPop);
  };

  const verifySellerSubmit = async (e) => {
    e.preventDefault();
    const userDetails = user;
    const formData = {
      licenseNumber: licenseNumber,
      companyName: companyName,
      licensedSince: licensedSince,
      userId: userDetails._id,
    };
    const response = await sellerService.applySellerVerification(formData);
    if (response.message) {
      notifySuccess(response.message);
      setLicenseNumber('');
      setCompanyName('');
      setLicensedSince();
      setVerifySellerPop(false);
    } else {
      notifyDanger(response.error || 'Something went wrong!');
    }
  };

  const handleSellerDetailsCancel = () => {
    setLicenseNumber('');
    setCompanyName('');
    setLicensedSince();
    setVerifySellerPop(false);
  };

  return (
    <div className="profile-page">
      <ToastContainer />
      {sessionExpired && <SessionExpired />}
      {userData ? (
        <>
          <h1 className="profile-heading">User Profile</h1>
          <div className="page-content-profile">
            <div className="header-container-profile">
              <div className="intro-profile-container">
                <div
                  className="intro-profile-img"
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                  onClick={() => setImageEdit(true)}
                ></div>
                <div className="intro-profile-content">
                  <p className="intro-profile-head">{userData.name}</p>
                </div>
              </div>
              {imageEdit && (
                <div className="image-edit-profile">
                  <input
                    name="image"
                    type="file"
                    accept=".jpg,.jpeg,image/jpeg"
                    onChange={handleImageChange}
                  />
                  <button
                    className="image-save-profile"
                    onClick={() => handleSaveImage()}
                  >
                    Save
                  </button>
                  <button
                    className="image-cancel-profile"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              <button
                className="profile-header-button"
                onClick={() => setImageEdit(true)}
              >
                <img src="/edittest.png" alt="edit" />
                <p>Edit</p>
              </button>
            </div>
            <div className="profile-details-container">
              <button
                className="profile-content-edit"
                onClick={() => seEditDetails(true)}
              >
                <img src="/edittest.png" alt="edit" />
                <p>Edit</p>
              </button>
              <div className="profile-first-name">
                <p className="profile-name-head">First Name</p>
                <p className="profile-name-content">
                  {userData.firstName || '-'}
                </p>
              </div>
              <div className="profile-last-name">
                <p className="profile-lastname-head">Last Name</p>
                <p className="profile-lastname-content">
                  {userData.lastName || '-'}
                </p>
              </div>
              <div className="profile-email">
                <p className="profile-email-head">Email Address</p>
                <p className="profile-email-content">{userData.email}</p>
              </div>
              <div className="profile-mobile">
                <p className="profile-mobile-head">Phone Number</p>
                <p className="profile-mobile-content">
                  {userData.mobileVerified ? (
                    userData.mobile
                  ) : (
                    <button
                      className="mobile-verify-btn-profile"
                      onClick={() =>
                        navigate(`/mobile-verification/${userData._id}`)
                      }
                    >
                      Verify
                    </button>
                  )}
                </p>
              </div>
              <div className="profile-bio">
                <p className="profile-bio-head">Bio</p>
                <p className="profile-bio-content">{userData.bio}</p>
              </div>
              <div className="profile-member">
                <p className="profile-member-head">Member Since</p>
                <p className="profile-member-content">
                  {formatDate(userData.joiningDate)}
                </p>
              </div>
              <div className="profile-seller-verify">
                {user.verifiedSeller ? (
                  <div className="verified-seller">
                    <h2>Verified Seller</h2>
                    <img src="/verified.png" alt="verified" />
                  </div>
                ) : (
                  <button onClick={() => handleVerifyClick()}>
                    Verify as a Seller
                  </button>
                )}
              </div>
              <div className="profile-premium-brokers">
                <button onClick={() => setShowPremiumSettings(true)}>
                  Get Notified
                </button>
              </div>
              {showPremiumSettings && user && !user.mobileVerified && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
                    <h2 className="text-lg font-bold text-gray-800 mb-4">
                      Verify mobile
                    </h2>
                    <p className="text-gray-600 mb-6">
                      Verify your phone number to start getting free leads and
                      closing deals!
                    </p>
                    <div className="flex justify-end">
                      <button
                        onClick={() => {
                          setShowPremiumSettings(false);
                        }}
                        className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mr-2 hover:bg-gray-300"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          navigate(`/mobile-verification/${user._id}`);
                        }}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      >
                        Verify Now
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {showPremiumSettings && user && !user.verifiedSeller && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
                    <h2 className="text-lg font-bold text-gray-800 mb-4">
                      Verify Seller Account
                    </h2>
                    <p className="text-gray-600 mb-6">
                      Please verify your seller account to start receiving
                      premium broker alerts.
                    </p>
                    <div className="flex justify-end">
                      <button
                        onClick={() => {
                          setShowPremiumSettings(false);
                        }}
                        className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mr-2 hover:bg-gray-300"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          setVerifySellerPop(true);
                          setShowPremiumSettings(false);
                        }}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      >
                        Verify Now
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {showPremiumSettings &&
                user &&
                user.verifiedSeller &&
                user.mobileVerified && (
                  <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                    onClick={() => {
                      showStateDropdown && setShowStateDropdown(false);
                      showTypeDropdown && setShowTypeDropdown(false);
                    }}
                  >
                    <div className="bg-white p-6 rounded shadow-md w-96">
                      <h3 className="text-xl font-bold mb-4">
                        Premium Settings
                      </h3>

                      {/* Notification Settings */}
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Get Notified:
                        </label>
                        <div className="flex items-center gap-4">
                          <label className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={premiumSettings.getNotified.mobile}
                              onChange={() =>
                                handleNotificationChange('mobile')
                              }
                              className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            Mobile
                          </label>
                          <label className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={premiumSettings.getNotified.email}
                              onChange={() => handleNotificationChange('email')}
                              className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            Email
                          </label>
                        </div>
                      </div>

                      {/* Manage States */}
                      <div className="mb-4 relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Manage States:
                        </label>
                        <button
                          onClick={() => setShowStateDropdown(true)}
                          className="w-full bg-gray-100 border rounded py-2 px-4 text-left flex justify-between items-center"
                        >
                          {premiumSettings.states.length > 0
                            ? premiumSettings.states.join(', ')
                            : 'Select States'}
                          <span className="ml-2">&#9662;</span>
                        </button>
                        {showStateDropdown && (
                          <div className="absolute bg-white border rounded mt-2 w-[75%] max-h-40 overflow-y-auto z-10">
                            {states.map((state) => (
                              <label
                                key={state}
                                className="block px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                                onClick={() => handleStateToggle(state)}
                              >
                                <input
                                  type="checkbox"
                                  checked={premiumSettings.states.includes(
                                    state
                                  )}
                                  className="form-checkbox"
                                />
                                {state}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>

                      <div className="mb-4 relative">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Manage Types:
                        </label>
                        <button
                          onClick={() => setShowTypeDropdown((prev) => !prev)}
                          className="w-full bg-gray-100 border rounded py-2 px-4 text-left flex justify-between items-center"
                        >
                          {premiumSettings.types.length > 0
                            ? premiumSettings.types.join(', ')
                            : 'Select Types'}
                          <span className="ml-2">&#9662;</span>
                        </button>
                        {showTypeDropdown && (
                          <div className="absolute bg-white border rounded mt-2 w-[75%] max-h-40 overflow-y-auto z-10">
                            {types.map((type) => (
                              <label
                                key={type}
                                className="block px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                                onClick={() => handleTypeToggle(type)}
                              >
                                <input
                                  type="checkbox"
                                  checked={premiumSettings.types.includes(type)}
                                  onChange={() => {}} // Empty onChange to avoid double triggers
                                  className="form-checkbox"
                                />
                                {type}
                              </label>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Action Buttons */}
                      <div className="mt-4 flex justify-evenly">
                        <button
                          onClick={handlePremiumSettingsSubmit}
                          className="bg-blue-900 text-white py-2 px-4 rounded"
                        >
                          Confirm
                        </button>
                        <button
                          onClick={() => {
                            setShowPremiumSettings(false);
                            setPremiumSettings({
                              getNotified: { mobile: false, email: false },
                              states: [],
                              types: [],
                            });
                          }}
                          className="bg-red-500 text-white py-2 px-4 rounded"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {verifySellerPop && (
              <form
                className="verify-seller-pop"
                onSubmit={(e) => verifySellerSubmit(e)}
              >
                <label htmlFor="licenseNumber">Agent Licence #:</label>
                <input
                  type="text"
                  name="licenseNumber"
                  value={licenseNumber}
                  autoFocus
                  onChange={(e) => setLicenseNumber(e.target.value)}
                  placeholder="Enter license number"
                />
                <label htmlFor="companyName">Company Name:</label>
                <input
                  type="text"
                  name="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter company name"
                />
                <label htmlFor="licensedSince">Licensed Since?</label>
                <input
                  type="date"
                  name="licensedSince"
                  value={licensedSince}
                  onChange={(e) => setLicensedSince(e.target.value)}
                />
                <button
                  className="seller-details-cancel"
                  onClick={() => handleSellerDetailsCancel()}
                >
                  Cancel
                </button>
                <button
                  className="seller-details-submit"
                  type="submit"
                  onClick={(e) => verifySellerSubmit(e)}
                >
                  Submit
                </button>
              </form>
            )}

            {editDetails && (
              <form
                className="edit-details-profile"
                onSubmit={(e) => handleDetailsSave(e)}
              >
                <label htmlFor="firstName">First Name*</label>
                <input
                  type="text"
                  className="name-edit-profile"
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleFirstNameChange}
                />
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="name-edit-profile"
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleLastNameChange}
                />
                <label htmlFor="mobile">Phone Number*</label>
                {/*<input
                  type="tel"
                  className="mobile-edit-profile"
                  name="mobile"
                  value={userData.mobile}
                  onChange={(e) => {
                    setUserData({ ...userData, mobile: e.target.value });
                  }}
                />*/}
                <button
                  className="mobile-edit-profile"
                  onClick={() =>
                    navigate(`/mobile-verification/${userData._id}`)
                  }
                >
                  Change Number
                </button>
                <label htmlFor="bio">Bio</label>
                <input
                  type="text"
                  className="bio-edit-profile"
                  name="bio"
                  value={userData.bio}
                  onChange={(e) => {
                    setUserData({ ...userData, bio: e.target.value });
                  }}
                />

                <button
                  className="profile-changes-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="profile-changes-save"
                  onClick={(e) => handleDetailsSave(e)}
                >
                  Save
                </button>
              </form>
            )}
          </div>
        </>
      ) : (
        <img src="/loading.gif" alt="loading" />
      )}
    </div>
  );
};

export default Profile;
