import React, { useState, useEffect } from 'react';
import buyerPostService from '../services/buyerPostService';
import getTypesService from '../services/getTypesService';
import getStatesService from '../services/getStatesService';
import './NewListing.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getAdminFieldsService from '../services/dynamicFieldsService';
import positionsService from '../services/indexingService';
import SessionExpired from './SessionExpired';
import { MessageCircle, Facebook, Link } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const NewListing = ({ user }) => {
  const userData = user;
  const [formData, setFormData] = useState({
    type: '',
    city: '',
    zip: '',
    state: '',
    currentPremium: '',
    expectedPremium: '',
    startDate: '',
    currentProvider: '',
    userEmail: userData.email,
    userId: userData._id,
    description: '',
    visibility: true,
    bids: {},
    additionalData: {},
  });
  const [types, setTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [fields, setFields] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState(false);
  const [showPrivacyPop, setShowPrivacyPop] = useState(false);
  const [showSharePop, setShowSharePop] = useState(false);
  const [postId, setPostId] = useState();

  useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const fields = await getAdminFieldsService.getAdminFields();
      const positionsResponse = await positionsService.getPositions();
      const positions = positionsResponse.positions;
      const orderedFields = [];
      positions.forEach((id) => {
        if (fields[id] !== undefined) {
          orderedFields.push(fields[id]);
        }
      });
      setFields(orderedFields);
    } catch (error) {
      notifyDanger('Failed to fetch fields:', error.message);
    }
  };

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  useEffect(() => {
    fetchTypes();
    fetchStates();
    const storedListing = localStorage.getItem('BMI-newlisting');

    if (storedListing) {
      setVerifyMessage(true);
      const newListingData = JSON.parse(storedListing);

      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        for (const key in newListingData) {
          if (updatedFormData.hasOwnProperty(key)) {
            updatedFormData[key] = newListingData[key];
          }
        }

        return updatedFormData;
      });

      localStorage.removeItem('BMI-newlisting');
    }
  }, []);

  const fetchTypes = async () => {
    try {
      const types = await getTypesService.getTypes();
      setTypes(types);
    } catch (error) {
      notifyDanger('Failed to fetch types:', error.message);
    }
  };
  const fetchStates = async () => {
    try {
      const states = await getStatesService.getStates();
      setStates(states);
    } catch (error) {
      notifyDanger('Failed to fetch states:', error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdditionalDataChange = (e) => {
    const { name, value } = e.target;
    if (value !== '') {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
      delete formData.additionalData[name];
    }
  };

  const handleToggle = () => {
    setFormData((prevState) => ({
      ...prevState,
      visibility: !prevState.visibility,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowPrivacyPop(false);
    try {
      const response = await buyerPostService.postDetails(formData);
      if (response.error) {
        notifyDanger(response.error);
      } else {
        notifySuccess('Successful');
        setPostId(response.insertedId || null);
        setShowSharePop(true);
        //window.location.href = "/main/dashboard";
      }
    } catch (error) {
      notifyDanger('Failed to post');
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setVerifyMessage(false);
    setFormData({
      type: '',
      city: '',
      zip: '',
      state: '',
      currentPremium: '',
      expectedPremium: '',
      startDate: '',
      currentProvider: '',
      userEmail: userData.email,
      userId: userData._id,
      description: '',
      visibility: true,
      bids: {},
      additionalData: {},
    });
  };

  const PrivacyPopup = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex items-start">
              <input
                type="checkbox"
                id="consent"
                className="mt-1 h-5 w-5 border-gray-300 rounded text-blue-600 focus:ring-blue-500"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="consent" className="ml-3 text-sm text-gray-700">
                By clicking Submit, you consent to sharing your information with
                sellers for business purposes.{' '}
                <a
                  href="/terms"
                  className="text-blue-600 underline hover:text-blue-800"
                >
                  View our Privacy Policy
                </a>{' '}
                to learn more.
              </label>
            </div>
            <button
              type="submit"
              className={`w-full py-2 px-4 rounded text-white ${
                isChecked ? 'bg-blue-900 hover:bg-blue-700' : 'bg-gray-400'
              }`}
              disabled={!isChecked}
            >
              Submit
            </button>
            <button
              onClick={() => setShowPrivacyPop(false)}
              className={`w-full py-2 px-4 rounded text-white
              bg-blue-900 hover:bg-blue-700
            }`}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    );
  };

  const SharePopup = () => {
    const [copied, setCopied] = useState(false);
    const navigate = useNavigate();

    const createRipple = (event) => {
      const button = event.currentTarget;
      const ripple = document.createElement('div');
      ripple.className = 'ripple';
      const rect = button.getBoundingClientRect();
      ripple.style.left = `${event.clientX - rect.left}px`;
      ripple.style.top = `${event.clientY - rect.top}px`;
      button.appendChild(ripple);
      setTimeout(() => ripple.remove(), 600);
    };

    const shareOnFacebook = (event) => {
      createRipple(event);
      const url = encodeURIComponent(
        `https://www.beatmyinsurance.com/sell-insurance/${postId || ''}`
      );
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      window.open(shareUrl, '_blank', 'width=600,height=400');
    };

    const shareOnWhatsApp = (event) => {
      createRipple(event);
      const url = encodeURIComponent(
        `https://www.beatmyinsurance.com/sell-insurance/${postId || ''}`
      );
      const message = encodeURIComponent(
        'Check this out at Beat My Insurance: \n'
      );
      const whatsappUrl = `https://wa.me/?text=${message}%20${url}`;

      window.open(whatsappUrl, '_blank');
    };

    const copyLink = (event) => {
      createRipple(event);
      navigator.clipboard
        .writeText(
          `https://www.beatmyinsurance.com/sell-insurance/${postId || ''}`
        )
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch(() => alert('Failed to copy link. Please try again.'));
    };

    return (
      <div className="flex items-start justify-center min-h-screen bg-[]  p-4 z-[100]">
        <div className="relative bg-white/90 p-6 rounded-3xl shadow-lg w-full max-w-sm  scale-90 animate-popIn overflow-hidden">
          <button
            className="absolute top-3 right-3 p-2 text-gray-500 hover:text-gray-700 transition-colors"
            onClick={() => {
              setShowSharePop(false);
              navigate('/main/dashboard');
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="absolute inset-0 pointer-events-none">
            <span className="absolute left-[15%] top-full text-2xl opacity-15 animate-float delay-[0s]">
              ✨
            </span>
            <span className="absolute left-[45%] top-full text-2xl opacity-15 animate-float delay-[3s]">
              🎯
            </span>
            <span className="absolute left-[75%] top-full text-2xl opacity-15 animate-float delay-[6s]">
              🚀
            </span>
          </div>
          <div className="text-gray-700 text-center font-semibold mb-3 mt-3">
            🎉 Congratulations! Your listing is live!
          </div>
          <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-purple-600 text-center mb-3">
            Challenge Your Network!
          </h2>
          <p className="text-center text-gray-700 mb-6">
            Share link with brokers
          </p>
          <div className="flex flex-col gap-3">
            <button
              className={`flex items-center justify-center gap-2 px-5 py-3 rounded-full text-white text-lg transition-all relative overflow-hidden w-full bg-pink-500 hover:bg-green-600 ${copied ? 'copied' : ''}`}
              onClick={copyLink}
            >
              <Link />
              Copy Challenge Link
              {copied && <span className="ml-2">✓</span>}
            </button>
            <button
              className="flex items-center justify-center gap-2 px-5 py-3 rounded-full text-white text-lg transition-all relative overflow-hidden w-full bg-blue-600 hover:bg-blue-700"
              onClick={shareOnFacebook}
            >
              <Facebook />
              Share on Facebook
            </button>
            <button
              className="flex items-center justify-center gap-2 px-5 py-3 rounded-full text-white text-lg transition-all relative overflow-hidden w-full bg-green-500 hover:bg-pink-600"
              onClick={shareOnWhatsApp}
            >
              <MessageCircle />
              Share on Whatsapp
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="pagecontent-buyerpost">
      {sessionExpired && <SessionExpired />}
      {verifyMessage ? (
        <h1 className="buyer-post-heading">Verify and Submit </h1>
      ) : (
        <h1 className="buyer-post-heading">New Listing </h1>
      )}
      <div className="toggle-container">
        <input
          type="checkbox"
          id="visibility-toggle"
          checked={formData.visibility}
          onChange={handleToggle}
          className="toggle-checkbox"
        />
        <label htmlFor="visibility-toggle" className="toggle-label">
          <span className="toggle-button"></span>
        </label>
        <p>{formData.visibility ? 'Active' : 'Inactive'}</p>
      </div>
      <div className="newlisting-body">
        <div
          className="buyer-post-container"
          style={{
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #e0e0e0',
          }}
        >
          {!showSharePop && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setShowPrivacyPop(true);
              }}
              /*onSubmit={handleSubmit}*/ className="buyer-post-form"
            >
              <div className="elements">
                <div className="form-group-newlisting">
                  <label htmlFor="type">Type*</label>
                  <div className="select-holder-buyerpost">
                    <select
                      name="type"
                      value={formData.type}
                      onChange={(e) => {
                        handleChange(e);
                        setFormData((prevState) => ({
                          ...prevState,
                          additionalData: {},
                        }));
                      }}
                    >
                      <option value="">Select Type</option>
                      {types.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group-newlisting">
                  <label htmlFor="state">State/Province*</label>
                  <select
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group-newlisting">
                  <label htmlFor="city">City*</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group-newlisting">
                  <label htmlFor="zip">ZIP* (XXX XXX)</label>
                  <input
                    type="text"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                  />
                </div>

                {formData.type !== 'Mortgage Rate Quote' && (
                  <div className="form-group-newlisting">
                    <label htmlFor="currentPremium">
                      Current Premium* (per month)
                    </label>
                    <input
                      type="text"
                      name="currentPremium"
                      value={formData.currentPremium}
                      onChange={handleChange}
                    />
                  </div>
                )}
                {formData.type !== 'Mortgage Rate Quote' && (
                  <div className="form-group-newlisting">
                    <label htmlFor="expectedPremium">Expected Premium</label>
                    <input
                      type="text"
                      name="expectedPremium"
                      value={formData.expectedPremium}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="form-group-newlisting">
                  <label htmlFor="startDate">Start Date*</label>
                  <input
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                  />
                </div>
                {formData.type !== 'Mortgage Rate Quote' && (
                  <div className="form-group-newlisting">
                    <label htmlFor="currentProvider">Current Provider*</label>
                    <input
                      type="text"
                      name="currentProvider"
                      value={formData.currentProvider}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {fields &&
                  fields.map((field) => {
                    // Check if the field's category includes the form type
                    if (
                      field.category
                        ? field.category.includes(formData.type)
                        : false
                    ) {
                      // If the field does not have a 'depend' property
                      if (field.depend === undefined) {
                        return (
                          <div
                            key={field._id}
                            className="form-group-newlisting"
                          >
                            <label htmlFor={field.name}>
                              {field.name === 'Driver License Number'
                                ? 'Driver License Number *'
                                : field.name}
                            </label>

                            {field.format === 'text' && (
                              <input
                                name={field.name}
                                value={
                                  formData.additionalData[field.name] || ''
                                }
                                onChange={(e) => handleAdditionalDataChange(e)}
                              />
                            )}

                            {field.format === 'dropdown' && (
                              <select
                                name={field.name}
                                value={
                                  formData.additionalData[field.name] || ''
                                }
                                onChange={(e) => handleAdditionalDataChange(e)}
                              >
                                <option value="">Select</option>
                                {field.options.split(',').map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )}

                            {field.format === 'date' && (
                              <input
                                type="date"
                                name={field.name}
                                value={
                                  formData.additionalData[field.name] || ''
                                }
                                onChange={(e) => handleAdditionalDataChange(e)}
                              />
                            )}
                          </div>
                        );
                      }

                      // If the field has a 'depend' property
                      if (field.depend !== undefined) {
                        const isDependentSatisfied =
                          (field.category
                            ? field.category.includes(formData.type)
                            : false) && field.depend[1] === 'X---Any_Value---X'
                            ? formData.additionalData[field.depend[0]] !== '' &&
                              formData.additionalData[field.depend[0]] !==
                                undefined
                            : formData.additionalData[field.depend[0]] ===
                                field.depend[1] ||
                              formData[field.depend[0]] === field.depend[1];

                        if (isDependentSatisfied) {
                          return (
                            <div
                              key={field._id}
                              className="form-group-newlisting"
                            >
                              <label htmlFor={field.name}>{field.name}</label>
                              {field.format === 'text' && (
                                <input
                                  name={field.name}
                                  value={
                                    formData.additionalData[field.name] || ''
                                  }
                                  onChange={(e) =>
                                    handleAdditionalDataChange(e)
                                  }
                                />
                              )}

                              {field.format === 'dropdown' && (
                                <select
                                  name={field.name}
                                  value={
                                    formData.additionalData[field.name] || ''
                                  }
                                  onChange={(e) =>
                                    handleAdditionalDataChange(e)
                                  }
                                >
                                  <option value="">Select</option>
                                  {field.options.split(',').map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              )}

                              {field.format === 'date' && (
                                <input
                                  type="date"
                                  name={field.name}
                                  value={
                                    formData.additionalData[field.name] || ''
                                  }
                                  onChange={(e) =>
                                    handleAdditionalDataChange(e)
                                  }
                                />
                              )}
                            </div>
                          );
                        }
                      }
                    }
                    return null;
                  })}

                <div className="form-group-newlisting">
                  <label htmlFor="description">Note for broker</label>
                  <textarea
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="buttonholder-newlisting">
                <button
                  className="cancel-newlisting"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  {' '}
                  Cancel
                </button>
                <button type="submit" className="submit-newlisting">
                  Submit
                </button>
              </div>
            </form>
          )}
          <ToastContainer />
        </div>
      </div>
      {showPrivacyPop && <PrivacyPopup />}
      {showSharePop && <SharePopup />}
    </div>
  );
};

export default NewListing;
