import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { TrendingUp, Banknote, PieChart } from 'lucide-react'; // For icons

// Constants
const COLORS = {
  primary: '#2563eb', // Royal Blue
  secondary: '#10b981', // Emerald
  accent: '#8b5cf6', // Purple
  warning: '#f59e0b', // Amber
  danger: '#ef4444', // Red
  gray: '#6b7280', // Cool Gray
};

// Placeholder UI components (These would normally come from a UI library)
const Card = ({ children, className }) => (
  <div className={`border rounded-lg shadow-md ${className}`}>{children}</div>
);

const CardHeader = ({ children, className }) => (
  <div className={`p-4 ${className}`}>{children}</div>
);

const CardTitle = ({ children }) => (
  <h2 className="text-xl font-semibold">{children}</h2>
);

const CardContent = ({ children }) => <div className="p-4">{children}</div>;

const Input = ({ id, name, value, onChange, type }) => (
  <input
    id={id}
    name={name}
    type={type}
    value={value}
    onChange={onChange}
    className="border p-2 w-full rounded-md"
  />
);

const Label = ({ children, htmlFor }) => (
  <label htmlFor={htmlFor} className="block text-sm font-medium">
    {children}
  </label>
);

const Alert = ({ children, className }) => (
  <div
    className={`bg-yellow-100 border-l-4 border-yellow-500 p-4 ${className}`}
  >
    {children}
  </div>
);

const AlertDescription = ({ children }) => <div>{children}</div>;

const Tabs = ({ children, defaultValue }) => <div>{children}</div>;

const TabsList = ({ children }) => <div className="flex">{children}</div>;

const TabsTrigger = ({ value, children }) => (
  <button className="px-4 py-2 border rounded-lg">{children}</button>
);

const TabsContent = ({ value, children }) => (
  <div className="mt-4">{children}</div>
);

// Main component
const RetirementCalculator = () => {
  const [inputs, setInputs] = useState({
    initialAmount: 1000000,
    annualReturn: 12,
    annualInvestmentRate: 5, // New input for annual investment rate
    withdrawalRate: 0.5,
    timelineYears: 20,
    inflationRate: 2.5,
    annualWithdrawalIncrease: 2.0,
    riskTolerance: 'moderate',
    investmentStyle: 'balanced',
  });

  const [results, setResults] = useState({
    monthlyWithdrawals: [],
    portfolioValues: [],
    totalWithdrawn: 0,
    finalAmount: 0,
    sustainabilityScore: 0,
    successProbability: 0,
    riskAnalysis: {
      riskLevel: 'moderate',
      recommendation: '',
    },
    monteCarlo: [],
    assetAllocation: [],
    monthlyData: [],
  });

  const runMonteCarloSimulation = () => {
    const simulations = 1000;
    const successfulRuns = Array(simulations)
      .fill(null)
      .map(() => {
        let portfolio = inputs.initialAmount;
        const monthlyReturn = inputs.annualReturn / 12 / 100;
        const monthlyWithdrawal =
          (inputs.initialAmount * (inputs.withdrawalRate / 100)) / 12;

        for (let year = 0; year < inputs.timelineYears; year++) {
          for (let month = 0; month < 12; month++) {
            const volatility = (Math.random() - 0.5) * 0.02;
            portfolio *= 1 + monthlyReturn + volatility;
            portfolio -=
              monthlyWithdrawal * (1 + inputs.inflationRate / 100) ** year;
          }
        }
        return portfolio > 0;
      });

    return (successfulRuns.filter(Boolean).length / simulations) * 100;
  };

  const calculateAssetAllocation = () => {
    const allocations = {
      conservative: [
        { name: 'Bonds', value: 60 },
        { name: 'Stocks', value: 30 },
        { name: 'Cash', value: 10 },
      ],
      moderate: [
        { name: 'Bonds', value: 40 },
        { name: 'Stocks', value: 50 },
        { name: 'Cash', value: 10 },
      ],
      aggressive: [
        { name: 'Bonds', value: 20 },
        { name: 'Stocks', value: 70 },
        { name: 'Cash', value: 10 },
      ],
    };

    return allocations[inputs.riskTolerance];
  };

  const calculateProjections = () => {
    const monthlyReturn = inputs.annualReturn / 12 / 100;
    const initialMonthlyWithdrawal =
      (inputs.initialAmount * (inputs.withdrawalRate / 100)) / 12;

    let currentPortfolioValue = inputs.initialAmount;
    let currentMonthlyWithdrawal = initialMonthlyWithdrawal;
    let totalWithdrawn = 0;

    const projectionData = [];
    const monthlyData = [];

    for (let year = 0; year <= inputs.timelineYears; year++) {
      let yearlyWithdrawal = 0;

      for (let month = 1; month <= 12; month++) {
        // Apply annual investment rate to portfolio
        currentPortfolioValue +=
          (inputs.annualInvestmentRate / 100) * currentPortfolioValue;

        currentPortfolioValue *= 1 + monthlyReturn; // Monthly growth
        currentPortfolioValue -= currentMonthlyWithdrawal; // Withdraw monthly

        yearlyWithdrawal += currentMonthlyWithdrawal;
        totalWithdrawn += currentMonthlyWithdrawal;

        monthlyData.push({
          month: year * 12 + month,
          withdrawal: currentMonthlyWithdrawal,
          portfolioValue: currentPortfolioValue,
        });

        if (month === 12) {
          currentMonthlyWithdrawal *= 1 + inputs.annualWithdrawalIncrease / 100; // Adjust withdrawal
        }
      }

      projectionData.push({
        year,
        portfolioValue: Math.round(currentPortfolioValue),
        withdrawalAmount: Math.round(yearlyWithdrawal),
        totalWithdrawn: Math.round(totalWithdrawn),
        inflationAdjustedValue: Math.round(
          currentPortfolioValue / Math.pow(1 + inputs.inflationRate / 100, year)
        ),
      });
    }

    const sustainabilityScore = Math.min(
      100,
      Math.max(
        0,
        (currentPortfolioValue / inputs.initialAmount) * 50 +
          (inputs.annualReturn - inputs.withdrawalRate) * 10 +
          (inputs.annualWithdrawalIncrease >= inputs.inflationRate ? 20 : 0)
      )
    );

    const successProbability = runMonteCarloSimulation();

    setResults({
      monthlyWithdrawals: monthlyData.map((d) => d.withdrawal),
      portfolioValues: projectionData,
      totalWithdrawn: Math.round(totalWithdrawn),
      finalAmount: Math.round(currentPortfolioValue),
      sustainabilityScore,
      successProbability,
      riskAnalysis: {
        riskLevel: inputs.riskTolerance,
        recommendation: getRecommendation(sustainabilityScore),
      },
      monteCarlo: [],
      assetAllocation: calculateAssetAllocation(),
      monthlyData,
    });
  };

  const getRecommendation = (score) => {
    if (score >= 80)
      return 'Your withdrawal strategy is well-balanced and sustainable.';
    if (score >= 60)
      return 'Consider small adjustments to improve long-term sustainability.';
    return 'Warning: Current withdrawal rate may need adjustment for long-term viability.';
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const getSustainabilityColor = (score) => {
    if (score >= 80) return COLORS.secondary;
    if (score >= 60) return COLORS.primary;
    if (score >= 40) return COLORS.warning;
    return COLORS.danger;
  };

  useEffect(() => {
    calculateProjections();
  }, [inputs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: parseFloat(value),
    }));
  };

  return (
    <div className="p-4">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {/* Basic Inputs */}
        <Card className="col-span-2 md:col-span-1">
          <CardHeader>
            <CardTitle>Retirement Planner</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4">
              <div>
                <Label htmlFor="initialAmount">Initial Amount</Label>
                <Input
                  id="initialAmount"
                  name="initialAmount"
                  value={inputs.initialAmount}
                  onChange={handleInputChange}
                  type="number"
                />
              </div>
              <div>
                <Label htmlFor="annualReturn">Annual Return (%)</Label>
                <Input
                  id="annualReturn"
                  name="annualReturn"
                  value={inputs.annualReturn}
                  onChange={handleInputChange}
                  type="number"
                />
              </div>
              <div>
                <Label htmlFor="annualInvestmentRate">
                  Annual Investment Rate (%)
                </Label>
                <Input
                  id="annualInvestmentRate"
                  name="annualInvestmentRate"
                  value={inputs.annualInvestmentRate}
                  onChange={handleInputChange}
                  type="number"
                />
              </div>
              <div>
                <Label htmlFor="withdrawalRate">Withdrawal Rate (%)</Label>
                <Input
                  id="withdrawalRate"
                  name="withdrawalRate"
                  value={inputs.withdrawalRate}
                  onChange={handleInputChange}
                  type="number"
                />
              </div>
              <div>
                <Label htmlFor="timelineYears">Timeline (Years)</Label>
                <Input
                  id="timelineYears"
                  name="timelineYears"
                  value={inputs.timelineYears}
                  onChange={handleInputChange}
                  type="number"
                />
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Portfolio Value Chart */}
        <Card className="col-span-2 md:col-span-1">
          <CardHeader>
            <CardTitle>Portfolio Value Projection</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={results.portfolioValues}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="portfolioValue"
                  stroke={COLORS.primary}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        {/* Monte Carlo Simulation */}
        <Card className="col-span-2 md:col-span-1">
          <CardHeader>
            <CardTitle>Monte Carlo Simulation</CardTitle>
          </CardHeader>
          <CardContent>
            <Alert className="mb-2">
              <AlertDescription>
                Success Probability: {results.successProbability}%
              </AlertDescription>
            </Alert>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RetirementCalculator;
