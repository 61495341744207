'use client';

import { useState } from 'react';
import { Search, CreditCard, X } from 'lucide-react';

// Mock implementations for missing UI components
const Card = ({ children }) => (
  <div className="bg-gray-100 rounded shadow p-4">{children}</div>
);
const CardHeader = ({ children }) => (
  <div className="border-b pb-2 mb-2">{children}</div>
);
const CardContent = ({ children }) => <div>{children}</div>;
const Button = ({ children, variant, ...props }) => (
  <button
    className={`px-4 py-2 rounded ${variant === 'outline' ? 'border' : 'bg-blue-600 text-white'}`}
    {...props}
  >
    {children}
  </button>
);

// Mock function for fetching card details
const getAllCards = () => [
  {
    bank: 'Bank A',
    cardName: 'SuperSaver Card',
    details: {
      color: 'bg-blue-500',
      annualFee: 0,
      rewards: { groceries: 5, travel: 3, dining: 2 },
    },
  },
  {
    bank: 'Bank B',
    cardName: 'TravelMax Card',
    details: {
      color: 'bg-purple-500',
      annualFee: 120,
      rewards: { travel: 4, dining: 3 },
    },
  },
];

const BestCardSelector = () => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Get all cards with their details
  const allCards = getAllCards();

  // Filter cards based on search query
  const filteredCards = allCards.filter((card) =>
    card.cardName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Add card to selection
  const addCard = (card) => {
    if (!selectedCards.some((sc) => sc.cardName === card.cardName)) {
      setSelectedCards([...selectedCards, card]);
    }
  };

  // Remove card from selection
  const removeCard = (cardName) => {
    setSelectedCards(
      selectedCards.filter((card) => card.cardName !== cardName)
    );
  };

  // Render reward rates
  const renderRewardRates = (rewards) => {
    return Object.entries(rewards)
      .filter(
        ([key, value]) =>
          key !== 'acceleratedCategories' && typeof value === 'number'
      )
      .sort((a, b) => b[1] - a[1])
      .map(([key, value]) => (
        <span
          key={key}
          className="inline-flex items-center bg-white/10 rounded px-2 py-1 text-sm mr-2 mb-2"
        >
          {key}: {value}%
        </span>
      ));
  };

  return (
    <div className="max-w-3xl mx-auto p-4 space-y-6">
      <div className="text-center space-y-2">
        <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Canadian Card Advisor
        </h1>
        <p className="text-gray-600">
          Select your credit cards to get personalized recommendations
        </p>
      </div>

      {/* Search */}
      <div className="relative flex justify-between items-center">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          placeholder="Search cards..."
          className="pl-10 flex justify-between items-center"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Selected Cards */}
      <div className="space-y-3">
        {selectedCards.map((card) => (
          <div
            key={card.cardName}
            className={`${card.details.color} text-white rounded-lg p-4 shadow-lg transition-all hover:scale-[1.02] relative`}
          >
            <Button
              variant="ghost"
              className="absolute top-2 right-2 h-8 w-8 p-0 text-white hover:bg-white/20"
              onClick={() => removeCard(card.cardName)}
            >
              <X className="h-4 w-4" />
            </Button>
            <div className="space-y-2">
              <div>
                <p className="font-semibold">{card.cardName}</p>
                <p className="text-sm text-white/80">{card.bank}</p>
              </div>
              <div className="flex flex-wrap">
                {renderRewardRates(card.details.rewards)}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Available Cards */}
      <Card>
        <CardHeader>
          <h2 className="text-xl font-semibold">Available Cards</h2>
        </CardHeader>
        <CardContent className="space-y-3">
          {filteredCards.map((card) => (
            <Button
              key={card.cardName}
              variant="outline"
              className="w-full justify-start space-x-3 h-auto py-3 hover:bg-gray-50"
              onClick={() => addCard(card)}
              disabled={selectedCards.some(
                (sc) => sc.cardName === card.cardName
              )}
            >
              <CreditCard
                className={`h-5 w-5 ${card.details.color} text-white rounded`}
              />
              <div className="text-left flex-1">
                <div className="flex justify-between items-start">
                  <p className="font-medium">{card.cardName}</p>
                  <span className="text-sm text-gray-500">
                    {card.details.annualFee > 0
                      ? `$${card.details.annualFee}/yr`
                      : 'No Fee'}
                  </span>
                </div>
                <p className="text-sm text-gray-500">{card.bank}</p>
                <div className="flex flex-wrap mt-1">
                  {renderRewardRates(card.details.rewards)}
                </div>
              </div>
            </Button>
          ))}
        </CardContent>
      </Card>

      {/* Continue Button */}
      <Button
        className="w-full bg-gradient-to-r py-2 from-blue-600 to-purple-600 hover:opacity-90"
        size="lg"
        disabled={selectedCards.length === 0}
      >
        Continue to Recommendations
      </Button>
    </div>
  );
};

export default BestCardSelector;
