import React, { useEffect, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPassword.css';
import SessionExpired from './SessionExpired';
import config from '../utils/ApiUrl';
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const ResetPasswordVerification = () => {
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [token, setToken] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);
  const navigate = useNavigate();
  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  useEffect(() => {
    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      setToken(token);
    };

    verifyEmail();
  }, [location]);

  const handleSubmit = async () => {
    if (password === confirm) {
      const formdata = {
        password: password,
        token: token,
      };
      try {
        const response = await fetch(
          `${API_URL}/verify-reset-password?token=${token}`,
          {
            method: 'POST',
            headers: {
              bmi_api_key: bmi_api_key,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formdata),
          }
        );
        const data = await response.json();

        if (data.error) {
          notifyDanger(data.error || 'Something went wrong');
        } else {
          notifySuccess(data.message);
          setSuccess(true);
          setTimeout(() => {
            window.location.href = 'https://www.beatmyinsurance.com/home';
          }, 5000);
        }
      } catch (error) {
        notifyDanger('Error verifying email');
      }
    } else {
      notifyDanger('Passwords doesnt match');
    }
  };

  return (
    <div className="reset-password-container">
      {sessionExpired && <SessionExpired />}
      <div className="reset-password-data">
        <label>Create Password</label>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <label>Re-type Password</label>
        <input
          type="password"
          onChange={(e) => setConfirm(e.target.value)}
          placeholder="Enter password again"
        />
        <button onClick={() => handleSubmit()}>Submit</button>
      </div>
      {success && (
        <div className="loading-changepassword">
          <img src="loading.gif" alt="loading" />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ResetPasswordVerification;
