import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import getTypesService from '../services/getTypesService';
import {
  Search,
  Users,
  TrendingDown,
  Home,
  Car,
  Plane,
  DollarSign,
  Award,
  Clock,
  Phone,
  Eye,
  MessageCircle,
  Lock,
  CheckCircle,
  XCircle,
  ArrowRight,
  Youtube,
  Linkedin,
  ChevronDown,
  Menu,
  X,
  TwitterIcon,
} from 'lucide-react';
import { Mail, MapPin, Facebook, Twitter, Instagram } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

// Component Definitions First
const ProductHighlight = ({
  icon: Icon,
  title,
  description,
  benefits,
  cta,
  ctaLink,
}) => (
  <div className="bg-white rounded-lg shadow-xl p-6 hover:shadow-2xl transition-shadow">
    <Icon className="w-12 h-12 text-blue-600 mb-4" />
    <h3 className="text-xl font-bold mb-3">{title}</h3>
    <p className="text-gray-600 mb-4">{description}</p>
    <ul className="space-y-2 mb-6">
      {benefits.map((benefit, index) => (
        <li key={index} className="flex items-start gap-2">
          <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
          <span className="text-gray-700">{benefit}</span>
        </li>
      ))}
    </ul>
    <button
      onClick={() => (window.location.href = ctaLink)}
      className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2"
    >
      {cta}
      <ArrowRight className="w-4 h-4" />
    </button>
  </div>
);

const ComparisonSection = () => (
  <div className="bg-gradient-to-r from-blue-50 to-white pb-16">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-4">
        Why Users Find Cheaper Car Insurance With Us 
      </h2>
      <h3 className="text-3xs pl-8 pr-8 text-center mb-12">
        As an independent platform, we work for you, not the insurance companies
        – helping you find genuinely cheap car insurance without compromising
        coverage. 
      </h3>
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          {/* Scrollable container */}
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse border border-gray-200">
              {/* Table Header */}
              <thead className="bg-blue-900 text-white">
                <tr>
                  <th className="px-4 py-2 text-left text-lg font-bold">
                    Feature
                  </th>
                  <th className="px-4 py-2 text-center text-lg font-bold">
                    beatmyinsurance.com
                  </th>
                  <th className="px-4 py-2 text-center text-lg font-bold">
                    Traditional Companies
                  </th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {[
                  { name: 'Single Data Entry', has: true },
                  { name: 'Anonymity', has: true },
                  { name: 'Competitive Bidding by Brokers', has: true },
                  { name: 'No Repeated Calls', has: true },
                  {
                    name: 'Message Agent Directly from Our Platform',
                    has: true,
                  },
                  { name: 'One-Click Activate/Deactivate Listings', has: true },
                  {
                    name: 'Easy Sharing on Whatsapp and other platforms',
                    has: true,
                  },
                  { name: "It's Free", has: true },
                ].map((feature, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                  >
                    <td className="px-4 py-2 text-blue-900 font-medium">
                      {feature.name}
                    </td>
                    <td className="px-4 py-2 text-center">
                      <CheckCircle className="w-6 h-6 text-green-500 mx-auto" />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <XCircle className="w-6 h-6 text-red-500 mx-auto" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Homepage1 = () => {
  const [activeTab, setActiveTab] = useState('buyer');
  const navigate = useNavigate();

  return (
    <div className="min-h-screen">
      <div className="bg-gradient-to-r from-blue-50 to-white py-16">
        <ComparisonSection />
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-4">
            {' '}
            Compare cheap car insurance & save money {' '}
          </h2>
          <p className="text-xl text-center text-gray-600 mb-12">
            Find the lowest car insurance and mortgage rates {' '}
          </p>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <ProductHighlight
              icon={Car}
              title="Cheap Car Insurance Rates"
              description=" save 30% with cheap auto insurance premiums through competitive bids.  "
              benefits={[
                'Get multiple bids from providers ',
                'Special rates for new drivers',
                'Multi-vehicle discounts available',
                ' Lowest car insurance rates',
              ]}
              cta="Get Car Insurance Quotes"
              ctaLink="/buy-insurance"
            />

            <ProductHighlight
              icon={Plane}
              title="Best Super Visa Insurance"
              description="Get affordable Super Visa travel insurance for your family"
              benefits={[
                'Lowest super visa insurance rates',
                'Pre-existing condition coverage',
                'Multiple deductible options',
                'Instant policy delivery',
              ]}
              cta="Compare Super Visa Rates"
              ctaLink="/buy-insurance"
            />

            <ProductHighlight
              icon={DollarSign}
              title="Today's Best Mortgage Rates"
              description="Access the lowest mortgage rates from multiple lenders"
              benefits={[
                'Best mortgage rates guaranteed  ',
                'First-time homebuyer specials',
                'Refinancing options',
                'Pre-approval in 24 hours',
              ]}
              cta="Check Mortgage Rates"
              ctaLink="/buy-insurance"
            />

            <ProductHighlight
              icon={Home}
              title="Home Insurance Deals"
              description="Find affordable home insurance quotes from multiple brokers "
              benefits={[
                'Complete property protection',
                'Flood and fire coverage',
                'Contents insurance included',
                'Multiple payment options',
              ]}
              cta="Get Home Insurance Quote"
              ctaLink="/buy-insurance"
            />

            <ProductHighlight
              icon={Users}
              title="Term Life Insurance"
              description="Secure your family's future with affordable life insurance"
              benefits={[
                'Competitive term rates',
                'Quick approval process',
                'Multiple coverage options',
                'No medical exam plans',
              ]}
              cta="Compare Life Insurance"
              ctaLink="/buy-insurance"
            />

            <ProductHighlight
              icon={Award}
              title="Auto & Home Bundle"
              description="Comprehensive business insurance solutions at competitive rates"
              benefits={[
                'Message Agents Directly  ',
                'Multiple Custom Bids ',
                'Stay Anonymous',
                'Centralized Dashboard Access  ',
              ]}
              cta="Get Business Coverage"
              ctaLink="/buy-insurance"
            />
          </div>
        </div>
      </div>

      <div className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Popular Insurance Categories
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              [
                'Car Insurance',
                [
                  'Get Multiple Bids',
                  'Stay Anonymous',
                  'Single Dashboard View',
                  'Message Agents Directly',
                ],
              ],
              [
                'Home Insurance',
                [
                  'Easy Details Sharing',
                  'Single Click Listing',
                  'Stay Anonymous',
                  'Multiple Bidding Process',
                ],
              ],
              [
                'Super Visa Insurance',
                [
                  'Easy Details Sharing',
                  'Single Click Listing',
                  'Stay Anonymous',
                  'Real time bids alerts',
                  'Easy renewal listing',
                ],
              ],
              [
                'Life Insurance',
                [
                  'Stay Anonymous',
                  'Message Directly to Agent',
                  'Single Dashboard',
                  'Get Multiple Bids',
                  'Single click Activate/Inactive listing',
                ],
              ],
              [
                'Auto & Home bundle',
                [
                  'Bundle Savings Available',
                  'Message Agents Directly',
                  'Multiple Provider Bids',
                  'Stay Anonymous',
                  'Centralized Dashboard Access',
                ],
              ],
              [
                'Travel Insurance',
                [
                  'Easy Date Customization',
                  'Easy Details Sharing',
                  'Single Click Listing',
                  'Stay Anonymous',
                  'Last Minute Pricing',
                ],
              ],
            ].map((category, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all"
              >
                <h3 className="text-xl font-bold mb-4">{category[0]}</h3>
                <ul className="space-y-3">
                  {category[1].map((feature, idx) => (
                    <li key={idx} className="flex items-center gap-2">
                      <CheckCircle className="w-5 h-5 text-green-500" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const HomepageNew = () => {
  const [activeTab, setActiveTab] = useState('buyer');
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const fetchTypes = async () => {
    try {
      const types = await getTypesService.getTypes();
      setInsuranceTypes(types);
    } catch (error) {
      alert('Failed to fetch types:', error.message);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  /*const insuranceTypes = [
    { type: "Car Insurance", options: ["New Driver", "Multi-Vehicle", "Commercial Vehicle"] },
    { type: "Super Visa Insurance", options: ["Parents/Grandparents", "Medical Coverage", "Extended Stay"] },
    { type: "Home Insurance", options: ["Homeowner", "Tenant", "Condo"] },
    { type: "Mortgage", options: ["First Time", "Renewal", "Refinance"] }
  ]; */

  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const menuItems = [
    {
      label: 'Insurance',
      submenu: [
        { label: 'Car Insurance', link: '/buy-insurance/auto-insurance' },
        {
          label: 'Super Visa Insurance',
          link: '/buy-insurance/super-visa-insurance',
        },
        { label: 'Home Insurance', link: '/buy-insurance/home-insurance' },
        { label: 'Life Insurance', link: '/buy-insurance/life-insurance' },
      ],
      size: 4,
    },
    {
      label: 'Tools & Calculators',
      submenu: [
        {
          label: 'Cheap Insurance Car Calculator',
          link: '/cheap-insurance-car-finder',
        },
        {
          label: 'Mortgage Penalty Calculator',
          link: '/mortgage-penalty-calculator',
        },
        {
          label: 'Car Insurance Rates-2025',
          link: '/car-insurance-rates-2025',
        },
        {
          label: 'Car Insurance Increase By Provider-2025',
          link: '/car-insurance-increase-by-provider-2025',
        },
      ],
      size: 2,
    },
    {
      label: 'Resources',
      submenu: [
        { label: 'Blogs', link: '/blogs' },
        { label: 'FAQ', link: '/faq' },
        { label: 'About Us', link: '/about' },
      ],
      size: 3,
    },
  ];

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;

    switch (selectedValue) {
      case 'Home Insurance':
        navigate('/buy-insurance/home-insurance');
        break;
      case 'Auto Insurance':
        navigate('/buy-insurance/auto-insurance');
        break;
      case 'Home and Auto (bundle)':
        navigate('/buy-insurance/home-and-auto-bundle');
        break;
      case 'Renters Insurance':
        navigate('/buy-insurance/renters-insurance');
        break;
      case 'Super Visa Insurance':
        navigate('/buy-insurance/super-visa-insurance');
        break;
      case 'Mortgage Rate Quote':
        navigate('/buy-insurance/mortgage-rate-quote');
        break;
      case 'Visitors Insurance':
        navigate('/buy-insurance/visitors-insurance');
        break;
      case 'Health & Dental Insurance':
        navigate('/buy-insurance/health-dental-insurance');
        break;
      case 'Life Insurance':
        navigate('/buy-insurance/life-insurance');
        break;
      default:
        break;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>Cheap Car Insurance | Super Visa | Best Mortgage Rates</title>
        <meta
          name="description"
          content="Find cheap car insurance, supervisa, and mortgage rates with BMI’s competitive bidding. Share your details securely with friends or agents via a single link."
        />
        <meta
          property="og:title"
          content="Cheap Car Insurance | Super Visa | Best Mortgage Rates"
        />
        <meta
          property="og:description"
          content="Find cheap car insurance, supervisa, and mortgage rates with BMI’s competitive bidding. Share your details securely with friends or agents via a single link."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta
          name="twitter:title"
          content="Cheap Car Insurance | Super Visa | Best Mortgage Rates"
        />{' '}
        <meta
          name="twitter:description"
          content="Find cheap car insurance, supervisa, and mortgage rates with BMI’s competitive bidding. Share your details securely with friends or agents via a single link."
        />{' '}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{' '}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Beat My Insurance',
            url: 'https://www.beatmyinsurance.com',
            description:
              'Find cheap car insurance, supervisa, and mortgage rates with BMI’s competitive bidding. Share your details securely with friends or agents via a single link.',
            logo: 'https://www.beatmyinsurance.com/logo.png',
            email: 'info@beatmyinsurance.com',
            address: {
              addressRegion: 'Ontario',
              addressCountry: 'Canada',
            },
            sameAs: [
              'https://www.facebook.com/beatmyinsurance/',
              'https://x.com/Beatmyinsurance',
              'https://www.instagram.com/beatmyinsurance/',
              'https://www.youtube.com/@BeatMyInsurance',
              'https://www.linkedin.com/company/beat-my-insurance/',
            ],
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FinancialProduct',
            name: 'Cheap Car Insurance Finder and Mortgage Penalty Calculator',
            description:
              'Explore our tools: the Cheap Insurance Car Finder to compare car insurance quotes and save money, and the Mortgage Penalty Calculator to estimate penalties on early mortgage repayment. Your one-stop solution for financial insights.',
            provider: {
              '@type': 'Organization',
              name: 'Beat My Insurance',
              url: 'https://www.beatmyinsurance.com',
            },
            offers: [
              {
                '@type': 'Offer',
                priceCurrency: 'CAD',
                price: 'Free',
                url: 'https://www.beatmyinsurance.com/cheap-insurance-car-finder',
                validFrom: '2024-09-01',
                itemOffered: {
                  '@type': 'WebApplication',
                  name: 'Cheap Insurance Car Finder',
                  url: 'https://www.beatmyinsurance.com/cheap-insurance-car-finder',
                  description:
                    'Search and compare cheap car insurance quotes with ease.',
                  applicationCategory: 'FinanceApplication',
                  operatingSystem: 'All',
                  browserRequirements: 'Requires JavaScript',
                },
              },
              {
                '@type': 'Offer',
                priceCurrency: 'CAD',
                price: 'Free',
                url: 'https://www.beatmyinsurance.com/mortgage-penalty-calculator',
                validFrom: '2024-09-01',
                itemOffered: {
                  '@type': 'WebApplication',
                  name: 'Mortgage Penalty Calculator',
                  url: 'https://www.beatmyinsurance.com/mortgage-penalty-calculator',
                  description:
                    'Estimate penalties for early mortgage repayment with this user-friendly tool.',
                  applicationCategory: 'FinanceApplication',
                  operatingSystem: 'All',
                  browserRequirements: 'Requires JavaScript',
                },
              },
            ],
            termsOfService: 'https://www.beatmyinsurance.com/terms',
            audience: {
              '@type': 'Audience',
              audienceType: 'Car Owners and Homeowners',
              geographicArea: {
                '@type': 'AdministrativeArea',
                name: 'Canada',
              },
            },
          })}
        </script>
      </Helmet>
      {/*<div className="bg-blue-900 text-white py-2">
        <div className="container mx-auto px-4 flex flex-col sm:flex-row justify-between items-center gap-[5%]">
          
          <div className="flex items-center mb-2 sm:mb-0 sm:w-auto w-full justify-between sm:justify-start">
            <div className="flex items-center w-full justify-center sm:justify-start">
              <img src="/whitelogo.png" alt="Logo" className="h-8" />
            </div>

            
            <div className="sm:hidden flex items-center justify-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-white p-2 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>

          
          <div className="hidden sm:block sm:flex-1 sm:text-center sm:mb-0 w-full justify-center mb-2 sm:mb-0">
            <p className="text-sm">
              🔥 Canada's First Anonymous Insurance Marketplace
            </p>
          </div>

          
          <div className="hidden sm:flex items-center mb-2 sm:mb-0 sm:w-auto w-full justify-center sm:justify-end space-x-4">
        
            <button
              onClick={() => {
                navigate("/login", { state: { newUser: false } });
              }}
              className="bg-transparent border-2 border-white text-white py-1 px-4 rounded-md hover:bg-white hover:text-blue-900 transition duration-200"
            >
              Login
            </button>
            <button
              onClick={() => {
                navigate("/login", { state: { newUser: true } });
              }}
              className="bg-transparent border-2 border-white text-white py-1 px-4 rounded-md hover:bg-white hover:text-blue-900 transition duration-200"
            >
              Signup
            </button>
          </div>
        </div>

        
        {isMenuOpen && (
          
          <div className="sm:hidden bg-blue-900 text-white flex flex-col items-center py-4 space-y-4">
            <p className="text-sm text-center">
              🔥 Canada's First Anonymous Insurance Marketplace
            </p>
            <button
              onClick={() => {
                navigate("/login", { state: { newUser: false } });
              }}
              className="bg-transparent border-2 border-white text-white py-1 px-4 rounded-md hover:bg-white hover:text-blue-900 transition duration-200"
            >
              Login
            </button>
            <button
              onClick={() => {
                navigate("/login", { state: { newUser: true } });
              }}
              className="bg-transparent border-2 border-white text-white py-1 px-4 rounded-md hover:bg-white hover:text-blue-900 transition duration-200"
            >
              Signup
            </button>
          </div>
        )}
      </div> */}
      <nav className="bg-blue-900 border-b">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            <div className="flex-shrink-0">
              {/*<span className="text-2xl font-bold text-white">BeatMyInsurance</span>*/}
              <img
                src="/whitelogo.png"
                alt="Beat My Insurance"
                className="max-h-8"
              />
            </div>

            <div className="hidden md:flex items-center space-x-6">
              <div className="flex space-x-6">
                {menuItems.map((item) => (
                  <div
                    key={item.label}
                    className="relative"
                    onClick={() => setOpenSubmenu(item.label)}
                    onMouseEnter={() => setOpenSubmenu(item.label)}
                  >
                    <button
                      className={`flex items-center px-2 py-1 text-sm text-white `}
                    >
                      {item.label}
                      <ChevronDown className="ml-1 w-4 h-4" />
                    </button>

                    {openSubmenu === item.label && (
                      <div className="absolute z-50 left-0 mt-1 w-48 bg-white rounded-lg shadow-xl py-1">
                        {item.submenu.map((subItem) => (
                          <a
                            key={subItem.label}
                            href={subItem.link}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-50"
                          >
                            {subItem.label}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="flex items-center space-x-3">
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: false } })
                  }
                  className="inline-flex items-center text-sm text-white hover:text-blue-600"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Login
                </button>
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: true } })
                  }
                  className="inline-flex items-center text-sm bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Sign Up
                </button>
              </div>
            </div>

            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-white hover:text-white"
              >
                {isOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden border-t">
            <div className="px-2 py-2 space-y-1">
              {menuItems.map((item) => (
                <div key={item.label}>
                  <button
                    className="w-full text-left px-3 py-2 text-sm text-white "
                    onClick={() =>
                      setOpenSubmenu(
                        openSubmenu === item.label ? null : item.label
                      )
                    }
                  >
                    <div className="flex justify-between items-center">
                      {item.label}
                      <ChevronDown
                        className={`w-4 h-4 transform ${
                          openSubmenu === item.label ? 'rotate-180' : ''
                        }`}
                      />
                    </div>
                  </button>
                  {openSubmenu === item.label && (
                    <div className="pl-4">
                      {item.submenu.map((subItem) => (
                        <a
                          key={subItem.label}
                          href={subItem.link}
                          className="block px-3 py-2 text-sm text-white"
                        >
                          {subItem.label}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-center space-x-3 mb-1">
              <button
                onClick={() =>
                  navigate('/login', { state: { newUser: false } })
                }
                className="inline-flex items-center pl-6 text-sm text-white hover:text-blue-600"
              >
                <Users className="w-4 h-4 mr-1" />
                Login
              </button>
              <button
                onClick={() => navigate('/login', { state: { newUser: true } })}
                className="inline-flex items-center  text-sm  text-white px-3 py-1 rounded hover:bg-blue-700"
              >
                <Users className="w-4 h-4 mr-1" />
                Sign Up
              </button>
            </div>
          </div>
        )}
      </nav>

      {/* Tab Navigation */}
      <div
        onClick={() => setOpenSubmenu(null)}
        className="bg-gradient-to-r from-blue-900 to-blue-800"
      >
        <div className="container mx-auto px-4">
          <div className="flex justify-center -mb-px">
            <button
              onClick={() => setActiveTab('buyer')}
              className={`w-full sm:w-1/2 md:w-96 py-3 sm:py-6 text-lg sm:text-xl font-semibold transition-colors
      ${
        activeTab === 'buyer'
          ? 'text-blue-900 bg-white rounded-t-lg border-t-4 border-blue-600'
          : 'text-white hover:text-blue-100'
      }`}
            >
              Looking for Insurance?
            </button>
            <button
              onClick={() => setActiveTab('seller')}
              className={`w-full sm:w-1/2 md:w-96 py-3 sm:py-6 text-lg sm:text-xl font-semibold transition-colors
      ${
        activeTab === 'seller'
          ? 'text-green-900 bg-white rounded-t-lg border-t-4 border-green-600'
          : 'text-white hover:text-blue-100'
      }`}
            >
              Insurance Agent?
            </button>
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div onClick={() => setOpenSubmenu(null)} className="bg-white">
        {activeTab === 'buyer' ? (
          /* Buyer Section */
          <div className="container mx-auto px-4 py-12 ">
            <div className="max-w-5xl mx-auto text-center">
              <h1 className="text-4xl font-bold text-blue-900 sm:text-4xl">
                Compare Cheap Car Insurance Quotes Anonymously
              </h1>
              <br />
              <span className="text-2xl font-normal text-blue-600 mb-6 sm:text-xl">
                No Phone Calls. No Emails. Just Great Rates.
              </span>

              {/* Search Form */}
              <div className="bg-white rounded-lg p-4 shadow-2xl">
                <div className="grid md:grid-cols-2 gap-6">
                  <select
                    onChange={handleSelectChange}
                    className="w-full p-4 border rounded-[50px] text-gray-700 text-lg"
                  >
                    <option>Select Insurance Type</option>
                    {insuranceTypes.map((type) => (
                      <option key={type}>{type}</option>
                    ))}
                  </select>

                  <button
                    className="w-full bg-blue-600 text-white p-4  rounded-[50px] hover:bg-blue-700 flex items-center justify-center gap-2 text-lg font-semibold"
                    onClick={() => navigate('/buy-insurance')}
                  >
                    <Search size={24} />
                    Get Free Quotes
                  </button>
                </div>

                {/* Benefits */}
                <div className="mt-8 grid md:grid-cols-3 gap-6">
                  <div className="flex items-center gap-3">
                    <Lock className="w-8 h-8 text-blue-600" />
                    <div className="text-left">
                      <h3 className="font-semibold">
                        Stay Anonymous or <br />
                        Easy sharing
                      </h3>
                      <p className="text-sm text-gray-600">
                        1-Click Share <br /> with Brokers/Agents
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <MessageCircle className="w-8 h-8 text-blue-600" />
                    <div className="text-left">
                      <h3 className="font-semibold">No Phone Calls</h3>
                      <p className="text-sm text-gray-600">
                        No Spam Messages <br /> Direct In-App Chat
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <DollarSign className="w-8 h-8 text-blue-600" />
                    <div className="text-left">
                      <h3 className="font-semibold">100% Free</h3>
                      <p className="text-sm text-gray-600">
                        Free Registration <br /> No Credit Card Needed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          /* Seller Section */
          <div className="container mx-auto px-4 py-12">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-4xl font-bold mb-6 text-green-800">
                Quality Leads for Insurance Brokers  <br />
              </h1>

              {/* Agent Actions */}
              <div className="bg-white rounded-lg p-8 shadow-2xl">
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="text-left space-y-4">
                    <h2 className="text-xl font-bold text-green-800">
                      New Agent?
                    </h2>
                    <p className="text-gray-600">
                      Register now to receive insurance leads and grow your
                      business.
                    </p>
                    <button
                      onClick={() =>
                        navigate('/login', { state: { newUser: true } })
                      }
                      className="w-full bg-green-600 text-white p-4 rounded-lg hover:bg-green-700 flex items-center justify-center gap-2 text-lg font-semibold"
                    >
                      <Users size={24} />
                      Register as Agent
                    </button>
                  </div>
                  <div className="text-left space-y-4">
                    <h2 className="text-xl font-bold text-green-800">
                      Existing Agent?
                    </h2>
                    <p className="text-gray-600">
                      View available leads and submit your competitive quotes.
                    </p>
                    <button
                      onClick={() => navigate('/sell-insurance')}
                      className="w-full border-2 border-green-600 text-green-600 p-4 rounded-lg hover:bg-green-50 flex items-center justify-center gap-2 text-lg font-semibold"
                    >
                      <Eye size={24} />
                      View Active Leads
                    </button>
                  </div>
                </div>

                {/* Benefits */}
                <div className="mt-8 grid md:grid-cols-3 gap-6">
                  <div className="flex items-center gap-3">
                    <Eye className="w-8 h-8 text-green-600" />
                    <div className="text-left">
                      <h3 className="font-semibold">Fresh Leads Daily</h3>
                      <p className="text-sm text-gray-600">
                        View without upfront fees
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <Users className="w-8 h-8 text-green-600" />
                    <div className="text-left">
                      <h3 className="font-semibold">Quality Prospects</h3>
                      <p className="text-sm text-gray-600">
                        Ready-to-buy customers
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <DollarSign className="w-8 h-8 text-green-600" />
                    <div className="text-left">
                      <h3 className="font-semibold">Free Sign Up</h3>
                      <p className="text-sm text-gray-600">
                        No credit card required
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-white p-6 rounded-lg  max-w-4xl mt-8 mx-auto">
                  <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
                    How to Get Cheap Car Insurance Quotes
                  </h2>
                  <div className="flex flex-col md:flex-row items-center justify-between  gap-6">
                    {/* Step 1 */}
                    <div className="flex flex-col items-center text-center">
                      <div className="bg-blue-100 p-2 rounded-full">
                        <img
                          src="/newform.png"
                          alt="Form Icon"
                          className="w-12 h-12"
                        />
                      </div>
                      <h3 className="font-semibold text-gray-700 mt-4">
                        Submit One Simple Form
                      </h3>
                      <p className="text-sm text-gray-500">
                        Quick & easy form submission
                      </p>
                    </div>

                    {/* Arrow */}
                    <div className="hidden md:block text-blue-500 text-3xl font-bold">
                      →
                    </div>

                    {/* Step 2 */}
                    <div className="flex flex-col items-center text-center">
                      <div className="bg-blue-100 p-2 rounded-full">
                        <img
                          src="/anonymous.png"
                          alt="Shield Icon"
                          className="w-12 h-12"
                        />
                      </div>
                      <h3 className="font-semibold text-gray-700 mt-4">
                        Stay Anonymous Throughout
                      </h3>
                      <p className="text-sm text-gray-500">
                        Protect your information
                      </p>
                    </div>

                    {/* Arrow */}
                    <div className="hidden md:block text-blue-500 text-3xl font-bold">
                      →
                    </div>

                    {/* Step 3 */}
                    <div className="flex flex-col items-center text-center">
                      <div className="bg-blue-100 p-2 rounded-full">
                        <img
                          src="/multiplebids.png"
                          alt="People Icon"
                          className="w-12 h-12"
                        />
                      </div>
                      <h3 className="font-semibold text-gray-700 mt-4">
                        Receive Multiple Bids
                      </h3>
                      <p className="text-sm text-gray-500">
                        Get competitive quotes
                      </p>
                    </div>
                  </div>

                  {/* Call to Action */}
                  <div className="mt-6 text-center">
                    <button
                      onClick={() => navigate('/buy-insurance')}
                      className="bg-blue-500 text-white py-2 px-6 rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300"
                    >
                      Get Your Free Quote Now →
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Rest of the content can go here 
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Available Insurance Types</h2>
        <div className="grid md:grid-cols-4 gap-6">
           
        </div>
      </div>*/}
      <Homepage1 />

      <div
        onClick={() => setOpenSubmenu(null)}
        className="bg-slate-900  text-white py-12"
      >
        <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold mb-4">BeatMyInsurance</h3>
            <p className="text-slate-300">
              Find the cheapest car insurance quotes. Save up to 50% on your
              auto insurance today!
            </p>
            <button
              onClick={() => navigate('/buy-insurance')}
              className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded flex items-center"
            >
              Get Free Quote <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2 text-slate-300">
              <li>
                <a
                  href="/cheap-insurance-car-finder"
                  className="hover:text-blue-400"
                >
                  Cheap Car Insurance
                </a>
              </li>
              <li>
                <a
                  href="/mortgage-penalty-calculator"
                  className="hover:text-blue-400"
                >
                  Mortgage Penalty Calculator
                </a>
              </li>
              <li>
                <a href="/about" className="hover:text-blue-400">
                  About Us
                </a>
              </li>
              <li>
                <a href="/blogs" className="hover:text-blue-400">
                  Blogs
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <div className="space-y-3 text-slate-300">
              {/*<p className="flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                1-800-BEAT-INS
              </p>*/}
              <p
                className="flex items-center"
                onClick={() =>
                  (window.location.href = 'mailto:info@beatmyinsurance.com')
                }
              >
                <Mail className="h-4 w-4 mr-2" />
                info@beatmyinsurance.com
              </p>
              <p className="flex items-center">
                <MapPin className="h-4 w-4 mr-2" />
                Ontario, Canada
              </p>
            </div>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Stay Updated</h4>
            <p className="text-slate-300 mb-4">
              Get the latest insurance deals and tips!
            </p>

            <div className="flex space-x-4 mt-4">
              <Facebook
                onClick={() => {
                  window.open(
                    'https://www.facebook.com/beatmyinsurance/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <img
                src="/twitter.png"
                alt="twitter"
                onClick={() => {
                  window.open(
                    'https://x.com/Beatmyinsurance',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <Instagram
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/beatmyinsurance/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
              <Youtube
                onClick={() => {
                  window.open(
                    'http://www.youtube.com/@BeatMyInsurance',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />

              <Linkedin
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/company/103720775/admin/feed/posts/',
                    '_blank',
                    'noopener,noreferrer'
                  );
                }}
                className="h-5 w-5 hover:text-blue-400 cursor-pointer"
              />
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="max-w-7xl mx-auto px-4 mt-8 pt-8 border-t border-slate-700">
          <div className="flex flex-col md:flex-row justify-between items-center text-slate-400 text-sm">
            <p>&copy; 2025 BeatMyInsurance. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              {/*<a href="/privacy" className="hover:text-blue-400">
                Privacy Policy
              </a>*/}
              <a href="/terms" className="hover:text-blue-400">
                Terms of use | Privacy Policy
              </a>
              {/*}
              <a href="/sitemap" className="hover:text-blue-400">
                Sitemap
              </a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageNew;
