import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MortgagePenalties.css'; // Optional: Add CSS for styling
import MortgageResult from './MortgageResult';
import { Helmet } from 'react-helmet-async';

const MortgageCalculator = () => {
  const [mortgageType, setMortgageType] = useState('Fixed');
  const [currentBalance, setCurrentBalance] = useState('');
  const [currentInterestRate, setCurrentInterestRate] = useState('');
  const [reinvestmentRate, setReinvestmentRate] = useState('');
  const [pendingTermMonths, setPendingTermMonths] = useState('');
  const [adminFee, setAdminFee] = useState(2500);
  const [cashback, setCashback] = useState(3500);
  const [dischargeFee, setDischargeFee] = useState(300);
  const [lawyerFee, setLawyerFee] = useState(1500);
  const [otherFee, setOtherFee] = useState(200);
  const [appraisalFee, setAppraisalFee] = useState(500);
  const [result, setResult] = useState(null);
  const [tips, setTips] = useState(null);
  const [showAdminDetails, setShowAdminDetails] = useState(false);
  const navigate = useNavigate();

  const toggleAdminDetails = () => {
    setShowAdminDetails(!showAdminDetails);
  };

  const updateAdminFee = () => {
    const total = dischargeFee + lawyerFee + otherFee + appraisalFee;
    setAdminFee(total);
  };

  const calculateMortgage = () => {
    const interestRate = parseFloat(currentInterestRate) / 100;
    const reinvestRate = parseFloat(reinvestmentRate) / 100;

    const threeMonthsPenalty = (currentBalance * interestRate) / 4;
    const IRD =
      currentBalance * (interestRate - reinvestRate) * (pendingTermMonths / 12);

    let penalty;
    if (mortgageType === 'Fixed') {
      penalty = Math.max(threeMonthsPenalty, IRD);
    } else {
      penalty = threeMonthsPenalty;
    }

    const totalCost = adminFee + penalty - cashback;

    setResult({
      currentBalance,
      interestRate,
      reinvestRate,
      pendingTermMonths,
      adminFee,
      threeMonthsPenalty,
      IRD,
      penalty,
      cashback,
      totalCost,
    });

    setTips(
      totalCost > 0
        ? `It might not make sense to switch right now as the total cost is CAD ${totalCost.toFixed(
            2
          )}, indicating a financial loss.`
        : `Switching makes financial sense, as you save CAD ${Math.abs(
            totalCost
          ).toFixed(2)}.`
    );
  };

  return (
    <div className="mortgage-page">
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>
          Mortgage Penalty Calculator -Save on Your Mortgage in Canada
        </title>
        <meta
          name="description"
          content="Calculate mortgage penalty savings easily. Enter mortgage type, balance, interest rate, term months, and more to discover potential savings on your mortgage!"
        />
        <meta
          property="og:title"
          content="Mortgage Penalty Calculator -Save on Your Mortgage in Canada"
        />
        <meta
          property="og:description"
          content="Calculate mortgage penalty savings easily. Enter mortgage type, balance, interest rate, term months, and more to discover potential savings on your mortgage!"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{' '}
        <meta
          name="twitter:title"
          content="Mortgage Penalty Calculator -Save on Your Mortgage in Canada"
        />{' '}
        <meta
          name="twitter:description"
          content="Calculate mortgage penalty savings easily. Enter mortgage type, balance, interest rate, term months, and more to discover potential savings on your mortgage!"
        />{' '}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{' '}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Beat My Insurance',
                item: 'https://www.beatmyinsurance.com',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Mortgage Penalty Calculator',
                item: 'https://www.beatmyinsurance.com/mortgage-penalty-calculator',
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="calculator-header-bar">
        <img
          className="logo-calculator"
          src="/logo2.png"
          alt="logo"
          onClick={() => navigate('/')}
        />
        <img
          className="home-icon-calculator"
          src="/home.png"
          onClick={() => navigate('/')}
          alt="home"
        />
      </div>
      <div className="calculator-mortgage">
        <h1 className="mortgage-heading">Mortgage Penalty Calculator</h1>

        <div className="input-group-mortgage">
          <label>Type of Mortgage:</label>
          <select
            value={mortgageType}
            onChange={(e) => setMortgageType(e.target.value)}
          >
            <option value="Fixed">Fixed</option>
            <option value="Variable">Variable</option>
          </select>
        </div>

        <div className="input-group-mortgage">
          <label>Current Mortgage Balance (CAD):</label>
          <input
            type="number"
            value={currentBalance}
            onChange={(e) =>
              setCurrentBalance(parseFloat(e.target.value) || '')
            }
            placeholder="e.g., 495000"
          />
        </div>

        <div className="input-group-mortgage">
          <label>Current Interest Rate (%):</label>
          <input
            type="number"
            value={currentInterestRate}
            onChange={(e) => setCurrentInterestRate(e.target.value)}
            placeholder="e.g., 3.5"
          />
        </div>

        <div className="input-group-mortgage">
          <label>Reinvestment Rate (%):</label>
          <input
            type="number"
            value={reinvestmentRate}
            onChange={(e) => setReinvestmentRate(e.target.value)}
            placeholder="e.g., 2.5"
          />
        </div>

        <div className="input-group-mortgage">
          <label>Pending Term Months:</label>
          <input
            type="number"
            value={pendingTermMonths}
            onChange={(e) => setPendingTermMonths(e.target.value)}
            placeholder="e.g., 24"
          />
        </div>

        <div className="input-group-mortgage">
          <label>Admin Fee (CAD) (approx):</label>
          <input type="number" value={adminFee} disabled={true} />
          <div className="admin-fee-section" onClick={toggleAdminDetails}>
            Customize Admin Fee Details
          </div>

          {showAdminDetails && (
            <div className="admin-details-mortgage">
              <label>Discharge Fee (CAD):</label>
              <input
                type="number"
                value={dischargeFee}
                onChange={(e) =>
                  setDischargeFee(parseFloat(e.target.value) || 0)
                }
                onBlur={updateAdminFee}
              />
              <label>Lawyer Fee (CAD):</label>
              <input
                type="number"
                value={lawyerFee}
                onChange={(e) => setLawyerFee(parseFloat(e.target.value) || 0)}
                onBlur={updateAdminFee}
              />
              <label>Other Fee (CAD):</label>
              <input
                type="number"
                value={otherFee}
                onChange={(e) => setOtherFee(parseFloat(e.target.value) || 0)}
                onBlur={updateAdminFee}
              />
              <label>Appraisal Fee (CAD):</label>
              <input
                type="number"
                value={appraisalFee}
                onChange={(e) =>
                  setAppraisalFee(parseFloat(e.target.value) || 0)
                }
                onBlur={updateAdminFee}
              />
            </div>
          )}
        </div>

        <div className="input-group-mortgage">
          <label>New Lender Discounts/Cashbacks (CAD):</label>
          <input
            type="number"
            value={cashback}
            onChange={(e) => setCashback(parseFloat(e.target.value) || 0)}
          />
        </div>

        <button className="button" onClick={calculateMortgage}>
          Calculate Savings
        </button>

        {result && (
          /*<div className="result">
          <p><strong>Total Cost: CAD {result.totalCost.toFixed(2)}</strong></p>
          <p>Penalty Applied: CAD {result.penalty.toFixed(2)}</p>
        </div>
        {
      currentBalance,
      interestRate,
      reinvestRate,
      pendingTermMonths,
      adminFee,
      threeMonthsPenalty,
      IRD,
      penalty,
      cashback,
      totalCost,
    }*/
          <MortgageResult
            result={result ? result : {}}
            tips={tips}
            handleResultClose={() => setResult(false)}
          />
        )}
      </div>
    </div>
  );
};

export default MortgageCalculator;
