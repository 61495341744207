import { React, useEffect, useState } from 'react';
import './Home.css';
import getTypesService from '../services/getTypesService';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Placeholder } from 'react-bootstrap';

const HomePage = () => {
  const [types, setTypes] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [visibility, setVisibility] = useState(false);
  //const [showProviderContent, setShowProviderContent] = useState(false);
  const [userRole, setUserRole] = useState('buyer');
  const [device, setDevice] = useState('desktop');
  const [tutorialType, setTutorialType] = useState('buy');
  const [showTutorial, setShowTutorial] = useState(false);
  const [showTools, setShowTools] = useState(false);
  //const [buyerOptions, setBuyerOptions] = useState(null);
  //const reviews = Array.from({ length: 16 }, (_, i) => i);
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  /*useEffect(() => {
    const regulator = setInterval(() => {
      setVisibility((prevVisibility) => !prevVisibility);
    }, 5000);
    fetchTypes();
    return () => clearInterval(regulator);
  }, []);*/

  const detectDevice = () => {
    const width = window.innerWidth;
    if (width >= 1000) {
      setDevice('desktop');
    } else if (width >= 550) {
      setDevice('tab');
    } else {
      setDevice('mobile');
    }
  };

  // Detect screen size on mount and on window resize
  useEffect(() => {
    detectDevice(); // Initial detection
    window.addEventListener('resize', detectDevice); // Re-detect on resize

    return () => {
      window.removeEventListener('resize', detectDevice); // Cleanup on unmount
    };
  }, []);

  const fetchTypes = async () => {
    try {
      const response = await getTypesService.getTypes();
      setTypes(response.slice(0, 8));
    } catch (error) {
      alert('Failed to fetch types:', error.message);
    }
  };

  const handleToggleClick = () => {
    setVisibility(!visibility);
  };

  return (
    <div className="homepage">
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <title>
          Get the Best Auto Insurance Quote Online | Beat My Insurance
        </title>
        <meta
          name="og:title"
          content="Get the Best Auto Insurance Quote Online | Beat My Insurance"
        />
        <meta
          name="twitter:title"
          content="Get the Best Auto Insurance Quote Online | Beat My Insurance"
        />
        <meta
          name="description"
          content="Get the best auto, home, life, and super visa insurance. Compare, save, and get your free quote today with Beat My Insurance."
        />
        <meta
          name="og:description"
          content="Get the best auto, home, life, and super visa insurance. Compare, save, and get your free quote today with Beat My Insurance."
        />
        <meta
          name="twitter:description"
          content="Get the best auto, home, life, and super visa insurance. Compare, save, and get your free quote today with Beat My Insurance."
        />
        <meta
          name="keywords"
          content="Car Insurance Quotes, Cheap Car Insurance Canada, Home Insurance Quotes, Car Insurance Ontario, Mortgage Brokers, Mortgage Rates Canada, Visitor Insurance"
        />
        <meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Organization',
            '@id': '#Organization',
            url: 'https://www.beatmyinsurance.com',
            legalName: 'Beat My Insurance',
            name: 'Beat My Insurance',
            description:
              'Get the best auto, home, life, and super visa insurance with Beat My Insurance! Easily compare options to find cheap car insurance, auto insurance, home insurance in Canada, and bundled home and auto insurance deals.',
            image: 'https://www.beatmyinsurance.com/logo.png',
            logo: 'https://www.beatmyinsurance.com/logo.png',

            email: 'info@beatmyinsurance.com',
            address: {
              '@type': 'PostalAddress',
              addressRegion: 'Ontario',
              addressCountry: 'Canada',
            },
            sameAs: [
              'https://www.facebook.com/beatmyinsurance/',
              'https://www.youtube.com/@BeatMyInsurance',
              'https://www.instagram.com/beatmyinsurance/',
              'https://www.linkedin.com/company/beat-my-insurance/',
              'https://x.com/Beatmyinsurance',
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Beat My Insurance',
                item: 'https://www.beatmyinsurance.com',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Buy Insurance',
                item: 'https://www.beatmyinsurance.com/buy-insurance',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: 'Sell Insurance',
                item: 'https://www.beatmyinsurance.com/sell-insurance',
              },
              {
                '@type': 'ListItem',
                position: 4,
                name: 'Cheap Insurance Car Finder',
                item: 'https://www.beatmyinsurance.com/cheap-insurance-car-finder',
              },
              {
                '@type': 'ListItem',
                position: 5,
                name: 'Mortgage Penalty Calculator',
                item: 'https://www.beatmyinsurance.com/mortgage-penalty-calculator',
              },
              {
                '@type': 'ListItem',
                position: 6,
                name: 'About Us',
                item: 'https://www.beatmyinsurance.com/about',
              },
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'WebPage',
            '@id': '#WebPage',
            url: 'https://www.beatmyinsurance.com/cheap-insurance-car-finder',
            name: 'Cheap Insurance Car Finder',
            areaServed: [
              'https://en.wikipedia.org/wiki/Canada',
              'https://en.wikipedia.org/wiki/Ottawa',
              'https://en.wikipedia.org/wiki/London,_Ontario',
              'https://en.wikipedia.org/wiki/Hamilton,_Ontario',
              'https://en.wikipedia.org/wiki/Vaughan',
              'https://en.wikipedia.org/wiki/Toronto',
              'https://en.wikipedia.org/wiki/Mississauga',
              'https://en.wikipedia.org/wiki/Brampton',
              'https://en.wikipedia.org/wiki/Windsor,_Ontario',
              'https://en.wikipedia.org/wiki/Kitchener,_Ontario',
              'https://en.wikipedia.org/wiki/Markham,_Ontario',
            ],
          })}
        </script>
      </Helmet>
      {showTutorial && (
        <div className="home-tutorial-holder">
          <div className="home-tutorial-btnholder">
            <button
              className={
                tutorialType === 'buy'
                  ? 'tutorial-home-btn-active'
                  : 'tutorial-home-btn'
              }
              onClick={() => setTutorialType('buy')}
            >
              Buyer
            </button>
            <button
              className={
                tutorialType === 'sell'
                  ? 'tutorial-home-btn-active'
                  : 'tutorial-home-btn'
              }
              onClick={() => setTutorialType('sell')}
            >
              Seller
            </button>
            <img
              src="/cross.png"
              alt="close"
              onClick={() => setShowTutorial(false)}
            />
          </div>
          <div className="home-video-holder">
            <video
              src={`https://profiles-bmi.s3.amazonaws.com/${tutorialType}-${device}.mp4`}
              controls
            />
          </div>
        </div>
      )}
      <div className="page1">
        <div className="navbar">
          <img className="logo" src="/logo.png" alt="beatmyinsurance" />
          {device !== 'desktop' && (
            <>
              <img
                className="menu-icon-home"
                src="/menu.png"
                alt="menu"
                onClick={() => setShowMenu(!showMenu)}
              />

              <div className={`menu-options-home ${showMenu ? 'open' : ''}`}>
                <button
                  className="calculator-tool-btn-m"
                  onClick={() => {
                    navigate('/cheap-insurance-car-finder');
                  }}
                >
                  <p>Cheap Car Insurance Finder</p>
                  <img src="/car.png" alt="how it works" />
                </button>
                <button
                  className="calculator-tool-btn-m"
                  onClick={() => {
                    navigate('/mortgage-penalty-calculator');
                  }}
                >
                  <p>Mortgage Penalty Calculator</p>
                  <img src="/car.png" alt="how it works" />
                </button>
                <button
                  className="tutorial-btn-home-m"
                  onClick={() => {
                    setShowTutorial(true);
                  }}
                >
                  <p>How it works</p>
                  <img src="/help-circle.png" alt="how it works" />
                </button>
                <button
                  className="aboutus-btn-home-m"
                  onClick={() => {
                    navigate('/about');
                  }}
                >
                  <p>About Us</p>
                  <img src="/alert-circle.png" alt="how it works" />
                </button>
                <button
                  className="login-m"
                  onClick={() => {
                    navigate('/login', { state: { newUser: false } });
                  }}
                >
                  Login / Signup
                </button>
              </div>
            </>
          )}
          {device === 'desktop' && (
            <>
              {/*<button
                className="calculator-tool-btn"
                onClick={() => {
                  navigate("/cheap-insurance-car-finder");
                }}
              >
                <p>Cheap Insurance Finder</p>
                <img src="/car.png" alt="Cheap Insurance Car Finder" />
              </button> */}
              <div
                className="tool-btn-home"
                onClick={() => setShowTools(!showTools)}
              >
                Tools
              </div>
              {showTools && (
                <div className="tools-options-home">
                  <p onClick={() => navigate('/cheap-insurance-car-finder')}>
                    Cheap Car Insurance Finder
                  </p>
                  <p onClick={() => navigate('/mortgage-penalty-calculator')}>
                    Mortgage Penalty Calculator
                  </p>
                </div>
              )}
              <button
                className="tutorial-btn-home"
                onClick={() => {
                  setShowTutorial(true);
                }}
              >
                <p>How it works</p>
                <img src="/help-circle.png" alt="how it works" />
              </button>

              <button
                className="login"
                onClick={() => {
                  navigate('/login', { state: { newUser: false } });
                }}
              >
                Sign In
              </button>
            </>
          )}
        </div>
        {/*<p className="creditcard-home">
          Keep your credit card—it’s <strong>completely free!</strong>
        </p>*/}
        <div className="select-role-home">
          <button
            className={userRole === 'buyer' ? 'role-btn-active' : 'role-btn'}
            onClick={() => {
              setUserRole('buyer');
              navigate('/buy-insurance');
            }}
          >
            I am a Buyer
          </button>
          <button
            className={userRole === 'seller' ? 'role-btn-active' : 'role-btn'}
            onClick={() => navigate('/sell-insurance')}
          >
            I am a Seller
          </button>{' '}
          {/*onClick={() => setUserRole("seller")}*/}
        </div>
        {userRole === 'buyer' && (
          <div className="need-insurance-box">
            <div className="btn-holder-home">
              <div className="select-wrapper-home">
                <select
                  defaultValue="none"
                  onChange={(e) => {
                    if (e.target.value === 'Add New Listing') {
                      navigate('/buy-insurance');
                    } else if (e.target.value === 'Check My Listings') {
                      navigate('/login', { state: { newUser: false } });
                    }
                  }}
                >
                  <option value="none" disabled hidden>
                    Add New Listing
                  </option>
                  <option value="Add New Listing">Add New Listing</option>
                  <option value="Check My Listings">Check My Listings</option>
                </select>
              </div>
            </div>
            <div className="need-insurance-box-content">
              <h2 className="need-insurance-heading">
                Let's beat your insurance quote!
              </h2>
              <p className="need-insurance-sidehead">
                No more chasing agents for cheap auto insurance.
              </p>
              <div className="need-insurance-info">
                <h3>Stay anonymous</h3>
                <h3>Post your needs</h3>
                <h3>Choose the best premium</h3>
              </div>

              <br />
            </div>
          </div>
        )}
        {userRole === 'seller' && (
          <div className="provide-insurance-box">
            <div className="btn-holder-home">
              <button onClick={() => navigate('/sell-insurance')}>
                Find Buyers
              </button>
            </div>
            <div className="need-insurance-box-content">
              <p className="need-insurance-heading">
                Let’s Beat Your Sales Goals Together!
              </p>
              <p className="need-insurance-sidehead">
                Bonjour! Skip chasing leads—connect with buyers!
              </p>
              <div className="need-insurance-info">
                <div>Search Leads</div>
                <div>Bid on Posts</div>
                <div>Win More Clients</div>
              </div>
            </div>
          </div>
        )}
        <img
          className="role-background"
          src="/home-p1-background.png"
          alt="beat your current premium"
        />
        <h1 className="quote-head">
          Cheap Insurance Quotes Without Repeated Calls
        </h1>
        <p className="quote-info">
          Set your terms, secure your info, and let agents bid to offer the
          cheapest car insurance quote!
        </p>
        {/*<button className='get-started' onClick={()=>{navigate("/login", {state:{newUser: true}})} }>Free Signup </button> */}
        {/*<img
          className="solar-background"
          src="solar-background.svg"
          alt="background"
        />
        <div className="notify1">
          <img className="bell-notify" src="bell-notify.png" alt="bell" />
          <p className="notify1-content">
            You have got two new bids for your post!
          </p>
        </div>
        {/*<div className='notify2'>
                    <img  className="commas" src='commas.png' alt='commas'/>
                    <div className='notify2-content'>
                        <p>Using BMI Platform is a game changer for me</p>
                        <img src='rating.png' alt='rating'/>
                    </div>
                </div>
        <div className="bid-notify-home">
          <img src="bid.png" alt="bid" />
          <p>Hey! Bid for John Smith</p>
          <button className="accept-home">Accept</button>
          <button className="reject-home">Reject</button>
        </div>
        {!visibility && (
          <div className="inactive-notify">
            <div className="toggle-info-container">
              <span>This post is currently not available to providers.</span>
            </div>
          </div>
        )}
        {visibility && (
          <div className="active-notify">
            <div className="toggle-info-container">
              <span>
                {" "}
                Hi Luna, Your post is live. <br />
                Providers can now see your post!
              </span>
            </div>
          </div>
        )}
        <div
          className={`toggle-btn-home-${visibility ? "active" : "inactive"}`}
          onClick={() => handleToggleClick()}
        >
          <span
            className={`toggle-status-${visibility ? "active" : "inactive"}`}
          >
            {visibility ? "Active" : "Inactive"}
          </span>
          <div
            className={`toggle-bar-home-${visibility ? "active" : "inactive"}`}
            onClick={() => handleToggleClick()}
          ></div>
        </div>
        <img className="girl-home" src="girl.png" alt="girl" />*/}
      </div>
      {/*<div className="lorem-details">
        <div className="lorem-options">
          <button
            className={!showProviderContent ? "lorem-btn-active" : "lorem-btn"}
            onClick={() => setShowProviderContent(false)}
          >
            Buyer
          </button>
          <button
            className={showProviderContent ? "lorem-btn-active" : "lorem-btn"}
            onClick={() => setShowProviderContent(true)}
          >
            Provider(Brokers)
          </button>
        </div>
        {showProviderContent && (
          <div className="lorem-content-holder">
            <div className="lorem-content">
              <p className="lorem-content-heading">Qualified Leads</p>
              <p className="lorem-content-info">
                Access a pool of motivated buyers who are ready to make
                decisions and take action.
              </p>
            </div>
            <div className="lorem-content">
              <p className="lorem-content-heading">Swift Quotes</p>
              <p className="lorem-content-info">
                Deliver quotes fast with all the essential details right at your
                fingertips.
              </p>
            </div>
            <div className="lorem-content">
              <p className="lorem-content-heading">No More Back-and-Forth</p>
              <p className="lorem-content-info">
                Eliminate endless follow-ups with complete information available
                from the start.
              </p>
            </div>
            <div className="lorem-content">
              <p className="lorem-content-heading">Cut Costs Efficiently</p>
              <p className="lorem-content-info">
                Streamline your quoting process to save time, reduce confusion,
                and lower expenses.
              </p>
            </div>
          </div>
        )}
        {!showProviderContent && (
          <div className="lorem-content-holder">
            <div className="lorem-content">
              <p className="lorem-content-heading">
                One Post, Countless Offers
              </p>
              <p className="lorem-content-info">
                One entry brings multiple top offers competing for you.
              </p>
            </div>
            <div className="lorem-content">
              <p className="lorem-content-heading">Stay Anonymous</p>
              <p className="lorem-content-info">
                Keep your email and phone private until you’re ready to accept,
                ensuring your privacy.
              </p>
            </div>
            <div className="lorem-content">
              <p className="lorem-content-heading">Unified Dashboard</p>
              <p className="lorem-content-info">
                Manage all your insurance options in one place, saving you time
                and effort.
              </p>
            </div>
            <div className="lorem-content">
              <p className="lorem-content-heading">Negotiation-Free Process</p>
              <p className="lorem-content-info">
                Say goodbye to endless negotiations and get instant, transparent
                offers.
              </p>
            </div>
          </div>
        )}
      </div>*/}
      <h2 className="deals-notify-head">
        Sit Back, Relax, Let the Best{' '}
        <span> Auto Insurance Quotes Find You</span>
      </h2>
      <p className="deals-notify-info">
        Just add your insurance or mortgage needs and let brokers bid you their
        lowest quotes!
      </p>
      <img className="machine-home" src="machine.png" alt="safe and secure" />
      <div className="point1">
        <img src="tick.png" alt="Stay anonymous until you accept" />
        <h3 className="points-info">Stay anonymous until you accept</h3>
      </div>
      <div className="point2">
        <img src="tick.png" alt="Goodbye to lengthy quote calls" />
        <h3 className="points-info">Goodbye to lengthy quote calls</h3>
      </div>
      <div className="point3">
        <img src="tick.png" alt="Let insurance providers come to you instead" />
        <h3 className="points-info">Brokers bid for your best rate!</h3>
      </div>
      <div className="point4">
        <img src="tick.png" alt="Secure your personal info" />
        <h3 className="points-info">Secure your personal info</h3>
      </div>
      <div className="comparison-block">
        <h2 className="comparison-head">
          Find a Better Insurance Premium with BeatMyInsurance.com
        </h2>
        <p className="comparison-info">
          Unlike traditional companies, BeatMyInsurance.com brings providers to
          you for cheap car insurance, Super Visa, and other quotes—quick,
          flexible, and with top deals from multiple offers!
        </p>
        {/*<img
          className="comparison-img"
          src="comparison.png"
          alt="pros and cons"
        />*/}
        <table className="comparison-table">
          <thead>
            <th>Feature</th>
            <th>beatmyinsurance.com</th>
            <th>Traditional Companies</th>
          </thead>
          <tbody>
            <tr>
              <td>Single Data Entry</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
            <tr>
              <td>Anonymity</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
            <tr>
              <td>Competitive Bidding by Brokers</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
            <tr>
              <td>No Repeated Calls</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
            <tr>
              <td>Message Agent Directly from Our Platform</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
            <tr>
              <td>One-Click Activate/Deactivate Listings</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
            <tr>
              <td>Easy Sharing on Whatsapp and other platforms</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
            <tr>
              <td>It's Free</td>
              <td className="td-image">
                <img src="/green.png" alt="yes" />
              </td>
              <td className="td-image">
                <img src="/red.png" alt="no" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="needs-home">
        <h2 className="needs-head">
          Post Your Needs, <span> Get a Cheap Insurance Quote! </span>
        </h2>
        <p className="needs-info">
          Share your needs and let agents bid to get you the lowest car
          insurance and mortgage rates instantly!
        </p>
      </div>
      <div className="types-container-home">
        {/*
                {types && types.map((type,index) =>(
                    <div key={index} className='type-item'>
                        {type}
                    </div>
                ))} */}
        <div className="type-item">
          <h3 className="type-item-heading">Home Insurance</h3>
          <p className="type-item-info">
            Protect your home and belongings from unexpected events with
            comprehensive home insurance coverage in Canada. Secure your
            investment with the best home insurance policies.
          </p>
        </div>
        <div className="type-item">
          <h3 className="type-item-heading">Auto Insurance</h3>
          <p className="type-item-info">
            Get the best auto insurance in Canada to safeguard your vehicle and
            ensure peace of mind on the road. Enjoy competitive car insurance
            rates and superior coverage.
          </p>
        </div>
        <div className="type-item">
          <h3 className="type-item-heading">Home & Auto Insurance (Bundled)</h3>
          <p className="type-item-info">
            Save more with bundled home and auto insurance packages in Canada,
            offering extensive coverage and discounts. Discover the benefits of
            bundled insurance policies.
          </p>
        </div>
        <div className="type-item">
          <h3 className="type-item-heading">Renters Insurance</h3>
          <p className="type-item-info">
            Secure your personal property and liability with affordable renters
            insurance for tenants in Canada. Get the best tenant insurance
            quotes and coverage.
          </p>
        </div>
        <div className="type-item">
          <h3 className="type-item-heading">Super Visa Insurance</h3>
          <p className="type-item-info">
            Ensure your parents and grandparents are covered with the best Super
            Visa insurance in Canada for their extended stay. Find affordable
            Super Visa medical insurance plans.
          </p>
        </div>
        <div className="type-item">
          <h3 className="type-item-heading">Visitor Insurance</h3>
          <p className="type-item-info">
            Get comprehensive visitor insurance in Canada to protect against
            medical emergencies during your visit. Choose from the top travel
            health insurance for visitors.
          </p>
        </div>
        <div className="type-item">
          <h3 className="type-item-heading">Mortgage Rates</h3>
          <p className="type-item-info">
            Find the best mortgage rates in Canada with top-rated providers.
            Compare affordable options for home financing, tailored to meet your
            budget and needs.
          </p>
        </div>
        <div className="type-item">
          <h3 className="type-item-heading">Life Insurance</h3>
          <p className="type-item-info">
            Provide financial security for your loved ones with customized life
            insurance policies in Canada. Find the best term life insurance and
            whole life insurance options.
          </p>
        </div>
      </div>
      <div className="customer-say">
        <div className="customer-say-caption">
          <h2 className="head1">
            Our Customer Say Something
            <span> About Auto Insurance & Mortgage Rates </span>
          </h2>
        </div>
        <div className="customer-reviews-container">
          {/*reviews.map((review, index) => (
                        <div key={index} className='customer-review'>
                            <img src='rating.png' alt='rating'/>
                            <p>what is to be here</p>
                        </div>
                    )) */}
          <div className="customer-review">
            <p className="customer-review-text">
              BeatMyInsurance.com helped me save 25% on my car insurance! I got
              multiple quotes from different providers, and the best one was
              perfect for my budget. I highly recommend it if you want cheap car
              insurance!
            </p>
            <p className="customer-name-home"> - David Anderson</p>
          </div>
          <div className="customer-review">
            <p className="customer-review-text">
              I was looking for car insurance near me and BeatMyInsurance.com
              made it so easy to compare quotes from local providers. I quickly
              got a few options for my needs and saved money. The whole process
              was smooth.
              {/*, and I’m now covered with a policy that works for me. */}
            </p>
            <p className="customer-name-home">- Priya Patel</p>
          </div>
          <div className="customer-review">
            <p className="customer-review-text">
              I was tired of searching for car insurance quotes on my own. With
              BeatMyInsurance.com, I simply entered my details, and brokers bid
              on my needs. It was so simple, and I ended up with an amazing
              rate! This is definitely the easiest way to get personalized
              quotes.
            </p>
            <p className="customer-name-home">— Derek P.</p>
          </div>
          <div className="customer-review">
            <p className="customer-review-text">
              “The best part about BeatMyInsurance is how easy it is to use. I
              could find and compare insurance quotes, and even mortgage quotes,
              all in one place. It simplified my search and helped me save a lot
              of time.”
            </p>
            <p className="customer-name-home">— Mark S</p>
          </div>
        </div>
      </div>
      <div className="join-us-home">
        <h2 onClick={() => navigate('/buy-insurance')}>
          Join now and receive bids for the cheapest car insurance!
        </h2>
        <button
          onClick={() => {
            navigate('/login', { state: { newUser: true } });
          }}
        >
          Add your listing
        </button>
      </div>

      <footer>
        <span className="copyright">
          Copyright 2024 BMI. All Rights Reserved
        </span>
        <span className="liner-footer">|</span>
        <div className="footer-icons-holder">
          <img
            onClick={() => {
              window.open(
                'https://www.facebook.com/beatmyinsurance/',
                '_blank',
                'noopener,noreferrer'
              );
            }}
            src="/facebook.svg"
            alt="facebook"
          />
          <img
            onClick={() => {
              window.open(
                'https://www.instagram.com/beatmyinsurance/',
                '_blank',
                'noopener,noreferrer'
              );
            }}
            src="/instagram.svg"
            alt="instagram"
          />
          <img
            onClick={() => {
              window.open(
                'http://www.youtube.com/@BeatMyInsurance',
                '_blank',
                'noopener,noreferrer'
              );
            }}
            src="/youtube.svg"
            alt="youtube"
          />
          <img
            onClick={() => {
              window.open(
                'https://x.com/Beatmyinsurance',
                '_blank',
                'noopener,noreferrer'
              );
            }}
            src="/twitter.svg"
            alt="twitter"
          />
          <img
            onClick={() => {
              window.open(
                'https://www.linkedin.com/company/103720775/admin/feed/posts/',
                '_blank',
                'noopener,noreferrer'
              );
            }}
            src="/linkedin.svg"
            alt="linkedin"
          />
        </div>
        <span
          onClick={() => {
            window.open('/blogs/', '_blank', 'noopener,noreferrer');
          }}
          className="blogs"
        >
          blogs
        </span>
        <span
          className="aboutus-footer"
          onClick={() => {
            navigate('/about');
          }}
        >
          About Us
        </span>
        <span
          onClick={() => {
            window.open(
              'https://www.beatmyinsurance.com/terms/',
              '_blank',
              'noopener,noreferrer'
            );
          }}
          className="terms"
        >
          Terms of Use | Privacy Policy
        </span>
      </footer>
    </div>
  );
};

export default HomePage;
