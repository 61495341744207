import config from '../utils/ApiUrl';
const API_URL = config.API_URL;

const customViewService = {
  getFields: async () => {
    try {
      const response = await fetch(`${API_URL}/get-all-fields`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      return data.fields;
    } catch (error) {
      throw new Error('Failed to fetch fields');
    }
  },
  fetchExistingView: async (id) => {
    try {
      const response = await fetch(`${API_URL}/fetch-existing-view/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      return data.view || [];
    } catch (error) {
      throw new Error('Failed to fetch view settings');
    }
  },
  uploadNewView: async (id, setting) => {
    try {
      const response = await fetch(`${API_URL}/upload-new-custom-view/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(setting),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to upload');
    }
  },
};

export default customViewService;
