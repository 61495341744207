import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchRateApprovalData } from '../services/calculatorService';
import {
  Users,
  ChevronDown,
  Menu,
  Mail,
  MapPin,
  Facebook,
  Instagram,
  Youtube,
  Linkedin,
  ArrowRight,
  X,
} from 'lucide-react';
import { Helmet } from 'react-helmet';

const RateApproval2025 = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [insuranceData, setInsuranceData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Insurance',
      submenu: [
        { label: 'Car Insurance', link: '/buy-insurance' },
        { label: 'Super Visa Insurance', link: '/buy-insurance' },
        { label: 'Home Insurance', link: '/buy-insurance' },
        { label: 'Life Insurance', link: '/buy-insurance' },
      ],
      size: 4,
    },
    {
      label: 'Tools & Calculators',
      submenu: [
        {
          label: 'Cheap Insurance Car Calculator',
          link: '/cheap-insurance-car-finder',
        },
        {
          label: 'Mortgage Penalty Calculator',
          link: '/mortgage-penalty-calculator',
        },
        {
          label: 'Car Insurance Rates-2025',
          link: '/car-insurance-rates-2025',
        },
        {
          label: 'Car Insurance Increase By Provider-2025',
          link: '/car-insurance-increase-by-provider-2025',
        },
      ],
      size: 2,
    },
    {
      label: 'Resources',
      submenu: [
        { label: 'Blogs', link: '/blogs' },
        { label: 'FAQ', link: '/faq' },
        { label: 'About Us', link: '/about' },
      ],
      size: 3,
    },
  ];

  useEffect(() => {
    fetchInsuranceData();
  }, []);

  const fetchInsuranceData = async () => {
    try {
      const response = await fetchRateApprovalData();
      if (response) {
        setInsuranceData(response.data);
      }
    } catch (err) {
      setInsuranceData([]);
    }
  };

  // Process and filter data
  const processedData = useMemo(() => {
    return insuranceData
      ? insuranceData.filter(
          (company) =>
            company['Market Share Year'] === 2023 &&
            new Date(company['Renewal Effective Date']) > new Date('2024-01-01')
        )
      : null;
  }, [insuranceData]);

  // Filter companies based on search
  const filteredCompanies = useMemo(
    () =>
      processedData
        ? processedData.filter(
            (company) =>
              company.Insurer.toLowerCase().includes(
                searchTerm.toLowerCase()
              ) ||
              company['Insurer Group']
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          )
        : null,
    [processedData, searchTerm]
  );

  // Calculate market averages
  const marketStats = useMemo(
    () =>
      processedData
        ? {
            avgRateChange:
              processedData.reduce(
                (acc, curr) => acc + curr['Approved Rate Change (%)'],
                0
              ) / processedData.length,
            avgMarketShare:
              processedData.reduce(
                (acc, curr) => acc + curr['Market Share (%)'],
                0
              ) / processedData.length,
          }
        : null,
    [processedData]
  );

  if (insuranceData) {
    return (
      <div>
        <Helmet>
          <link rel="canonical" href={window.location.href} />
          <title>
            Car Insurance Rate Approval Search | Compare Provider Changes 2025
          </title>
          <meta
            name="description"
            content="Search insurance rate approvals and market share data by provider | Auto Insurance Rate Changes 2025"
          />
          <meta
            property="og:title"
            content="Car Insurance Rate Approval Search | Compare Provider Changes 2025"
          />
          <meta
            property="og:description"
            content="Search insurance rate approvals and market share data by provider | Auto Insurance Rate Changes 2025"
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Beat My Insurance" />
          <meta name="twitter:card" content="summary_large_image" />{' '}
          <meta
            name="twitter:title"
            content="Car Insurance Rate Approval Search | Compare Provider Changes 2025"
          />{' '}
          <meta
            name="twitter:description"
            content="Search insurance rate approvals and market share data by provider | Auto Insurance Rate Changes 2025"
          />{' '}
          <meta
            name="twitter:image"
            content="https://www.beatmyinsurance.com/logo512.png"
          />{' '}
          <meta name="twitter:site" content="@Beatmyinsurance" />
        </Helmet>
        <nav
          className="bg-blue-900 border-b"
          onClick={() => setOpenSubmenu(null)}
        >
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between h-14">
              <div className="flex-shrink-0">
                {/*<span className="text-2xl font-bold text-white">BeatMyInsurance</span>*/}
                <img
                  onClick={() => navigate('/home')}
                  src="/whitelogo.png"
                  alt="Beat My Insurance"
                  className="max-h-8 cursor-pointer"
                />
              </div>

              <div className="hidden md:flex items-center space-x-6">
                <div className="flex space-x-6">
                  {menuItems.map((item) => (
                    <div
                      key={item.label}
                      className="relative"
                      onClick={() => setOpenSubmenu(item.label)}
                      onMouseEnter={() => setOpenSubmenu(item.label)}
                    >
                      <button
                        className={`flex items-center px-2 py-1 text-sm text-white `}
                      >
                        {item.label}
                        <ChevronDown className="ml-1 w-4 h-4" />
                      </button>

                      {openSubmenu === item.label && (
                        <div className="absolute z-50 left-0 mt-1 w-48 bg-white rounded-lg shadow-xl py-1">
                          {item.submenu.map((subItem) => (
                            <a
                              key={subItem.label}
                              href={subItem.link}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-50"
                            >
                              {subItem.label}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="flex items-center space-x-3">
                  <button
                    onClick={() =>
                      navigate('/login', { state: { newUser: false } })
                    }
                    className="inline-flex items-center text-sm text-white hover:text-blue-600"
                  >
                    <Users className="w-4 h-4 mr-1" />
                    Login
                  </button>
                  <button
                    onClick={() =>
                      navigate('/login', { state: { newUser: true } })
                    }
                    className="inline-flex items-center text-sm bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                  >
                    <Users className="w-4 h-4 mr-1" />
                    Sign Up
                  </button>
                </div>
              </div>

              <div className="md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="text-white hover:text-white"
                >
                  {isOpen ? (
                    <X className="w-6 h-6" />
                  ) : (
                    <Menu className="w-6 h-6" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {isOpen && (
            <div className="md:hidden border-t">
              <div className="px-2 py-2 space-y-1">
                {menuItems.map((item) => (
                  <div key={item.label}>
                    <button
                      className="w-full text-left px-3 py-2 text-sm text-white "
                      onClick={() =>
                        setOpenSubmenu(
                          openSubmenu === item.label ? null : item.label
                        )
                      }
                    >
                      <div className="flex justify-between items-center">
                        {item.label}
                        <ChevronDown
                          className={`w-4 h-4 transform ${openSubmenu === item.label ? 'rotate-180' : ''}`}
                        />
                      </div>
                    </button>
                    {openSubmenu === item.label && (
                      <div className="pl-4">
                        {item.submenu.map((subItem) => (
                          <a
                            key={subItem.label}
                            href={subItem.link}
                            className="block px-3 py-2 text-sm text-white"
                          >
                            {subItem.label}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex justify-center space-x-3 mb-1">
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: false } })
                  }
                  className="inline-flex items-center pl-6 text-sm text-white hover:text-blue-600"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Login
                </button>
                <button
                  onClick={() =>
                    navigate('/login', { state: { newUser: true } })
                  }
                  className="inline-flex items-center  text-sm  text-white px-3 py-1 rounded hover:bg-blue-700"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Sign Up
                </button>
              </div>
            </div>
          )}
        </nav>

        <div
          className="flex items-center justify-center"
          onClick={() => setOpenSubmenu(null)}
        >
          <div
            className="bg-white max-w-[550px]  my-2 rounded-lg shadow-lg p-8"
            onClick={() => setOpenSubmenu(null)}
          >
            <h1 className="text-2xl font-bold mb-4">
              Insurance Rate Approval Analysis 2025
            </h1>

            {/* Search Bar */}
            <div className="mb-6">
              <input
                type="text"
                placeholder="Search insurance company or group..."
                className="w-full p-3 border rounded-lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Market Statistics */}
            <div className="mb-6 p-4 bg-blue-50 rounded-lg">
              <h2 className="font-bold mb-2">Market Overview</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  Average Rate Change: {marketStats.avgRateChange.toFixed(2)}%
                </div>
                <div>
                  Average Market Share: {marketStats.avgMarketShare.toFixed(2)}%
                </div>
              </div>
            </div>

            {/* Company List */}
            <div className="space-y-4">
              {filteredCompanies.map((company) => (
                <div
                  key={company.Insurer}
                  className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer"
                  onClick={() => setSelectedCompany(company)}
                >
                  <div className="flex justify-between">
                    <div>
                      <h3 className="font-bold">{company.Insurer}</h3>
                      <p className="text-gray-600">
                        {company['Insurer Group']}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="font-bold">
                        {company['Market Share (%)'].toFixed(2)}% Share
                      </p>
                      <p
                        className={`${company['Approved Rate Change (%)'] > marketStats.avgRateChange ? 'text-red-600' : 'text-green-600'}`}
                      >
                        {company['Approved Rate Change (%)'] > 0 ? '+' : ''}
                        {company['Approved Rate Change (%)'].toFixed(2)}% Rate
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Company Details Modal */}
            {selectedCompany && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                  <div className="flex justify-between mb-4">
                    <h2 className="text-xl font-bold">
                      {selectedCompany.Insurer}
                    </h2>
                    <button
                      onClick={() => setSelectedCompany(null)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      ✕
                    </button>
                  </div>

                  <div className="grid gap-4">
                    <div>
                      <h3 className="font-bold mb-2">Details</h3>
                      <div className="grid grid-cols-2 gap-2">
                        <p>Group: {selectedCompany['Insurer Group']}</p>
                        <p>
                          Market Share:{' '}
                          {selectedCompany['Market Share (%)'].toFixed(2)}%
                        </p>
                        <p>
                          Rate Change:{' '}
                          {selectedCompany['Approved Rate Change (%)'].toFixed(
                            2
                          )}
                          %
                        </p>
                        <p>Approval Date: {selectedCompany['Approval Date']}</p>
                        <p>
                          Renewal Date:{' '}
                          {selectedCompany['Renewal Effective Date']}
                        </p>
                      </div>
                    </div>

                    <div>
                      <h3 className="font-bold mb-2">Analysis</h3>
                      <ul className="list-disc pl-4">
                        <li>
                          Rate change is{' '}
                          {Math.abs(
                            selectedCompany['Approved Rate Change (%)'] -
                              marketStats.avgRateChange
                          ).toFixed(2)}
                          %
                          {selectedCompany['Approved Rate Change (%)'] >
                          marketStats.avgRateChange
                            ? ' above '
                            : ' below '}
                          market average
                        </li>
                        <li>
                          Market share is{' '}
                          {Math.abs(
                            selectedCompany['Market Share (%)'] -
                              marketStats.avgMarketShare
                          ).toFixed(2)}
                          %
                          {selectedCompany['Market Share (%)'] >
                          marketStats.avgMarketShare
                            ? ' above '
                            : ' below '}
                          average
                        </li>
                        {selectedCompany['Market Share (%)'] > 5 && (
                          <li>
                            Major market player with significant market presence
                          </li>
                        )}
                        {selectedCompany['Approved Rate Change (%)'] < 0 && (
                          <li>
                            Implementing rate decrease, potentially to increase
                            market share
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <h4 className="text-center my-4">
          Data source :{' '}
          <a
            className="underline"
            href="https://autorateapprovals.fsrao.ca/?_gl=1*1v0zjxc*_gcl_au*NjM1NjMxNDI3LjE3MzM0Mjg4MTM.*_ga*MzE4Mzk2NTA2LjE3MzM0Mjg4MTI.*_ga_SBF9PKM7WQ*MTczMzg5Mjc4Mi4zLjEuMTczMzg5Mjc4OC41NC4wLjA."
          >
            Auto Rate Approvals
          </a>
        </h4>
        <div
          onClick={() => setOpenSubmenu(null)}
          className="bg-slate-900  text-white py-12"
        >
          <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Company Info */}
            <div className="space-y-4">
              <h3 className="text-xl font-bold mb-4">BeatMyInsurance</h3>
              <p className="text-slate-300">
                Find the cheapest car insurance quotes. Save up to 50% on your
                auto insurance today!
              </p>
              <button
                onClick={() => navigate('/buy-insurance')}
                className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded flex items-center"
              >
                Get Free Quote <ArrowRight className="ml-2 h-4 w-4" />
              </button>
            </div>

            {/* Quick Links */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2 text-slate-300">
                <li>
                  <a
                    href="/cheap-insurance-car-finder"
                    className="hover:text-blue-400"
                  >
                    Cheap Car Insurance
                  </a>
                </li>
                <li>
                  <a
                    href="/mortgage-penalty-calculator"
                    className="hover:text-blue-400"
                  >
                    Mortgage Penalty Calculator
                  </a>
                </li>
                <li>
                  <a href="/about" className="hover:text-blue-400">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/blogs" className="hover:text-blue-400">
                    Blogs
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Info */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
              <div className="space-y-3 text-slate-300">
                {/*<p className="flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                1-800-BEAT-INS
              </p>*/}
                <p
                  className="flex items-center"
                  onClick={() =>
                    (window.location.href = 'mailto:info@beatmyinsurance.com')
                  }
                >
                  <Mail className="h-4 w-4 mr-2" />
                  info@beatmyinsurance.com
                </p>
                <p className="flex items-center">
                  <MapPin className="h-4 w-4 mr-2" />
                  Ontario, Canada
                </p>
              </div>
            </div>

            {/* Newsletter */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Stay Updated</h4>
              <p className="text-slate-300 mb-4">
                Get the latest insurance deals and tips!
              </p>

              <div className="flex space-x-4 mt-4">
                <Facebook
                  onClick={() => {
                    window.open(
                      'https://www.facebook.com/beatmyinsurance/',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                  className="h-5 w-5 hover:text-blue-400 cursor-pointer"
                />
                <img
                  src="/twitter.png"
                  alt="twitter"
                  onClick={() => {
                    window.open(
                      'https://x.com/Beatmyinsurance',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                  className="h-5 w-5 hover:text-blue-400 cursor-pointer"
                />
                <Instagram
                  onClick={() => {
                    window.open(
                      'https://www.instagram.com/beatmyinsurance/',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                  className="h-5 w-5 hover:text-blue-400 cursor-pointer"
                />
                <Youtube
                  onClick={() => {
                    window.open(
                      'http://www.youtube.com/@BeatMyInsurance',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                  className="h-5 w-5 hover:text-blue-400 cursor-pointer"
                />

                <Linkedin
                  onClick={() => {
                    window.open(
                      'https://www.linkedin.com/company/103720775/admin/feed/posts/',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                  className="h-5 w-5 hover:text-blue-400 cursor-pointer"
                />
              </div>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="max-w-7xl mx-auto px-4 mt-8 pt-8 border-t border-slate-700">
            <div className="flex flex-col md:flex-row justify-between items-center text-slate-400 text-sm">
              <p>&copy; 2024 BeatMyInsurance. All rights reserved.</p>
              <div className="flex space-x-4 mt-4 md:mt-0">
                {/*<a href="/privacy" className="hover:text-blue-400">
                Privacy Policy
              </a>*/}
                <a href="/terms" className="hover:text-blue-400">
                  Terms of use | Privacy Policy
                </a>
                {/*}
              <a href="/sitemap" className="hover:text-blue-400">
                Sitemap
              </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <p>loading...</p>;
  }
};

export default RateApproval2025;
