import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import * as Dialog from '@radix-ui/react-dialog';

const QRCodePopup = ({ link, open, onClose }) => {
  if (!link) return null;

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="fixed inset-0 bg-black/50 z-50"
          onClick={onClose}
        />

        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-80 text-center z-50">
          <h2 className="text-lg font-semibold mb-4">Scan QR Code</h2>
          <div className="flex justify-center">
            <QRCodeCanvas value={link} size={200} />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default QRCodePopup;
